<template>
  <v-container>
    <v-row>
      <v-col>
        <v-img
          src="../assets/aspenLogo.png"
          contain
          max-height="200"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
          <v-btn color="primary" outlined rounded block to="/announcement-list-aspen">
            Manage Announcements
          </v-btn>
        </v-col>

      <v-col cols="12">
          <v-btn color="primary" outlined rounded block to="/dealer-list">
            Manage Dealers
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-btn color="primary" outlined rounded block to="/create-user">
            Manage Users
          </v-btn>
        </v-col>

        
      <v-col cols="12">
        <v-btn color="primary" outlined rounded block to="/catalog-editor/4">
          View/Edit Catalogs
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn color="primary" outlined rounded block to="model-editor">
          View/Edit Models
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn color="primary" outlined rounded block to="/series-editor">
          View/Edit Series
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn color="primary" outlined rounded block to="/optionType-editor">
          View/Edit Options
        </v-btn>
      </v-col>

      <v-col cols="12">
          <v-btn color="primary" outlined rounded block to="/rep-onboard-tool">
            New Dealer On Boarding Sales Tool
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-btn color="primary" outlined rounded block to="/emphome">
            Employee Production Trackers
          </v-btn>
        </v-col>

      <!-- <v-col cols="12">
        <v-btn color="primary" outlined rounded block to="/partType-editor">
          View/Edit Parts
        </v-btn>
      </v-col> -->

    </v-row>
  </v-container>
</template>


<script>
import { } from "@/services";


export default {
  name: "EditorNavigation",

  data: () => ({


  }),

  mounted() {
  },

  computed: {
  
  },

  methods: {
  },

}
</script>

<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-img
            src="../assets/aspenLogo.png"
            contain
            max-height="200"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        
      
        <v-col cols="12" v-if="userTypeId === 1 || (userTypeId === 4 && this.currentUser.dealers.includes(167))">
          <v-btn color="primary" outlined rounded block to="/exorder">
            Place New Example Order
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1 || (userTypeId === 4 && this.currentUser.dealers.includes(167))">
          <v-btn color="primary" outlined rounded block to="/exorder-list">
            View/Edit Example Current Orders
          </v-btn>
        </v-col>

        <!-- <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 4">
          <v-btn color="primary" outlined rounded block to="/order-list-available">
            Claim Currently Available Example Spas
          </v-btn>
        </v-col> -->
       
      </v-row>
    </v-container>
  </div>

</template>

<script>
import { getOrders } from "@/services";

export default {
  name: "RepOnBoardTool",

  data: () => ({
    approvedOrders: []
  }),

  mounted() {
    getOrders().then((result) => {
      result.data.forEach(a => { if(a.status === "Pending") {
        this.approvedOrders.push(a);
      }
      })
    })
  },

  computed : {
    isLoggedIn() { return this.$store.getters.isLoggedIn },
    currentUser() { return this.$store.state.user },
    userTypeId() { return this.$store.getters.userTypeId }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      })
    }
  },
  components: {},
};
</script>

<template>
  <v-container class="mt-16">
    <v-row justify="center">
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="5"
        xl="3"
      >
        <v-card class="mt-16 px-6 rounded-lg" elevation="4">
          <v-row>
            <v-img
              src="../assets/aspenLogo.png"
              contain
            ></v-img>
          </v-row>
          <v-row>
            <v-container class="justify-center px-14">
              <v-card elevation="0">
                <v-card-title class="text-center primary--text font-weight-bold text-h5 pt-0">
                  <v-container>
                    Password Recovery
                  </v-container>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-card-subtitle class="secondary--text">Enter your email linked with Aspen Spas:</v-card-subtitle>
                  <v-text-field
                    class="pt-2"
                    outlined
                    dense
                    v-model="email"
                    placeholder="Your Email..."
                  ></v-text-field>
                  <v-alert
                    v-model="failAlert"
                    type="error"
                    text
                    dense
                  >
                    Email Not Linked To An Aspen Spas Account!
                  </v-alert>
                  <v-alert
                    v-model="successAlert"
                    type="success"
                    text
                    dense
                  >
                    Reset Link Has Been Sent To <strong>{{ email }}</strong>!
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-btn block elevation="0" class="secondary mb-6" @click="sendReset(email)">Email me a Recovery Link</v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { resetPasswordEmail } from "@/services"

export default {
  name: "ForgotPassword",

  data: () => ({
    emailSent: false,
    email: "",
    failAlert: false,
    successAlert: false,

  }),

  methods: {
    sendReset(email) {
      resetPasswordEmail(email).then((result) => {
        console.log(result.data)
        if(result.data) {
          this.failAlert=false;
          this.successAlert=true;
        } else {
          this.failAlert=true;
          this.successAlert=false;
        }
      })
    }
  },
};
</script>

<template>
  <v-card>
    <v-banner
    sticky
    color="white"
    elevation="7"
    class="px-16 text-center">
    
    <v-select
     v-model="request.dealers"
     label="Dealer(s)"
     :items="dealer"
      multiple
      chips
    >
  </v-select>
    <v-row class="mx-auto">
    <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="request.startDate"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="request.startDate"
            label="Start Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="request.startDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(request.startDate)"
          >
            OK
          </v-btn>
        </v-date-picker>

    </v-menu>
    <v-menu
    ref="menu2"
    v-model="menu2"
    :close-on-content-click="false"
    :return-value.sync="request.endDate"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="request.endDate"
            label="End Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="request.endDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(request.endDate)"
          >
            OK
          </v-btn>
        </v-date-picker>

      </v-menu>
    </v-row>
    <v-btn color="primary" @click="submitDataRequest()">Populate Graphs</v-btn>
    </v-banner>
    <br/>

    <v-row v-if="!this.dataHere" justify="center">
           <v-card  elevation="7" class="ma-3 pa-2 rounded-lg">
          <v-card-title class="justify-center"> Enter Paramaters Above to Populate Graphs</v-card-title>
          </v-card>
    </v-row>

    <v-row v-if="this.dataHere" justify="center">
           <v-card  elevation="7" class="ma-3 pa-2 rounded-lg">
          <v-card-title class="justify-center"> Hot Tubs Ordered By Month</v-card-title>
              <GChart
              type="LineChart"
                :data="orderData"
                :options="chartOptionsLine"
              >
              </GChart>
          </v-card>
    </v-row>

    <v-row v-if="this.dataHere" justify="center">
    <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Model Graph</v-card-title>    
              <GChart     
                type="BarChart"
                :data="modelsData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
  
  
  <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Series Graph</v-card-title>    
              <GChart     
                type="PieChart"
                :data="seriesData"
                :options="chartOptionsPie"
              >
              </Gchart>
            </v-card>
          </v-row>
          <v-row v-if="this.dataHere" justify="center">
    <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Acrylic Graph</v-card-title>    
              <GChart     
                type="BarChart"
                :data="acrylicData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
  
  
  <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Cabinet Graph</v-card-title>    
              <GChart     
                type="BarChart"
                :data="cabinetData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
          </v-row>
          </v-card>
</template>

<script>
import { getDealers, sendModelRequest, sendSeriesRequest, sendAcrylicRequest, sendCabRequest, sendDateCountDealerRequest } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "DealerAnalytics",

  data: () => ({
    dataHere: false,
    dealer: [],
    dealers: [],
    request: {
      startDate: null,
      endDate: null,
      dealers: []
    },
    modelsData: [
      ['Model', 'Sales', {role: 'annotation'}],
    ],
    seriesData: [
      ['Series', 'Sales']
    ],
    acrylicData: [
       ['Option', 'Count']
     ],
    cabinetData: [
      ['Option', 'Count']
    ],
    orderData: [
      ['Dates', 'Count']
    ],
    
    chartOptionsBar: {
      width: 700,
      height: 350,
      legend: { position: 'none'},
      titlePosition: 'none',
    },
    chartOptionsLine: {
      width: 1400,
      height: 350,
      legend: { position: 'none'},
      titlePosition: 'none',
    },
    chartOptionsPie: {
      width: 700,
      height: 350,
      legend: { position: 'top', alignment: 'center'},
      titlePosition: 'none',
    },
    menu: false,
    menu2 :false
  }),

  mounted() {
    getDealers().then((result) => {
      result.data.forEach((d) => this.dealers.push({ value: d.id, text: d.name }))
      this.dealer = this.dealers
      this.dealer.unshift({value: 0, text: "All"})
    })
  },


  computed: {
    
  },

  methods: {
    submitDataRequest() {
      this.modelsData=[['Model', 'Sales', {role: 'annotation'}]];
      this.seriesData=[['Series', 'Sales']];
      this.acrylicData=[['Option', 'Count']];
      this.cabinetData=[['Option', 'Count']];
      this.orderData=[['Dates', 'Count']]
      sendModelRequest(this.request).then((result) => {
        result.data.forEach((m) => this.modelsData.push([m.modelName, m.modelCount, m.modelGroup]))
      });
      sendSeriesRequest(this.request).then((result) => {
        result.data.forEach((s) =>  this.seriesData.push([s.seriesName, s.seriesCount]))
      });
      sendAcrylicRequest(this.request).then((result) => {
        result.data.forEach((a) => this.acrylicData.push([a.optionName, a.optionCount]))
      });
      sendCabRequest(this.request).then((result) => {
        result.data.forEach((c) => this.cabinetData.push([c.optionName, c.optionCount]))
      });
      sendDateCountDealerRequest(this.request).then((result) => {
        result.data.forEach((o) => this.orderData.push([o.dates, o.dealerOrders]));
      })
      this.dataHere = true
    }
  },


  watch: {
    
  },

  components: {
    GChart
  },

}
</script>

<template>
<div>
  <v-data-table
  height='500'
  fixed-header
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :headers="computedHeaders"
    :items="shownOrders"
    :search="search"
    :loading="loading"
    loading-text="Loading Orders..."
    class="elevation-2 rounded-lg d-print-none"
  >
    <template v-slot:top>
      <v-toolbar flat class="my-2">
        <v-toolbar-title class="text-h4 primary--text font-weight-bold">Example Orders</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>          
        <v-spacer></v-spacer>
       
        <!-- <v-switch
          v-model="showClosedOrders"
          color="primary"
          label="Show Completed"
        ></v-switch> -->
   
      </v-toolbar>
    </template>

     <template v-slot:item.createdAt="{ item }">
      {{moment.utc(item.createdAt).local().format('MM/DD/YYYY')}}
    </template>
    <template v-slot:item.closing="{ item }">
      {{moment.utc(item.closing).local().format('MM/DD/YYYY')}}
    </template>
    <template v-slot:item.modelName="{ item}">
        {{item.modelNameOverride ? item.modelNameOverride : item.modelName }}
      </template>
      <template v-slot:item.orderDetails="{ item }">
        <v-btn icon @click="currentOrderId=item.id; showWorkOrder=true">
          <v-icon color="secondary">mdi-receipt-text</v-icon>
        </v-btn>
      </template>

    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon :to="'/exorder/'+item.id" v-if="item.status==='Test Order'" v-on="on">
            <v-icon color="secondary">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Order</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="orderToDelete=item; confirmDelete=true;" v-if="item.status==='Test Order'" v-on="on">
            <v-icon color="primary">mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete Order</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon :to="'/exorder/'+item.id+'/true'" v-on="on">
            <v-icon color="secondary"> mdi-content-copy </v-icon>
          </v-btn>
        </template>
        <span>Duplicate Order</span>
      </v-tooltip>      
    </template>

  </v-data-table>
  <v-dialog max-width="350" v-model="confirmDelete">
    <v-card>
      <v-card-title class="secondary--text d-flex justify-center text-h5">
        Are you sure you want to delete Order #{{ orderToDelete.serialNumber }}?
      </v-card-title>
      <v-card-actions>
        <v-btn text color="primary" @click="confirmDelete=false; orderToDelete={};">No</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="deleteOrder(orderToDelete); confirmDelete=false">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog fullscreen v-model="showWorkOrder">
    <v-toolbar
    class="d-print-none"
      flat
      color="secondary"
    >
      <v-btn @click="showWorkOrder=false">Close</v-btn>
       <v-spacer></v-spacer>
        <!-- <v-btn icon color="white" @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn> -->
    </v-toolbar>
    <SalesOrderCust :orderId="currentOrderId"></SalesOrderCust>
  </v-dialog>
  <v-snackbar
    v-model="snackbarAlert"
    text
    color="primary"
  >
    <v-container class="text-center text-h6">
      {{ alertMsg }}
    </v-container>
  </v-snackbar>
</div>
</template>

<script>
import { getOrders, deleteOrder } from "@/services";
import SalesOrderCust from "../views/SalesOrderCust.vue";

export default {
  name: "ExampleOrderList",

  data() {
    return {
      search: "",
      loading: false,
      moment: require('moment'),
      showClosedOrders: false,
      headers: [
        {
          text: "Serial No",
          align: "start",
          value: "serialNumber",
        },
        { text: "Order Date", value: "createdAt" },
        { text: "Ship Date", value: "closing"},
        { text: "PO Number", value: "poNumber" },
        { text: "Customer Name", value: "customer"},
        { text: "Model", value: "modelName" },
        { text: "Series", value: "seriesName" },
        { text: "Sales Order", value: "orderDetails"},
        { text: "Order Total", value: "totalCost" },
        { text: "Status", value: "status" },
        { text: "User", value: "usersFullName"},
        { text: "Actions", sortable: false, value: "actions" },
      ],
      orders: [],
      confirmDelete: false,
      orderToDelete: {},
      currentOrderId: null,
      showWorkOrder: false,
      snackbarAlert: false,
      alertMsg: ""
    };
  },

  mounted() {
    this.loading = true;
    getOrders().then((result) => {
      this.orders = result.data.filter((order) => order.dealerId === 167 && order.userId === this.currentUser.id);
      this.loading = false;
    });
  },

  methods: {
    editOrder(order) {
      console.log("Edit order" + order);
    },
    deleteOrder(order) {
      //confirm
      deleteOrder(order.id).then((result) => {
          //splice it out of list
          let i = this.orders.findIndex(o => o.id === order.id);
          this.orders.splice(i, 1);
          this.alertMsg = "Successfully deleted order " + order.serialNumber;
          this.snackbarAlert = true;
          console.log("Deleted order:" + order.id);
        }).catch((err) => {
          this.alertMsg = "Failed to delete order " + order.serialNumber;
          this.snackbarAlert = true;
          console.log("Failed to Delete order:" + order.id);
        })
    },
    print() {
      window.print()
    },
  },
  computed: {
    currentUser() { return this.$store.state.user },
    shownOrders: function () {
      if(this.showClosedOrders === false)
        {return this.orders.filter((order) => order.status !== "Example Completed");} 
        else {return this.orders.filter((order) => order.status === "Example Completed");}
    },
    computedHeaders () {
      if(this.showClosedOrders)
      {return this.headers.filter((h) => h.value !== "createdAt")}
      else
      {return this.headers.filter((h) => h.value !== "closing")}
    },
     sortBy () {
      return this.showClosedOrders ? "closing" : "createdAt"
    },
    sortDesc () {
      return this.showClosedOrders ? true : false
    },
  },
  components: {
    SalesOrderCust,
  }

};
</script>

<style></style>

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#ea6425", // orange #ea6425
        secondary: "#5a3d36", // brown #5a3d36
        accent: "#F7C5AD", // light orange
      },
    },
  },
});

<template>
<div>
  <v-card elevation="0"
    class="d-print-none"
    height="100%"
  >
    <v-row>
      <v-col>
        <v-card class="text-center" elevation="0">
        <v-btn
        icon
        class="ma-2"
        @click="focus=moment(focus).subtract(1, 'weeks'); getSelectedWeek()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <span class="secondary--text font-weight-bold">
        {{ moment(focus).format("MMMM, YYYY")}}
      </span>
      <v-btn
        icon
        class="ma-2"
        @click="focus=moment(focus).add(1, 'weeks'); getSelectedWeek()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      </v-card>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col v-for="day in currentWeek" :key="day.id">
        <v-card flat class="rounded-0">
          <v-card outlined class="rounded-0"  color="blue-grey lighten-5">
            <v-card-title class="d-flex justify-center secondary--text">
              {{ moment(day.date).format("ddd, Do")}}
            </v-card-title>
          </v-card>
          <v-divider></v-divider>
          <v-card v-for="model in getCurrentModels(day.date)" :key="model.id" class="ma-2 rounded-lg">
            <v-container>
              <v-card-title class="justify-center primary--text font-weight-bold text-h5 py-0">
                {{ model }}
              </v-card-title>
              <v-card-actions class="justify-center py-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="primary" small @click="setUpPicker(model, day.date)" v-on="on">
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <span>Move All {{model}}s</span>
                </v-tooltip>
              </v-card-actions>
            </v-container>
            <v-divider></v-divider>
            <v-list-item v-for="order in getCurrentOrders(day.date, model)" :key="order.id">
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon @click="currentOrderId=order.id; showWorkOrder=true" v-on="on"> 
                      <v-icon>mdi-receipt-text</v-icon>
                    </v-btn>
                  </template>
                  <span>Sales Order</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-title class="secondary--text font-weight-bold text-center pr-2">
                {{ order.serialNumber }}
              </v-list-item-title>
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon @click="editedProductionDate=order.productionStartDate; selectedOrder=order; datePicker=true" v-on="on">
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <span>Move Order</span>
                </v-tooltip>
              </v-list-item-icon>
            </v-list-item>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    </v-card>

    <v-dialog fullscreen
      v-model="showWorkOrder"
    >
      <v-toolbar
        class="d-print-none"
        flat
        color="secondary"
      >
        <v-btn @click="showWorkOrder=false">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn icon color="white" @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-toolbar>
      <div id="printable">
        <SalesOrder :orderId="currentOrderId"></SalesOrder>
      </div>
    </v-dialog>
    <v-dialog
      v-model="datePicker"
      width="290"
    >
      <v-card>
        <v-card-title class="d-flex justify-center primary--text text-h4 font-weight-bold">
          {{ selectedOrder.id }}
        </v-card-title>
        <v-divider></v-divider>
        <v-date-picker
          v-model="editedProductionDate"
          no-title
          scrollable
        >
          <v-btn
            text
            color="primary"
            @click="datePicker=false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="selectedOrder.productionStartDate=editedProductionDate; editProductionDate(selectedOrder); datePicker=false"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="datePickerModel"
      width="290"
    >
      <v-card>
        <v-card-title class="d-flex justify-center primary--text text-h4 font-weight-bold">
          {{ currentModel }}
        </v-card-title>
        <v-divider></v-divider>
        <v-date-picker
          v-model="editedProductionDate"
          no-title
          scrollable
        >
          <v-btn
            text
            color="primary"
            @click="datePickerModel=false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="editProductionDateModels(); datePickerModel=false"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { getProductionOrders, updateProductionDate } from "@/services";
//import WorkOrder from "../views/WorkOrder.vue";
import SalesOrder from "./SalesOrder.vue";
import moment from 'moment';

export default {
  name: "ProductionSchedule",

  data: () => ({
    orders: [],
    swimSpas: [],
    currentWeek: [],
    currentOrderId: null,
    showWorkOrder: false,
    datePicker: false,
    datePickerModel: false,
    originalDate: null,
    editedProductionDate: null,
    selectedOrder: {},
    currentModel: null,
    currentOrders: null,
    focus: moment(),

    moment: require('moment'),

  }),

  mounted() {
    getProductionOrders().then((result) => {
      result.data.forEach((t) => {
        t.productionStartDate = moment(t.productionStartDate).format('YYYY-MM-DD')
        if(t.modelGroup !== 'S.W.I.M.')
          this.orders.push(t)
        else
          this.swimSpas.push(t)
      });
      let startOfWeek = moment().startOf('week')
      this.currentWeek.push({ date: startOfWeek, id: 1 })
      for (let i = 2; i < 8; i++) {
        startOfWeek = moment(startOfWeek).add(1, 'days')
        this.currentWeek.push({ date: startOfWeek, id: i})   
      };
    })
  },

  methods: {
    getSelectedWeek() {
      this.currentWeek = []
      let startOfWeek = this.focus.startOf('week')
      this.currentWeek.push({ date: startOfWeek, id: 1 })
      for (let i = 2; i < 8; i++) {
        startOfWeek = moment(startOfWeek).add(1, 'days')
        this.currentWeek.push({ date: startOfWeek, id: i})   
      };    
    },

    getCurrentOrders(date, model) {
      date = moment(date).format("YYYY-MM-DD")
      let orders = this.orders.filter(a => moment(a.productionStartDate).format("YYYY-MM-DD") === date).sort(function(a, b) {
        a.modelId - b.modelId
      })
      return orders.filter(order => order.modelName === model)
    },

    getCurrentModels(date) {
      date = moment(date).format("YYYY-MM-DD")
      let orders = this.orders.filter(a => moment(a.productionStartDate).format("YYYY-MM-DD") === date).sort(function(a, b) {
        a.modelId - b.modelId
      })
      let set = new Set()
      orders.forEach(order => set.add(order.modelName))
      return Array.from(set)
    },

    editProductionDate(order) {
      //var index = this.orders.map(function(e) { return e.id; }).indexOf(item.id);
      //var index = this.orders.findIndex(o => o.id === order.id);
      updateProductionDate(order).then((result) => {
        result.data.productionStartDate = moment(result.data.productionStartDate).format('YYYY-MM-DD');
        //result.data.start = result.data.productionStartDate;
        //result.data.name = result.data.id.toString()+' '+result.data.modelName
        //result.data.color = order.color;
        this.orders.splice(this.orders.findIndex((item) => item.id === order.id), 1, result.data);
        //this.getOrders(this.focus);
      })
    },
    print() {
      window.scrollTo(0,0)
      window.print()
    },
    setUpPicker(model, date) {
      this.currentOrders = this.getCurrentOrders(date, model)
      this.originalDate = moment(date).format('YYYY-MM-DD')
      this.editedProductionDate = moment(date).format('YYYY-MM-DD')
      this.currentModel = model
      this.datePickerModel = true
    },
    editProductionDateModels() {
      let orders = this.orders.filter(order => moment(order.productionStartDate).format("YYYY-MM-DD") === moment(this.originalDate).format("YYYY-MM-DD") && order.modelName === this.currentModel)
      orders.forEach(order => {
        order.productionStartDate = moment(this.editedProductionDate).format("YYYY-MM-DD")
        updateProductionDate(order).then((result) => {
          result.data.productionStartDate = moment(result.data.productionStartDate).format('YYYY-MM-DD');
          this.orders.splice(this.orders.findIndex((item) => item.id === order.id), 1, result.data);
        })        
      })
    }
  },

  computed: {

  },

  components: {
    SalesOrder,
  }

}
</script>
<template>
<div>
  <v-data-table
  height='500'
  fixed-header
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :headers="computedHeaders"
    :items="shownOrders"
    :search="search"
    :loading="loading"
    loading-text="Loading Retail Draft Orders..."
    class="elevation-2 rounded-lg d-print-none"
  >
    <template v-slot:top>
      <v-toolbar flat class="my-2">
        <v-toolbar-title class="text-h4 primary--text font-weight-bold">Orders</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>          
        <v-spacer></v-spacer>

        <v-switch
          v-model="showClosedOrders"
          color="primary"
          label="Show Completed"
        ></v-switch>
   
      </v-toolbar>
    </template>

     <template v-slot:item.createdAt="{ item }">
      {{moment.utc(item.createdAt).local().format('MM/DD/YYYY')}}
    </template>
    
    <template v-slot:item.closing="{ item }">
      {{moment.utc(item.closing).local().format('MM/DD/YYYY')}}
    </template>
    
      <template v-slot:item.orderDetails="{ item }">
        <v-btn icon @click="currentOrderId=item.id; showWorkOrder=true">
          <v-icon color="secondary">mdi-receipt-text</v-icon>
        </v-btn>
      </template>

    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="item.status === 'Retail Draft' || item.status==='Pending' || item.status==='Approved'" icon :to="'/retail-order/'+item.id" v-on="on">
            <v-icon color="secondary">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Order</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="item.status === 'Retail Draft' || item.status==='Pending' || item.status==='Approved'" icon @click="orderToDelete=item; confirmDelete=true;" v-on="on">
            <v-icon color="primary">mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete Order</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="item.status === 'Retail Draft'" icon :to="'/order/'+item.id" v-on="on">
            <v-icon color="secondary"> mdi-cart-arrow-right </v-icon>
          </v-btn>
        </template>
        <span>Submit Order</span>
      </v-tooltip>      
    </template>

  </v-data-table>
  <v-dialog max-width="350" v-model="confirmDelete">
    <v-card>
      <v-card-title class="secondary--text d-flex justify-center text-h5">
        Are you sure you want to delete Order #{{ orderToDelete.id }}?
      </v-card-title>
      <v-card-actions>
        <v-btn text color="primary" @click="confirmDelete=false; orderToDelete={};">No</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="deleteOrder(orderToDelete); confirmDelete=false">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog fullscreen v-model="showWorkOrder">
    <v-toolbar
    class="d-print-none"
      flat
      color="secondary"
    >
      <v-btn @click="showWorkOrder=false">Close</v-btn>
       <v-spacer></v-spacer>
        <v-btn icon color="white" @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
    </v-toolbar>
    <SalesOrderEndCust :orderId="currentOrderId"></SalesOrderEndCust>
  </v-dialog>
  <v-snackbar
    v-model="snackbarAlert"
    text
    color="primary"
  >
    <v-container class="text-center text-h6">
      {{ alertMsg }}
    </v-container>
  </v-snackbar>
</div>
</template>

<script>
import { getRetailOrders, deleteOrder } from "@/services";
import SalesOrderEndCust from "../views/SalesOrderEndCust.vue";

export default {
  name: "RetailOrderList",

  data() {
    return {
      search: "",
      loading: false,
      moment: require('moment'),
      showClosedOrders: false,
      headers: [
        {
          text: "Order ID",
          align: "start",
          value: "id",
        },
        { text: "Draft/Submit Date", value: "createdAt" },
        { text: "Ship Date", value: "closing"},
        { text: "Customer Name", value: "customer"},
        { text: "Model", value: "modelName" },
        { text: "Series", value: "seriesName" },
        { text: "Retail Total", value: "retailCost" },
        { text: "Sales Order", value: "orderDetails"},
        { text: "Status", value: "status"},
        { text: "Actions", sortable: false, value: "actions" },
      ],
      orders: [],
      confirmDelete: false,
      orderToDelete: {},
      currentOrderId: null,
      showWorkOrder: false,
      snackbarAlert: false,
      alertMsg: ""
    };
  },

  mounted() {
    this.loading = true;
    getRetailOrders().then((result) => {
      this.orders = result.data;
      this.loading = false;
    });
  },

  methods: {
    editOrder(order) {
      console.log("Edit order" + order);
    },
    deleteOrder(order) {
      //confirm
      deleteOrder(order.id).then((result) => {
          //splice it out of list
          let i = this.orders.findIndex(o => o.id === order.id);
          this.orders.splice(i, 1);
          this.alertMsg = "Successfully deleted order " + order.id;
          this.snackbarAlert = true;
          console.log("Deleted order:" + order.id);
        }).catch((err) => {
          this.alertMsg = "Failed to delete order " + order.id;
          this.snackbarAlert = true;
          console.log("Failed to Delete order:" + order.id);
        })
    },
    print() {
      window.print()
    },
  },
  computed: {
    userTypeId() { return this.$store.getters.userTypeId },

    shownOrders: function () {
      if(this.showClosedOrders === false)
        {return this.orders.filter((order) => order.status !== "Completed");} 
        else {return this.orders.filter((order) => order.status === "Completed");}
  },
  computedHeaders () {
      if(this.showClosedOrders)
      {return this.headers.filter((h) => h.value !== "createdAt")}
      else
      {return this.headers.filter((h) => h.value !== "closing")}
    },
     sortBy () {
      return this.showClosedOrders ? "closing" : "createdAt"
    },
    sortDesc () {
      return this.showClosedOrders ? true : false
    },
},
  components: {
    SalesOrderEndCust,
  }

};
</script>

<style></style>

<template>
  <v-data-table
    :headers="headers"
    :items="models"
    sort-by="name"
    class="elevation-2 rounded-lg"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="text-h4 primary--text font-weight-bold">Model Editor</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>          
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="35%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on">
              New Model
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5"> {{ formTitle }} </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editedUser.name"
                      label="Model Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editedUser.group"
                      label="Spa Size"
                      :items="modelGroups"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-textarea
                      auto-grow
                      rows="1"
                      v-model="editedUser.description"
                      label="Description"
                      placeholder="Enter Description..."
                      persistent-placeholder
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="secondary" text @click="close"> Cancel </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item, index }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="editItem(item, index)">
            <v-icon class="mr-2" color="secondary"> mdi-pencil-outline </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { getModels, createModel, editModel } from "@/services";

export default {
  name: "ModelEditor",

  data: () => ({
    dialog: false,
    search: "",
    headers: [
      {
        text: "Model",
        align: "start",
        value: "name",
      },
      { text: "Spa Size", value: "group"},
      { text: "Description", value: "description", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    editedIndex: -1,

    editedUser: {
      id: null,
      name: "",
      description: "",
      group: "",
    },
    defaultUser: {
      id: null,
      name: "",
      description: "",
      group: "",
    },

    modelGroups: [
      { value: "8'", text: "8'" },
      { value: "7'", text: "7'" },
      { value: "Small", text: "Small" },
      { value: "S.W.I.M.", text: "S.W.I.M." },
    ],

    models: [],
  }),

  mounted() {
    getModels().then((result) => {
      this.models = result.data;
    });
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Model" : "Edit Model";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    editItem(item, index) {
      this.editedIndex = index;
      this.editedUser = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        editModel(this.editedUser).then((result) => {
          this.models.splice(this.editedIndex,1,result.data);
          this.close();
        });
      } else {
        createModel(this.editedUser).then((result) => {
          this.models.push(result.data);
        });
      }
      this.close();
    },
  },
};
</script>

<template>
  <v-card min-height="912" elevation="2" class="px-6">
    <v-card elevation="0" class="my-4 rounded-lg">
      <v-row>
        <v-col cols="6">
          <v-card-title class="pb-0 text-h3 primary--text font-weight-black d-print-none">{{ order.dealerName }}</v-card-title>
          <v-card-title class="pb-0 text-h4 primary--text font-weight-black d-none d-print-block">{{ order.dealerName }}</v-card-title>          
          <v-card-text class="py-0 my-0 subtitle-1 secondary--text"> Order Date: {{ moment.utc(order.createdAt).local().format('MM/DD/YYYY') }} </v-card-text>
          <v-card-subtitle class="secondary--text pt-0 pb-4 my-0">Order Number: <span class="font-weight-bold">{{ this.orderId }}</span></v-card-subtitle>

          <v-card-title class="pt-2 pb-2 text-h5 font-weight-bold secondary--text"> Status: </v-card-title>
          <v-card-text>
            <v-chip class="d-print-none" color="primary"> {{ order.status }} </v-chip>
            <span class="d-none d-print-block font-weight-bold"> {{ order.status }} </span>
          </v-card-text>

          <v-card-text class="pt-4 secondary--text"> Order Placed By: <span class="font-weight-bold"> {{ order.usersFirstName + " " + order.usersLastName }} </span></v-card-text>
        </v-col>
        <v-col cols="6">
          <v-row justify="center">
            <v-container>
              <div class="text-h6 pt-8 font-weight-bold secondary--text"> Dealer Address </div>
              <div class="body-1 primary--text font-weight-bold"> {{ order.dealerName }} </div>
              <div class="subitle-2 secondary--text"> {{ order.dealerAddress1 }} </div>
              <div class="subitle-2 secondary--text"> {{ order.dealerCity + " " + order.dealerStateProvince + ", " + order.dealerPostalCode + " " +order.dealerCountry }} </div>
            </v-container>
            <v-container>
              <div class="pt-4 text-h6 font-weight-bold secondary--text"> Contact Info </div>
              <div class="subitle-2 secondary--text"> {{ order.dealerPhone }} </div>
              <div class="subitle-2 secondary--text"> {{ order.dealerEmail }} </div>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
    <v-card class="mt-4" elevation="0">
      <v-row>
        <v-col>
          <v-card-title class="text-h4 primary--text font-weight-bold">Order Details</v-card-title>
          <v-card-subtitle class="secondary--text">Serial Number: <span class="font-weight-bold">{{ order.serialNumber }}</span></v-card-subtitle>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-content class="secondary--text">
                  <span class="text-h6 font-weight-bold">{{ order.modelName }}  {{ order.seriesName }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  v-for="option in sortColorOptions"
                  :key="option.optionTypeId"
              >
                <v-list-item-content class="pa-0 secondary--text">
                  <div class=""> {{ option.optionTypeName }}: <span class="subtitle-1 font-weight-bold">{{ option.optionName }}</span></div>
                </v-list-item-content>
              </v-list-item>
                    <v-list-item v-if="sortOtherOptions.length>0" class="font-italic font-weight-bold">Upgrades and Add-Ons:</v-list-item>
                          <v-list-item v-else class="font-italic font-weight-bold">No Upgrades or Add-Ons: <br/> Standard {{ order.modelName}} {{order.seriesName}} Build</v-list-item>
              <v-list-item
                  v-for="option in sortOtherOptions"
                  :key="option.optionTypeId"
              >
                <v-list-item-content class="pa-0 secondary--text">
                  <div> {{ option.optionTypeName }}: <span class="subtitle-1 font-weight-bold">{{ option.optionName }}</span></div>
                </v-list-item-content> 
              </v-list-item>   

               <v-list-item v-if="order.specialInstructions">
              <div class=""> {{"Dealer Special Instructions: "}} <span class="subtitle-1 font-weight-bold">{{order.specialInstructions}}</span></div>
            </v-list-item>
             <v-list-item v-if="order.notes">
             <div class=""> {{"Aspen Notes: "}} <span class="subtitle-1 font-weight-bold">{{order.notes}}</span></div>
            </v-list-item>

            </v-list>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { getOrder} from '@/services';

export default {
  name: "SalesOrder",
    
  data () {
    return {
      order:{},
      moment: require('moment'),
      
    };    
  },
  props: ['filter', 'orderId'],

  mounted() {

    getOrder(this.orderId).then((result) => {
      this.order = result.data;
    })

 
  },

  watch: {
    orderId: function () {
      getOrder(this.orderId).then((result) => {
        this.order = result.data;});
    }
  },


  computed: {
    sortColorOptions: function() {
      let sortedOptions = this.order.options.filter(o => o.group === 'color');
      sortedOptions.sort((a, b) => 
         a.optionTypeSequence - b.optionTypeSequence);
          return sortedOptions;},

    sortOtherOptions: function() {
      let sortedOptions = this.order.options.filter(o => (o.group === 'other' || o.group === 'add-ons') && o.standard === null);
      sortedOptions.sort((a, b) => 
         a.optionTypeSequence - b.optionTypeSequence);
          return sortedOptions;},

   },
  methods: {
    
  },
};

</script>

<template>
  <v-card>
    <v-data-table
      class="pl-4 d-print-none"
      :loading="loading"
      loading-text="Loading Orders..."
      :headers="headers"
      :items="filteredOrders"
      :search="search"
      :items-per-page="-1"
      hide-default-footer
      sort-by="productionStartDate"
      fixed-header
      height="600"
    >
      <template v-slot:top>
        <v-toolbar flat class="my-2">
           
          <v-toolbar-title v-if="date === false" class="text-h4 primary--text font-weight-bold">Today's Packaging Report</v-toolbar-title>
           <v-toolbar-title v-if="date === true" class="text-h4 primary--text font-weight-bold">Yesterday's Packaging Report</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>          
          <v-spacer></v-spacer>
           <v-select
            v-model="filter"
            dense
            flat
            outlined
            hide-details
            :items="dealers"
            :item-text="item => `${item.name} (${item.count})`"
            :item-value="item => item.name"
            placeholder="Dealer"
          >
          </v-select>
          <v-spacer></v-spacer>
          <v-switch
            class="mt-8"
            v-model="date"
            inset
            label="Today / Yesterday"
          >
          </v-switch>          
        </v-toolbar>
      </template>
      <!-- <template v-slot:item.progress="{ item }">
        <div v-if="item.status==='Scheduled'">
          <v-chip color="primary">
            Scheduled
          </v-chip>
        </div>
        <div v-else>
          
          <v-progress-circular
            :value="(item.completedPhases/9)*100"
            :color="item.completedPhases >= 9 ? 'green' : 'primary'"
          >
          {{ item.completedPhases }}/9
          </v-progress-circular>
        </div>

      </template>

       <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon :to="'/order/'+item.id" v-if="item.status!=='Completed'" v-on="on">
            <v-icon color="secondary">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Order</span>
      </v-tooltip>
       </template> -->

      <template v-slot:item.productionStartDate="{ item }">
        {{ moment(item.productionStartDate).format('MM/DD/YYYY') }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="currentOrderId=item.id; showWorkOrder=true">
          <v-icon color="secondary">mdi-receipt-text</v-icon>
        </v-btn>
      </template>
      
      <!-- <template v-slot:item.vacuum_form="{ item }">
        <v-checkbox 
          v-model="item.vacuum_form"
          :value="item.vacuum_form"
          @change="updatePhase(item, 'vacuum_form')"
        >
        </v-checkbox>
      </template>
      
      <template v-slot:item.fiberglass="{ item }">
        <v-checkbox
          v-model="item.fiberglass"
          @change="updatePhase(item, 'fiberglass')"
        >
        </v-checkbox>
      </template>
      <template v-slot:item.drilling="{ item }">
        <v-checkbox
          v-model="item.drilling"
          @change="updatePhase(item, 'drilling')"        
        >
        </v-checkbox>
      </template> -->
      <!---
      <template v-slot:item.frame="{ item }">
        <v-checkbox
          v-model="item.frame"
          @change="updatePhase(item, 'frame')"        
        >
        </v-checkbox>
      </template>
      <template v-slot:item.door="{ item }">
        <v-checkbox
          v-model="item.door"
          @change="updatePhase(item, 'door')"        
        >
        </v-checkbox>
      </template>
      <template v-slot:item.plumbing="{ item }">
        <v-checkbox
          v-model="item.plumbing"
          @change="updatePhase(item, 'plumbing')"        
        >
        </v-checkbox>
      </template>
      <template v-slot:item.test="{ item }">
        <v-checkbox
          v-model="item.test"
          @change="updatePhase(item, 'test')"        
        >
        </v-checkbox>
      </template>
      <template v-slot:item.packaging="{ item }">
        <v-checkbox
          v-model="item.packaging"
          @change="updatePhase(item, 'packaging')"        
        >
        </v-checkbox>
      </template>
      <template v-slot:item.closing="{ item }">
        <v-checkbox
          v-model="item.closing"
          @change="updatePhase(item, 'closing')"        
        >
        </v-checkbox> -->
      <!-- </template> -->
    </v-data-table>
    <v-dialog max-width="350" v-model="completeOrder">
      <v-card>
        <v-card-title class="secondary--text d-flex justify-center text-h5">
          Is Order #{{ tempOrder.id }} Completed?
        </v-card-title>
        <v-card-actions>
          <v-btn text color="primary" @click="completeOrder=false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="moveToComplete(tempOrder); completeOrder=false">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   <v-dialog fullscreen
      v-model="showWorkOrder"
   >
      <v-toolbar
        class="d-print-none"
        flat
        color="secondary"
      >
        <v-btn @click="showWorkOrder=false">Close</v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn icon color="white" @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>            -->
      </v-toolbar>
      <SalesOrder :orderId="currentOrderId"></SalesOrder>
    </v-dialog>
     <v-bottom-navigation
  color="primary"
  >
   <v-btn v-if="userTypeId === 1" to="/production-status/false">
      <span>Production Tracker</span>
      <v-icon>mdi-progress-check</v-icon>
    </v-btn>

     <v-btn v-else to="/production-status-employee-foreman">
      <span>Jefe Production Tracker</span>
      <v-icon>mdi-progress-check</v-icon>
    </v-btn>

    <v-btn to="/production-status-report-vacuum/false">
      <span>Vac Form Report</span>
      <v-icon>mdi-view-list</v-icon>
    </v-btn>

     <v-btn to="/production-status-report-fiberglass/false">
      <span>Fiberglass Report</span>
      <v-icon>mdi-view-list</v-icon>
    </v-btn>

    <v-btn to="/production-status-report-drilling/false">
      <span> Drilling Report</span>
      <v-icon>mdi-view-list</v-icon>
    </v-btn>

    <v-btn to="/production-status-report-frames/false">
      <span>Frames Report</span>
      <v-icon>mdi-view-list</v-icon>
    </v-btn>

    <v-btn to="/production-status-report-doors/false">
      <span>Doors Report</span>
      <v-icon>mdi-view-list</v-icon>
    </v-btn>

    <v-btn to="/production-status-report-plumbed/false">
      <span>Plumbing Report</span>
      <v-icon>mdi-view-list</v-icon>
    </v-btn>

    <v-btn to="/production-status-report-test/false">
      <span>Testing Report</span>
      <v-icon>mdi-view-list</v-icon>
    </v-btn>

    <v-btn to="/production-status-report-packaged/false">
      <span>Packaging Report</span>
      <v-icon>mdi-view-list</v-icon>
    </v-btn>
  </v-bottom-navigation>

  </v-card>
</template>

<script>
import { getDealerOrders, getProductionOrders, updatePhase, updateOrderStatus } from "@/services"
import SalesOrder from "./SalesOrder.vue";
import moment from 'moment';

export default {
  name: "ProductionStatusReportPackaged",

  data:() => ({
    loading: false,
    search: "",
    filter: "",
    moment: require('moment'),
    completeOrder: false,
    currentDate: moment().format("YYYY-M-DD"),
    yesterdayDate: moment().subtract(1, 'days').format("YYYY-M-DD"),
    currentOrderId: null,
    showWorkOrder: false,
    date: false,
    todayOrders: [],
    yesterdayOrders: [],
    finalOrder: {},
    tempOrder: {},
    headers: [
        { text: "Dealer",  value: "dealerName" },
        { text: "Model", value: "modelName"},
        { text: "Series", value: "seriesName"},
        { text: "Serial No",  value: "serialNumber", groupable: false },
        { text: "Production Start Date", value: "productionStartDate", groupable: false },
        { text: "Sales Order",  value: "options", groupable: false },
        // { text: "Vacuum Formed",  value: "vacuum_form", groupable: false },
        // { text: "Fiberglassed",  value: "fiberglass", groupable: false },
        // { text: "Drilled",  value: "drilling", groupable: false },
        // { text: "Framed",  value: "frame", groupable: false },
        // { text: "Doors",  value: "door", groupable: false },
        // { text: "Plumbed",  value: "plumbing", groupable: false },
        // { text: "Tested",  value: "test", groupable: false },
        // { text: "Packaged",  value: "packaging", groupable: false },
        // { text: "Shipped",  value: "closing", groupable: false },
          
    ],
  }),

  mounted() {
    this.loading = true;   
    getProductionOrders().then((result) => {
      result.data.forEach(a => {
        a.completedPhases = this.getCompletedPhases(a);
        if ((a.modelGroup !== 'S.W.I.M.') && (a.packaging === this.currentDate))
          this.todayOrders.push(a);
        else if ((a.modelGroup != 'S.W.I.M.') && (a.packaging === this.yesterdayDate))
        this.yesterdayOrders.push(a)
      })
      this.loading = false;
    });

   

  },

  props: ['swimProp'],

  methods: {
    updatePhase(order, phase) {
      let index = this.orders.findIndex(o => o.id === order.id);
      this.finalOrder = { orderId: order.id, phase: phase, complete: order[phase] };
      updatePhase(this.finalOrder).then((result) => {
        order[phase] = result.data;
        order.completedPhases = this.getCompletedPhases(order);
        if (order.completedPhases > 0 && order.status === 'Scheduled') {
          this.moveToProduction(order);
          console.log("Move to In Production!");
        }
        if (order.completedPhases >= 9) {
          this.tempOrder = order;
          this.completeOrder = true;
          console.log("Completed!");
        }
        if (order.completedPhases <= 0 && order.status === 'In Production') {
          this.moveToScheduled(order)
        }
        //this.orders.splice(index,1,order);
      })
    },

    updateStatus(order, status) {
      updateOrderStatus({ orderId: order.id, orderStatus: status }).then((result) => {
        order.status = status;
      })
    },

    moveToScheduled(order) {
      this.updateStatus(order, "Scheduled")
    },

    moveToProduction(order) {
      this.updateStatus(order, 'In Production');
    },

    moveToComplete(order) {
      this.updateStatus(order, 'Completed');
    },

    getCompletedPhases(order) {
      var value = 0;
      if(order.vacuum_form) {
        value ++;
      }
      if(order.fiberglass) {
        value ++;
      }
      if(order.drilling) {
        value ++;
      }
      if(order.frame) {
        value ++;
      }
      if(order.door) {
        value ++;
      }
      if(order.plumbing) {
        value ++;
      }
      if(order.test) {
        value ++;
      }
      if(order.packaging) {
        value ++;
      }
      if(order.closing) {
        value ++;
      }
      return value;
    },
    print() {
      window.print()
    }
  },

  computed: {
    userTypeId() { return this.$store.getters.userTypeId },
    
    filteredOrders: function() {
        if (this.filter && this.filter !== "All" && !this.date)
        return this.todayOrders.filter(order => order.dealerName === this.filter) 
      else if ((!this.filter || this.filter === "All") && this.date)
        return this.yesterdayOrders
      else if ((this.filter && this.filter !== "All") && this.date)
        return this.yesterdayOrders.filter(order => order.dealerName === this.filter)
      else
        return this.todayOrders
    },
    dealers() {
      var arrayToCount = this.date ? this.yesterdayOrders : this.todayOrders;
      var dlrs = [];
      arrayToCount.forEach(o => {
        let df = dlrs.find(d => d.name === o.dealerName);
         if (df) {
           df.count ++;
         } else {
           dlrs.push({ name: o.dealerName, count: 1 });
         }
      })

      dlrs.sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
      })

      dlrs.unshift({ name: "All", count: arrayToCount.length });

      return dlrs;
    }

  },

  components: {
    SalesOrder,
  }
}
</script>

<style scoped>
 
</style>

<template>
  <v-card min-height="912" elevation="2" class="px-6">
    <v-card elevation="0" class="my-4 rounded-lg">
      <v-row>
        <v-col cols="6">
          <v-card-title class="pb-0 text-h3 primary--text font-weight-black d-print-none">{{ order.dealerName }}</v-card-title>
          <v-card-title class="pb-0 text-h4 primary--text font-weight-black d-none d-print-block">{{ order.dealerName }}</v-card-title>          
          <v-card-text class="py-0 my-0 subtitle-1 secondary--text"> Order Date: {{ moment.utc(order.createdAt).local().format('MM/DD/YYYY') }} </v-card-text>
          <v-card-subtitle class="secondary--text pt-0 pb-4 my-0">Order Number: <span class="font-weight-bold">{{ this.orderId }}</span></v-card-subtitle>

          <v-card-title class="pt-2 pb-2 text-h5 font-weight-bold secondary--text"> Status: </v-card-title>
          <v-card-text>
            <v-chip class="d-print-none" color="primary"> {{ order.status }} </v-chip>
            <span class="d-none d-print-block font-weight-bold"> {{ order.status }} </span>
          </v-card-text>

          <v-card-text class="pt-4 secondary--text"> Order Placed By: <span class="font-weight-bold"> {{ order.usersFirstName + " " + order.usersLastName }} </span></v-card-text>
        </v-col>
        <v-col cols="6">
          <v-row justify="center">
            <v-container>
              <div class="text-h6 pt-8 font-weight-bold secondary--text"> Dealer Address </div>
              <div class="body-1 primary--text font-weight-bold"> {{ order.dealerName }} </div>
              <div class="subitle-2 secondary--text"> {{ order.dealerAddress1 }} </div>
              <div class="subitle-2 secondary--text"> {{ order.dealerCity + " " + order.dealerStateProvince + ", " + order.dealerPostalCode + " " +order.dealerCountry }} </div>
            </v-container>
            <v-container>
              <div class="pt-4 text-h6 font-weight-bold secondary--text"> Contact Info </div>
              <div class="subitle-2 secondary--text"> {{ order.dealerPhone }} </div>
              <div class="subitle-2 secondary--text"> {{ order.dealerEmail }} </div>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
    <v-card class="mt-4" elevation="0">
      <v-row>
        <v-col>
          <v-card-title class="text-h4 primary--text font-weight-bold">Part Order Details</v-card-title>
          <!-- <v-card-subtitle class="secondary--text"></v-card-subtitle> -->
          <v-card-text>
            <v-list>
              <v-list-item
                  v-for="option in this.order.options"
                  :key="option.partOrderId"
              >
                <v-list-item-content class="pa-0 secondary--text">
                 <span class="subtitle-1 font-weight-bold">{{ option.partName }}</span>
                  
                </v-list-item-content>

                <v-list-img>
                  <v-img
                  :src="option.img"
                  width="50">
                </v-img>
                </v-list-img>
              <v-spacer></v-spacer>
                <v-list-item-content>
                  Unit Price: {{ option.unitPrice }}
                </v-list-item-content>
                <v-list-item-content>
                  QTY: {{ option.partOrderItemQty }}
                </v-list-item-content>
                <v-list-item-action-text>
                   {{ option.unitPrice * option.partOrderItemQty }}
                </v-list-item-action-text>
                
                
              
              </v-list-item>
              
            <v-list-item>
              <v-list-item-content>
                <div class="subtitle-1 font-weight-bold">Order Total</div>
              </v-list-item-content>
              <v-list-item-action-text>
            <div>${{order.totalCost}}</div>
            </v-list-item-action-text>
            </v-list-item>            
            </v-list>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { getPartOrder} from '@/services';

export default {
  name: "SalesOrderCust",
    
  data () {
    return {
      order:{},
      moment: require('moment'),
    };    
  },
  props: ['filter', 'orderId'],

  mounted() {

    getPartOrder(this.orderId).then((result) => {
      this.order = result.data;
    })

 
  },

  watch: {
    orderId: function () {
      getPartOrder(this.orderId).then((result) => {
        this.order = result.data;});
    }
  },


  computed: {
    // sortColorOptions: function() {
    //   let sortedOptions = this.order.options.filter(o => o.group === 'color');
    //   sortedOptions.sort((a, b) => 
    //      a.optionTypeSequence - b.optionTypeSequence);
    //       return sortedOptions;},

    // sortOtherOptions: function() {
    //   let sortedOptions = this.order.options.filter(o => (o.group === 'other' || o.group === 'add-ons'));
    //   sortedOptions.sort((a, b) => 
    //      a.optionTypeSequence - b.optionTypeSequence);
    //       return sortedOptions;},
   },
  methods: {
   
  },
};

</script>

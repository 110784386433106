<template>
  <div>
    <v-container v-if="catalog">
      <v-row class="mb-1">
        <v-col cols="8">
          <h2>{{ catalog.dealerName }} Retail Catalog Editor</h2>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-btn cols="2" color="primary" @click="saveRC()">Save Retail Catalog</v-btn>
        </v-col>
      </v-row>
      <v-alert
        :value=failedValidation
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
      
      <h2>Retail Catalog has been Saved but is Not Complete</h2>
      <h3>Please fix the following errors to Complete the Retail Catalog and begin Placing and Viewing Retail Orders:</h3>
      <div class="mt-n5" v-html="validationMessage"></div>
      </v-alert>
      <v-alert
        :value=successValidation
        border="top"
        colored-border
        type="success"
        elevation="2"
      >
      <v-row>
        <v-col class="text-center">
          <h2>Retail Catalog has been Saved and is Complete</h2>
      <h3>Place and View Retail Orders is now available from the Retail Orders Home</h3>
      <v-btn color="primary" outlined rounded block to="/retailhome">
            Retail Orders Home
          </v-btn>
        </v-col>
      </v-row>
      </v-alert>

      <v-expansion-panels>  
        <v-expansion-panel class="my-1" v-for="model in catalog.modelGroups" :key="'model-'+model.catBuild">
         
          <v-expansion-panel-header class="text-h6" color="blue-grey lighten-1">
            {{ model.modelGroupName }}<v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer> <v-chip v-show="model.hide" color="primary" class="mx-8 pa-2 justify-center">Hidden</v-chip><v-chip v-show="!model.hide" color="success" class="mx-8 pa-2 justify-center">Available</v-chip>
          </v-expansion-panel-header>
          
          <v-expansion-panel-content>
            
            <v-checkbox v-model="model.hide" class="ml-4" :label="'Hide '+model.modelGroupName"></v-checkbox>

            <v-expansion-panels  v-if="model.seriesGroups && !model.hide">
               <v-expansion-panel class="my-1" v-for="series in model.seriesGroups" :key="'series-'+model.catBuild+'-'+series.catBuild">
                  
                <v-expansion-panel-header class="text-subtitle-2" color="blue-grey lighten-3">
                     {{model.modelGroupName+' '+series.seriesGroupName}} <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer> <v-tooltip left color="#000000"> <template v-slot:activator="{ on }"> <v-icon v-show="!series.hide && !series.retailPrice" v-on="on" class="pr-4" color="red">mdi-alert</v-icon> </template> <span> Dealer is required to Hide Series or enter Retail Series Price</span> </v-tooltip><v-chip v-show="series.retailPrice" color="success" class="mx-8 pa-2 justify-center">Retail Price: ${{ series.retailPrice }} </v-chip><v-chip v-show="series.hide" color="primary" class="mx-8 pa-2 justify-center">Hidden</v-chip>  Wholesale Cost: ${{ series.price }}
                  </v-expansion-panel-header>
                
                  <v-expansion-panel-content>

                  <v-row> <v-col> <v-checkbox v-model="series.hide" class="ml-4" :label="'Hide '+model.modelGroupName+' '+series.seriesGroupName"></v-checkbox></v-col> 
                   <v-col> <v-text-field :rules="[() => !!series.retailPrice || 'Dealer is required to Hide or enter Retail Series Price for each Model/Series']" v-if="!series.hide" v-model="series.retailPrice" class="mt-3 ml-16" label="Retail Series Price" :placeholder="'Retail Series Price for '+model.modelGroupName+' '+series.seriesGroupName" prefix="$" outlined dense clearable></v-text-field> </v-col> </v-row>
                  
                  <v-expansion-panels multiple :value="panels" readonly v-if="series.optionTypes && !series.hide">
                    <v-expansion-panel class="my-1" v-for="optionType in series.optionTypes" :key="'catoptyp-'+model.catBuild+'-'+series.catBuild+'-'+optionType.optionTypeId">
                  
                      <v-expansion-panel-header hide-actions class="text-subtitle-2" color="blue-grey lighten-5">
                        {{ optionType.optionTypeName }}
                      </v-expansion-panel-header>
                        
                      <v-expansion-panel-content>
                  
                  <v-checkbox v-if="optionType.optionTypeName != 'Acrylic Color' && optionType.optionTypeName != 'Cabinet Color' && optionType.optionTypeName != 'Cover Color'" v-model="optionType.hide" class="ml-4" :label="'Hide '+optionType.optionTypeName"></v-checkbox><v-checkbox v-else v-model="optionType.hide" class="ml-4" disabled :label="'Can Not Hide Option Type '+optionType.optionTypeName"></v-checkbox>
                  
                  <v-expansion-panels mandatory :value=0 v-if="optionType.options && !optionType.hide">
                    <v-expansion-panel class="my-1" v-for="option in optionType.options" :key="'catop-'+model.catBuild+'-'+series.catBuild+'-'+option.optionId">
                  
                      <v-expansion-panel-header class="text-subtitle-2">
                        {{ option.optionName }} <span class="font-weight-black pl-2" v-if="option.standard===1">(Standard)</span><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer> <v-tooltip left color="#000000"> <template v-slot:activator="{ on }"> <v-icon v-show="!option.hide && !option.retailPrice" v-on="on" class="pr-4" color="red">mdi-alert</v-icon> </template> <span> Dealer is required to Hide Option or enter Retail Option Price</span> </v-tooltip> <v-chip v-show="option.retailPrice" color="success" class="mx-8 pa-2 justify-center">Retail Price: ${{ option.retailPrice }} </v-chip><v-chip v-show="option.hide" color="primary" class="mx-8 pa-2 justify-center">Hidden</v-chip> Wholesale Cost: ${{ option.price }}
                      </v-expansion-panel-header>
                  
                          <v-expansion-panel-content>
                            
                            <v-row> <v-col> <v-checkbox v-if="!option.standard" v-model="option.hide" class="ml-4" :label="'Hide '+option.optionName"></v-checkbox><v-checkbox v-else v-model="option.hide" class="ml-4" disabled :label="'Can Not Hide Standard Option '+option.optionName"></v-checkbox> </v-col> 
                            <v-col> <v-text-field :rules="[() => !!option.retailPrice || 'Dealer is required to enter Retail Price for each Option they Do Not Hide']" v-if="!option.hide" v-model="option.retailPrice" class="mt-3 ml-16" label="Retail Price" :placeholder="'Retail Price for '+option.optionName" prefix="$" outlined dense clearable></v-text-field> </v-col> </v-row>
                          
                          </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            
                  </v-expansion-panel-content>
                </v-expansion-panel>
               </v-expansion-panels>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    
    </v-container>
   
    <!-- Loading Overlay -->
    <v-overlay :value="loadingCatalog">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
   
    <!-- Dealer Dialog -->
    <v-dialog
          width="350"
          v-model="dealerDialog"
          persistent
        >
          <v-card>
            <v-card-title>Dealer for Retail Catalog Editing</v-card-title>
                <v-select                      
                    class="px-4"
                    placeholder="Select Dealer..."
                    v-model="dealerId"
                    :items="dealers"
                    item-text="dealerName"
                    item-value="dealerId"
                    :rules="[rules.required]"
                ></v-select>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="setDealer()">Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>
import { getRetailCatalogCondensed, getDealersForUser, saveRetailCatalog} from "@/services";

export default {
  name: "RetailCatalogEditor",

  data: () => ({
    loadingCatalog: false,
    catalog: {},
    dealers: [],
    dealerId: null,
    dealerDialog: false,
    panels: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
    rules: { required: value => !!value || 'Required' },
    failedValidation: false,
    successValidation: false,
    validationMessage: ""
  }),

  mounted() {
    if ((this.currentUser.dealers && this.currentUser.dealers.length > 1) || this.currentUser.userTypeId === 1)  {
        getDealersForUser().then((result) => {
          this.dealers = result.data.filter(
            (a) => a.retailCatalogEditorType === "Full"
          )
          this.dealerDialog = true
        });

      } else {
        this.dealerId = this.currentUser.dealers[0];
        this.refreshCatalog();
      }

  },

  computed: {
    currentUser() { return this.$store.state.user },
  },

  methods: {
    setDealer() {
      if (this.dealerId) {
        this.refreshCatalog(); 
        this.dealerDialog=false;
      }
    },
    refreshCatalog() {
      this.loadingCatalog = true;
      getRetailCatalogCondensed(this.dealerId).then((result) => {
        this.catalog = result.data;
        this.loadingCatalog = false;
        console.log("load cat:", this.catalog);
      });

    },
    saveRC() {
console.log('cat', this.catalog)

      this.validationMessage = ""
      this.failedValidation = false
      this.successValidation = false
      
      
       this.catalog.modelGroups.forEach(model => {
        
        if (!model.hide) {
          model.seriesGroups.forEach(ser => {
            
            if (!ser.hide && !ser.retailPrice) {
              this.validationMessage+="<br />Hide or Add Retail Price for " + model.modelGroupName + ' ' + ser.seriesGroupName;
              this.failedValidation=true;
            } 
            if (!ser.hide) {
              let optCount = 0

            ser.optionTypes.forEach(opType => { 
              
              if (!opType.hide) {
                let oCount = 0

                opType.options.forEach(o => {
                 
                  if (!o.hide && !o.retailPrice) {
                    optCount++
                    oCount++
                    if (!ser.hide && ser.retailPrice && oCount ===1 && optCount === 1) {
                      this.validationMessage+="<br />Retial Price for " + model.modelGroupName + ' ' + ser.seriesGroupName + " is $" + ser.retailPrice; 
                    }
                    if (oCount ===1 && optCount === 1) {
                      this.validationMessage+="<br />&nbsp;&nbsp;&nbsp Below are the Options that need to be Hidden or have a Retail Price Added for the " + model.modelGroupName + ' ' + ser.seriesGroupName
                    }
                    if (oCount === 1) {
                      this.validationMessage+="<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; " + opType.optionTypeName
                    }
                    
                    this.validationMessage+="<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -" + o.optionName;
                    this.failedValidation=true;
                  }
                })
              }
            })
            if (optCount >= 1) {
             this.validationMessage+="<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ----------END OF " +  model.modelGroupName + ' ' + ser.seriesGroupName + "----------<br />"
           }
            }
          })
          
        }
       })
       
       if (!this.failedValidation)
       {
        this.successValidation = true;
        this.catalog.complete = 1;
       } else {
        this.catalog.complete = 0;
       }
       saveRetailCatalog(this.catalog);
    }
  }
};
</script>

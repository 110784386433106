<template>
  <div>
    <v-container>
      <v-card flat>
            <v-card-title class="text-h5 font-weight-bold justify-center primary--text">Analytics Pages</v-card-title>
            <v-card-title class="text-h6 font-weight-bold justify-center secondary--text">Open Orders Analytics</v-card-title>
           
           <v-row>
             <v-col>
              <v-card to="/analyticsoo" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">Open Orders Breakdown</v-card-title>
             </v-card>
             </v-col>
             <v-col>
              <v-card to="/analyticsswimoo" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">Open Order Swim Spa Breakdown</v-card-title>
             </v-card>
            </v-col>
            </v-row>
            <v-row>
              <v-col>
              <v-card to="/analyticsoptions" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">Parts / Options / Upgrades On Open Orders Breakdown</v-card-title>
             </v-card>
            </v-col>
            </v-row>

            <v-card-title class="text-h6 font-weight-bold justify-center secondary--text">Series Breakdowns</v-card-title>

            <v-row>
               <v-col>
              <v-card to="/analyticses" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">ES Options Breakdown</v-card-title>
             </v-card>
               </v-col>
               <v-col>
              <v-card to="/analyticsgs" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">GS Options Breakdown</v-card-title>
             </v-card>
               </v-col>
             </v-row>
             <v-row>
               <v-col>
              <v-card to="/analyticsvs" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">VS Options Breakdown</v-card-title>
             </v-card>
               </v-col>
               <v-col>
              <v-card to="/analyticsholiday" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">Holiday Options Breakdown</v-card-title>
             </v-card>
               </v-col>
             </v-row>

             <v-card-title class="text-h6 font-weight-bold justify-center secondary--text">Historical Analytics</v-card-title>

             <v-row>
               <v-col>
                <v-card to="/analytics" outlined hover class="rounded-lg">
                  <v-card-title class="font-weight-bold justify-center primary--text">Historical Analytics</v-card-title>
                </v-card>
               </v-col>
               <v-col>
                  <v-card to="/analyticsswim" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">Historical Swim Spa Analytics</v-card-title>
             </v-card>
            </v-col>
             </v-row>

             <!-- <v-card-title class="text-h6 font-weight-bold justify-center secondary--text">Aspel Analytics</v-card-title>
            
             <v-row>
               <v-col>
              <v-card to="/analyticsaspel" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">Historical Aspel Analytics</v-card-title>
             </v-card>
               </v-col>
             </v-row> -->

             <v-card-title class="text-h6 font-weight-bold justify-center secondary--text">Dealer List and Profiles</v-card-title>
             
             <v-row>
            <v-col>
               <v-card to="/dealer-list" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">Dealer List</v-card-title>
             </v-card>
            </v-col>
            <v-col>
               <v-card to="/dealeranalytics" outlined hover class="rounded-lg">
            <v-card-title class="font-weight-bold justify-center primary--text">Dealer Breakdown Tool</v-card-title>
             </v-card>
            </v-col>
             </v-row>
             <br/>
           </v-card>
    </v-container>
  </div>

</template>

<script>
import { } from "@/services";

export default {
  name: "AnalyticsHome",

  data: () => ({
    
  }),

  mounted() {
    
  },

  computed : {
    currentUser() { return this.$store.state.user },
    userTypeId() { return this.$store.getters.userTypeId }
  },
  methods: {
   
  },
  components: {},
};
</script>

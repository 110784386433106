<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-img
            src="../assets/aspenLogo.png"
            contain
            max-height="200"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="(userTypeId === 2 || userTypeId === 3) && this.retailCatalogComplete !== 1">
          <v-card
          elevation="5"
          outlined
          shaped>
          <v-card-title class="justify-center">
            Welcome to the Retail Catalog Creator
            </v-card-title>
             <v-card-text  class="text-center font-weight-bold">
              Please Complete your Retail Catalog to Place and View Retail Orders
             </v-card-text>
           
          </v-card>
        </v-col>
      </v-row>
      <v-row>

        <v-col cols="12" v-if="userTypeId === 1 || ((userTypeId === 2 || userTypeId === 3) && this.retailCatalogComplete === 1) ">
          <v-btn color="primary" outlined rounded block to="/retail-order">
            Place New Retail Order
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="userTypeId === 1">
          <v-btn color="primary" outlined rounded block to="/retail-order-list-aspen-emp">
            View All Retail Drafts
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="(userTypeId === 2 || userTypeId === 3) && this.retailCatalogComplete === 1">
          <v-btn color="primary" outlined rounded block to="/retail-order-list">
            View/Edit/Submit My Retail Draft Orders
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="userTypeId === 1 || ((userTypeId === 2 || userTypeId === 3) && this.retailCatalogEditorType === 'Full')">
          <v-btn color="primary" outlined rounded block to="/retail-catalog-editor">
            Edit My Retail Catalog
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="userTypeId === 1">
          <v-btn color="primary" outlined rounded block to="/retail-catalog-editor-no-hide">
           No Hide Edit My Retail Catalog
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="(userTypeId === 2 || userTypeId === 3) && this.retailCatalogEditorType === 'Standard'">
          <v-btn color="primary" outlined rounded block to="/retail-catalog-editor-no-hide">
           Edit My Retail Catalog
          </v-btn>
        </v-col>


      </v-row>
    </v-container>
  </div>

</template>

<script>
import { getDealerx } from "@/services";

export default {
  name: "RetailHome",

  data: () => ({
  dealerId: null,
  retailCatalogComplete: null,
  retailCatalogEditorType: null,
  }),

  mounted() {
   if (this.userTypeId !== 1) {
    this.dealerId = this.currentUser.dealers[0];
    getDealerx(this.dealerId).then((result) => {
      this.retailCatalogEditorType = result.data.retailCatalogEditorType;
      this.retailCatalogComplete = result.data.retailCatalogComplete;
    })
    
   }
  },

  computed : {
    currentUser() { return this.$store.state.user },
    userTypeId() { return this.$store.getters.userTypeId },
  },
  methods: {
    
  },
  components: {},
};
</script>

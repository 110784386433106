<template>
<div>
  <v-data-table
  height='500'
  fixed-header
    :headers="headers"
    :items="announcements"
    :search="search"
    :loading="loading"
    loading-text="Loading Announcements..."
    sort-by="createdAt"
    sort-desc
  >
    <template v-slot:top>
      <v-toolbar flat class="my-2">
        <v-toolbar-title class="text-h4 primary--text font-weight-bold">Aspen Announcements</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>          
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" width="75%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on">
                New Announcement
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              <span class="text-h5"> {{ formTitle }} </span>
            </v-card-title>
            <v-card-text>
              <v-text-field
            v-model="editedAnnouncement.title"
            label="Title"></v-text-field>
            <v-file-input
              v-model="announcementFile"
              accept=".pdf"
              label="Upload and Attach File"
              @change="newUploadSelected = true">
            </v-file-input>
            <v-btn
              :loading="uploading"
              :disabled="!newUploadSelected"
              color="blue-grey"
              class="ma-2 white--text"
              @click="uploadFile"
            >
              Upload
              <v-icon
                right
                dark
              >
                mdi-cloud-upload
              </v-icon>
            </v-btn>            
            <v-text-field
              v-model="editedAnnouncement.announcement"
              label="File Attachment Link"
              readonly
              disabled>
            </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancel</v-btn>
              <v-btn color="primary" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-divider></v-divider>
    </template>

    <template v-slot:item.createdAt="{ item }">
      {{moment.utc(item.createdAt).local().format('MM/DD/YYYY')}}
    </template>

   

    <template v-slot:item.announcement="{ item }">  
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="openAttachment(item)">  
            <v-icon class="mr-2" color="secondary"> mdi-receipt-text </v-icon>
          </v-btn>
        </template>
        <span>View Announcement</span>
      </v-tooltip>
    </template>

   
    
    <template v-slot:item.actions="{ item, index }">  
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="editAnnouncement(item, index)">  
            <v-icon class="mr-2" color="secondary"> mdi-pencil-outline </v-icon>
          </v-btn>
        </template>
        <span>Edit Announcement</span>
      </v-tooltip>
    </template>
  
    </v-data-table>
  </div>
</template>

<script>
import { getAnnouncements, editAnnouncement, createAnnouncement, uploadAnnouncementFile } from "@/services";


export default {
  name: "AnnouncementListAspen",

  data: () => ({
    dialog: false,
    viewDialog: false,
    announcements: [],
    search: '',
    loading: false,
    currentDealerId: null,
    editedIndex: -1,
    moment: require('moment'),
    uploading: false,
    newUploadSelected: false,
    announcementFile: null,

    headers: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Announcement Date", value: "createdAt" },
      { text: "Announcement By", value: "name" },
      { text: "Announcement Title", value: "title" },
      { text: "Announcement", value: "announcement", sortable: false },
      { text: "Edit Announcement", value: "actions", sortable: false },
    ],

   
    

    editedAnnouncement: {
      id: null,
      announcement: "",
      title: "",
      userId: null,
      
    },

    defaultAnnouncement: {
      id: null,
      announcement: "",
      title: "",
      userId: null
      
    },    
  }),

  mounted() {
    this.loading = true;
    getAnnouncements().then((result) => {
      this.announcements = result.data;
      this.loading = false;
      this.newUploadSelected = false;
    });
  },

  computed: {
    currentUser() { return this.$store.state.user },
    userTypeId() { return this.$store.getters.userTypeId },
    formTitle() {
      return this.editedIndex === -1 ? "New Announcement" : "Update Announcement"
    },
  },

  methods: {
    editAnnouncement(announcement, index) {
      console.log(announcement)
      this.editedIndex = index;
      this.editedAnnouncement = Object.assign({}, announcement);
      this.dialog = true;
    },

    uploadFile() {
      if (this.announcementFile) {
        this.uploading = true;
        this.newUploadSelected = false;
        uploadAnnouncementFile(this.announcementFile).then((result) => {
        //set announcementUrl
        this.editedAnnouncement.announcement = result.data;
        this.uploading = false;
        });
      }
    },
    openAttachment(item){
      window.open(item.announcement, '_blank');
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedAnnouncement = Object.assign({}, this.defaultAnnouncement);
        this.editedIndex = -1;
      });
    },

    save() {
      this.editedAnnouncement.userId = this.currentUser.id
      if (this.editedIndex > -1) {
        editAnnouncement(this.editedAnnouncement).then((result) => {
          this.announcements.splice(this.editedIndex,1,result.data);
          this.close();
        });
      } else {
        createAnnouncement(this.editedAnnouncement).then((result) => {
          console.log(result);
          this.announcements.push(result.data);
          this.close();
        });
      }
    }
  },
  components: {
  }
};

</script>
<template>
  <v-card>
     <v-banner
    elevation="5"
    class="text-center font-weight-bold">
    <span class="text-h4 font weight-bold"> Holiday Options Breakdown </span> <br/>
    Breakdown of Main Upgrades on Holiday Series Tubs 
    </v-banner>
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-2">
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Holiday Lock Box Controls Upgrade </v-card-title>    
              <GChart 
              type="PieChart"
                :data="hsTSCOptionData"
                :options="hsOptionsOptions"    
              >
              </Gchart>
            </v-card>
            
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Holiday in.Touch 2 Upgrade</v-card-title>    
              <GChart     
                type="PieChart"
                :data="hsIntOptionData"
                :options="hsOptionsOptions"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Holiday LED Upgrade</v-card-title>    
              <GChart 
              type="PieChart"
                :data="hsLEDOptionData"
                :options="hsOptionsOptions"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row> 
   
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-0 mt-n4">
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Holiday Insulation Upgrade</v-card-title>    
              <GChart     
                 type="PieChart"
                :data="hsInsOptionData"
                :options="hsOptionsOptions"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Holiday In Line Frog Upgrade</v-card-title>    
              <GChart     
                type="PieChart"
                :data="hsSanOptionData"
                :options="hsOptionsOptions"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Holiday Circ Pump Upgrade</v-card-title>    
              <GChart     
                 type="PieChart"
                :data="hsCircOptionData"
                :options="hsOptionsOptions"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row>
    
    <v-bottom-navigation
  color="primary"
  >
  <v-btn to="analyticsoptions">
      <span>Open Options</span>
      <v-icon>mdi-table</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>  
    
  <v-btn to="/analyticsoo">
      <span>Open Hot Tubs</span>
      <v-icon>mdi-chart-pie</v-icon>
    </v-btn>
 
    <v-btn to="analyticsswimoo">
      <span>Open SWIM Spas</span>
      <v-icon>mdi-chart-pie-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analyticses">
      <span>Extreme</span>
      <v-icon>mdi-alpha-e-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsgs">
      <span>Great</span>
      <v-icon>mdi-alpha-g-circle-outline</v-icon>
    </v-btn>

    <v-btn to="analyticsvs">
      <span>Value</span>
      <v-icon>mdi-alpha-v-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsholiday">
      <span>Holiday</span>
      <v-icon>mdi-alpha-h-circle-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analytics">
      <span> Hot Tubs</span>
      <v-icon>mdi-chart-box</v-icon>
    </v-btn>

    <v-btn to="analyticsswim">
      <span>SWIM Spas</span>
      <v-icon>mdi-chart-box-outline</v-icon>
    </v-btn>

  </v-bottom-navigation>

  </v-card>
</template>

<script>
import {  getHolidayOptionCount } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "AnalyticsHoliday",

  data: () => ({
    hsTSCOptionData: [
      ['Option', 'Count']
    ],
    hsCircOptionData: [
      ['Option', 'Count']
    ],
    hsIntOptionData: [
      ['Option', 'Count']
    ],
    hsLEDOptionData: [
      ['Option', 'Count']
    ],
    hsInsOptionData: [
      ['Option', 'Count']
    ],
    hsSanOptionData: [
      ['Option', 'Count']
    ],
    hsOptionsOptions: {
     
      width: 500,
      height: 300,
      legend: { position: 'top', alignment: 'center'},
      titlePosition: 'none',
      sliceVisibilityThreshold: .00
    },
  }),

  mounted() {
    getHolidayOptionCount().then((result) => {
      result.data.forEach(o => {
        if (o.optionTypeName === "Insulation") {
          this.hsInsOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Top Side Controls") {
          this.hsTSCOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "24hr. Circulation Pump") {
          this.hsCircOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "in.touch 2 App") {
          this.hsIntOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "LED Lighting") {
          this.hsLEDOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Sanitation") {
          this.hsSanOptionData.push([o.optionName, o.optionCount])
        }
    
      })
    })
  },
  computed: {
    
  },

  components: {
    GChart
  },

}
</script>

<template>
  <v-card>
     <v-banner
    elevation="5"
    class="text-center font-weight-bold">
    <span class="text-h4 font weight-bold"> Open Orders SWIM Spa Breakdown </span> <br/>
    Breakdown of SWIM Spa Model, Series, Cabinet Color and Cover for SWIM Spas On Order
    </v-banner>

     <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-2">

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Swim Models On Order</v-card-title>    
              <GChart     
                type="BarChart"
                :data="modelData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Swim Covers On Order</v-card-title>    
              <GChart     
                type="Table"
                :data="coverData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row>
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-0 mt-n2">
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Swim Series Popularity Breakdown</v-card-title>    
              <GChart     
                type="PieChart"
                :data="seriesData"
                :options="chartOptionsPie"
              >
              </Gchart>
            </v-card>

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Swim Cabinet Popularity Breakdown</v-card-title>    
              <GChart     
                type="BarChart"
                :data="cabinetOptions"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>

        
          </v-card>
           </v-col> 
    </v-row>

    <v-bottom-navigation
  color="primary"
  >
  <v-btn to="analyticsoptions">
      <span>Open Options</span>
      <v-icon>mdi-table</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>  
    
  <v-btn to="/analyticsoo">
      <span>Open Hot Tubs</span>
      <v-icon>mdi-chart-pie</v-icon>
    </v-btn>
 
    <v-btn to="analyticsswimoo">
      <span>Open SWIM Spas</span>
      <v-icon>mdi-chart-pie-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analyticses">
      <span>Extreme</span>
      <v-icon>mdi-alpha-e-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsgs">
      <span>Great</span>
      <v-icon>mdi-alpha-g-circle-outline</v-icon>
    </v-btn>

    <v-btn to="analyticsvs">
      <span>Value</span>
      <v-icon>mdi-alpha-v-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsholiday">
      <span>Holiday</span>
      <v-icon>mdi-alpha-h-circle-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analytics">
      <span> Hot Tubs</span>
      <v-icon>mdi-chart-box</v-icon>
    </v-btn>

    <v-btn to="analyticsswim">
      <span>SWIM Spas</span>
      <v-icon>mdi-chart-box-outline</v-icon>
    </v-btn>

  </v-bottom-navigation>

  </v-card>
</template>

<script>
import { getSwimCoverCount, getSwimOrdersIntervalsOpen, getActiveModelSwimCount, getActiveAspelModelSwimCount, getActiveAspelSwimSeriesCount, getActiveSwimSeriesCount, getActiveSwimCabOptionCount, getActiveSwimAspelCabOptionCount } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "AnalyticsSwim",

  data: () => ({
     aspModelData: [
      ['Model', 'Sales'],
    ],
    modelData: [
      ['Model', 'Sales'],
    ],
    aspSeriesData: [
      ['Series', 'Sales'],
    ],
    seriesData: [
      ['Series', 'Sales'],
    ],
    cabinetOptions: [
      ['Option', 'Count']
    ],
     aspCabinetOptions: [
      ['Option', 'Count']
    ],
    coverData: [
      ['Cover', 'Swim 13 Count', 'Swim 16 Count']
    ],  
    orderCount: [],
    chartOptionsBar: {
      width: 700,
      height: 350,
      legend: { position: 'none'},
      titlePosition: 'none',
    },
      chartOptionsPie: {
      width: 700,
      height: 350,
      legend: { position: 'top', alignment: 'center'},
      titlePosition: 'none',
      sliceVisibilityThreshold: .00
    },
  }),

  mounted() {
    getActiveAspelModelSwimCount().then((result) => {
      result.data.forEach((m) => this.aspModelData.push([ m.modelName, m.modelCount ]));
    })
    getActiveModelSwimCount().then((result) => {
      result.data.forEach((m) => this.modelData.push([ m.modelName, m.modelCount ]));
    })
    getActiveAspelSwimSeriesCount().then((result) => {
      result.data.forEach((s) => this.aspSeriesData.push([ s.seriesName, s.seriesCount ]));
    })
    getActiveSwimSeriesCount().then((result) => {
      result.data.forEach((s) => this.seriesData.push([ s.seriesName, s.seriesCount ]));
    })
    getActiveSwimCabOptionCount().then((result) => {
      result.data.forEach((o) => this.cabinetOptions.push([ o.optionName, o.optionCount])); 
    })
    getActiveSwimAspelCabOptionCount().then((result) => {
      result.data.forEach((o) => this.aspCabinetOptions.push([ o.optionName, o.optionCount])); 
    })
    getSwimOrdersIntervalsOpen().then((result) => {
      this.orderCount = result.data;
    })
    getSwimCoverCount().then((result) => {
      result.data.forEach((o) => this.coverData.push([o.optionName, o.swim13OptionCount, o.swim16OptionCount]));
    })
  },
  computed: {
    
  },

  components: {
    GChart
  },

}
</script>

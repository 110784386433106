<template>
  <v-card>
    <v-data-table
      class="pl-4 d-print-none"
      :loading="loading"
      loading-text="Loading Orders..."
      :headers="headers"
      :items="filteredOrders"
      :search="search"
      :items-per-page="-1"
      hide-default-footer
      sort-by="productionStartDate"
      fixed-header
      height="600"
    >
   
      <template v-slot:top>
        <v-toolbar flat class="my-2">
          <v-toolbar-title class="text-h4 primary--text font-weight-bold">
            Claimed Orders Ready To Be Shiped
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>          
          <v-spacer></v-spacer>
           <v-select
            v-model="filter"
            dense
            flat
            outlined
            hide-details
            :items="dealers"
            :item-text="item => `${item.name} (${item.count})`"
            :item-value="item => item.name"
            placeholder="Dealer"
          >
          </v-select>
          <v-spacer></v-spacer>
          <v-switch
            class="mt-8"
            v-model="swimSpa"
            inset
            label="SWIM Spas"
          >
          </v-switch>          
        </v-toolbar>
         

      </template>
      <!-- <template v-slot:item.progress="{ item }">
        <div v-if="item.status==='Scheduled'">
          <v-chip color="primary">
            Scheduled
          </v-chip>
        </div>
        <div v-else>
          
          <v-progress-circular
            :value="(item.completedPhases/9)*100"
            :color="item.completedPhases >= 8 ? 'green' : 'primary'"
          >
          {{ item.completedPhases }}/9
          </v-progress-circular>
        </div>

      </template> -->

       <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="makeClaimable(item)" v-if="item.status!=='Completed'" v-on="on">
            <v-icon color="primary">mdi-restore</v-icon>
          </v-btn>
        </template>
        <span>Make Available</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon :to="'/order/'+item.id" v-if="item.status!=='Completed'" v-on="on">
            <v-icon color="secondary">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Order</span>
      </v-tooltip>

     
       </template>

      <template v-slot:item.productionStartDate="{ item }">
        {{ moment(item.productionStartDate).format('MM/DD/YYYY') }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="currentOrderId=item.id; showWorkOrder=true">
          <v-icon color="secondary">mdi-receipt-text</v-icon>
        </v-btn>
      </template>

        <template v-slot:item.invoice="{ item }">
        <v-checkbox
          v-model="item.invoice"
          @change="updatePhase(item, 'invoice')"        
        >
        </v-checkbox>
      </template>
     
      <template v-slot:item.closing="{ item }">
        <v-checkbox
          v-model="item.closing"
          @change="updatePhase(item, 'closing')"        
        >
        </v-checkbox>
      </template>
    </v-data-table>

     <v-snackbar
      v-model="availAlert"
      text
      color="primary"
    >
      <v-container class="text-center text-h6">
        {{ alertMsg }}
      </v-container>
    </v-snackbar>
 
    
    <v-dialog max-width="350" v-model="completeOrder">
      <v-card>
        <v-card-title class="secondary--text d-flex justify-center text-h5">
          Is Order #{{ tempOrder.id }} Completed?
        </v-card-title>
        <v-card-actions>
          <v-btn text color="primary" @click="completeOrder=false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="moveToComplete(tempOrder); completeOrder=false">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   <v-dialog fullscreen
      v-model="showWorkOrder"
   >
      <v-toolbar
        class="d-print-none"
        flat
        color="secondary"
      >
        <v-btn @click="showWorkOrder=false">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn icon color="white" @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>           
      </v-toolbar>
      <SalesOrderCust :orderId="currentOrderId"></SalesOrderCust>
    </v-dialog>
   
  </v-card>
</template>

<script>
import { getDealerOrders, getOrdersWithStatus, updatePhase, updateOrderStatus, updateOrderDealerId } from "@/services"
import SalesOrderCust from "./SalesOrderCust.vue";
import moment from 'moment';

export default {
  name: "OrderListClaimed",

  data:() => ({
    loading: false,
    search: "",
    filter: "",
    moment: require('moment'),
    completeOrder: false,
    currentDate: moment().format("YYYY-MM-DD"),
    currentOrderId: null,
    showWorkOrder: false,
    swimSpa: false,
    availAlert: false,
    alertMsg: '',
    orders: [],
    swimSpas: [],
    finalOrder: {},
    tempOrder: {},
    headers: [
        {text: "Make Avail / Edit", value: "actions", groupable: false},
        // {text: "Progress", value: "progress", groupable: false},
        { text: "Sales Order",  value: "options", groupable: false },
        { text: "Serial No",  value: "serialNumber", groupable: false },
        { text: "Dealer",  value: "dealerName" },
        // { text: "Production Start Date", value: "productionStartDate", groupable: false },
        // { text: "Vacuum Formed",  value: "vacuum_form", groupable: false },
        // { text: "Fiberglassed",  value: "fiberglass", groupable: false },
        // { text: "Drilled",  value: "drilling", groupable: false },
        // { text: "Framed",  value: "frame", groupable: false },
        // { text: "Doors",  value: "door", groupable: false },
        // { text: "Plumbed",  value: "plumbing", groupable: false },
        // { text: "Tested",  value: "test", groupable: false },
        // { text: "Packaged",  value: "packaging", groupable: false },
        { text: "Invoiced", value: "invoice", groupable: false },
        { text: "Shipped",  value: "closing", groupable: false },
      ]
  }),

  mounted() {
    this.loading = true;
    if (this.swimProp === "true")
      this.swimSpa = true;    
    getOrdersWithStatus("Claimed").then((result) => {
      result.data.forEach(a => {
        if (a.modelGroup !== 'S.W.I.M.')
          this.orders.push(a);
        else
          this.swimSpas.push(a)
      })
      this.loading = false;
    })
  },

  props: ['swimProp'],

  methods: {
    updatePhase(order, phase) {
      let index = this.orders.findIndex(o => o.id === order.id);
      this.finalOrder = { orderId: order.id, phase: phase, complete: order[phase] };
      updatePhase(this.finalOrder).then((result) => {
        order[phase] = result.data;
       if (order.closing) {
          this.tempOrder = order;
          this.completeOrder = true;
          console.log("Completed!");
        }
       
      })
    },

    updateStatus(order, status) {
      updateOrderStatus({ orderId: order.id, orderStatus: status }).then((result) => {
        order.status = status;
      })
    },

    updateDealerId(order, dealerId) {
      updateOrderDealerId({ orderId: order.id, orderDealerId: dealerId }).then((result) => {
        order.dealer_id = dealerId;
      })
    },

    moveToComplete(order) {
      this.updateStatus(order, 'Completed');
    },

    makeClaimable(order) {
      this.updateStatus(order, 'In Production');
      this.updateDealerId(order, 126);
      this.alertMsg = `Order SN#${order.serialNumber} Has Been Made Available!`;
      this.availAlert=true;
    },

    

   
    print() {
      window.print()
    }
  },

  computed: {
    filteredOrders: function() {
        if (this.filter && this.filter !== "All" && !this.swimSpa)
        return this.orders.filter(order => order.dealerName === this.filter)
      else if ((!this.filter || this.filter === "All") && this.swimSpa)
        return this.swimSpas
      else if ((this.filter && this.filter !== "All") && this.swimSpa)
        return this.swimSpas.filter(order => order.dealerName === this.filter)
      else
        return this.orders
    },
    dealers() {
      var arrayToCount = this.swimSpa ? this.swimSpas : this.orders;
      var dlrs = [];
      arrayToCount.forEach(o => {
        let df = dlrs.find(d => d.name === o.dealerName);
         if (df) {
           df.count ++;
         } else {
           dlrs.push({ name: o.dealerName, count: 1 });
         }
      })

      dlrs.sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
      })

      dlrs.unshift({ name: "All", count: arrayToCount.length });

      return dlrs;
    }
  },

  components: {
    SalesOrderCust,
  }
}
</script>

<style scoped>
  
</style>

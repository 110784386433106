<template>
  <v-card>
     <v-banner
    elevation="5"
    class="text-center font-weight-bold">
    <span class="text-h4 font weight-bold"> ES Options Breakdown </span> <br/>
    Breakdown of Main Upgrades on ES Series Tubs 
    </v-banner>
    <v-row>
            <v-col>
              <v-card elevation="0" class="d-flex justify-center pt-2" >
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Small ES Circ Pump Upgrade</v-card-title>    
              <GChart     
                 type="PieChart"
                :data="esCircOptionData"
                :options="esOptionsOptionsB"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">ES Stereo Add-On</v-card-title>    
              <GChart     
                type="PieChart"
                :data="esStereoOptionData"
                :options="esOptionsOptions"
              >
              </Gchart>
            </v-card>
          </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-0 mt-n2">
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">ES Insulation Upgrade</v-card-title>    
              <GChart     
                 type="PieChart"
                :data="esInsOptionData"
                :options="esOptionsOptions"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">ES Sanitation Upgrade</v-card-title>    
              <GChart     
                type="PieChart"
                :data="esSanOptionData"
                :options="esOptionsOptions"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row>

    <v-bottom-navigation
  color="primary"
  >
  <v-btn to="analyticsoptions">
      <span>Open Options</span>
      <v-icon>mdi-table</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>  
    
  <v-btn to="/analyticsoo">
      <span>Open Hot Tubs</span>
      <v-icon>mdi-chart-pie</v-icon>
    </v-btn>
 
    <v-btn to="analyticsswimoo">
      <span>Open SWIM Spas</span>
      <v-icon>mdi-chart-pie-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analyticses">
      <span>Extreme</span>
      <v-icon>mdi-alpha-e-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsgs">
      <span>Great</span>
      <v-icon>mdi-alpha-g-circle-outline</v-icon>
    </v-btn>

    <v-btn to="analyticsvs">
      <span>Value</span>
      <v-icon>mdi-alpha-v-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsholiday">
      <span>Holiday</span>
      <v-icon>mdi-alpha-h-circle-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analytics">
      <span> Hot Tubs</span>
      <v-icon>mdi-chart-box</v-icon>
    </v-btn>

    <v-btn to="analyticsswim">
      <span>SWIM Spas</span>
      <v-icon>mdi-chart-box-outline</v-icon>
    </v-btn>

  </v-bottom-navigation>

  </v-card>
</template>

<script>
import { getESOptionCount, getSmallESOptionCount } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "AnalyticsES",

  data: () => ({
    esCircOptionData: [
      ['Option', 'Count']
    ],
    esInsOptionData: [
      ['Option', 'Count']
    ],
    esStereoOptionData: [
      ['Option', 'Count']
    ],
    esSanOptionData: [
      ['Option', 'Count']
    ],
    esOptionsOptions: {
     
      width: 700,
      height: 350,
      legend: { position: 'right', alignment: 'center'},
      titlePosition: 'none',
      sliceVisibilityThreshold: .00
    },
    esOptionsOptionsB: {
     
     width: 700,
     height: 350,
     legend: { position: 'top', alignment: 'center'},
     titlePosition: 'none',
     sliceVisibilityThreshold: .00
   },        
  }),

  mounted() {
    getESOptionCount().then((result) => {
      result.data.forEach(o => {
        if (o.optionTypeName === "Insulation") {
          this.esInsOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Stereo") {
          this.esStereoOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Sanitation") {
          this.esSanOptionData.push([o.optionName, o.optionCount])
        }
    
      })
    })
    getSmallESOptionCount().then((result) => {
      result.data.forEach(o => {
        if (o.optionTypeName === "24hr. Circulation Pump") {
          this.esCircOptionData.push([o.optionName, o.optionCount])
        }
      })
    })
  },
  computed: {
    
  },

  components: {
    GChart
  },

}
</script>

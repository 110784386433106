<template>
  <v-card>
    <v-data-table
      class="pl-4 d-print-none"
      :loading="loading"
      loading-text="Loading Orders..."
      :headers="headers"
      :items="filteredOrders"
      :search="search"
      :items-per-page="-1"
      hide-default-footer
      sort-by="productionStartDate"
      fixed-header
      height="500"
    >
      <template v-slot:top>
     <v-toolbar flat class="my-2">
          <v-toolbar-title class="text-h4 primary--text font-weight-bold">Drilling</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-switch
            class="mt-8"
            v-model="completedOrder"
            inset
            label="Completed Orders"
          >
          </v-switch> 
        </v-toolbar> 
          <v-toolbar flat class="my-2">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field> 
          <v-spacer></v-spacer>
           <v-select
            v-model="filter"
            dense
            flat
            outlined
            hide-details
            :items="dealers"
            :item-text="item => `${item.name} (${item.count})`"
            :item-value="item => item.name"
            placeholder="Dealer"
          >
          </v-select>
          </v-toolbar>
      </template>
     

      <template v-slot:item.productionStartDate="{ item }">
        {{ moment(item.productionStartDate).format('MM/DD/YYYY') }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="currentOrderId=item.id; showWorkOrder=true">
          <v-icon color="secondary">mdi-receipt-text</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.drilling="{ item }">
        <v-checkbox
          v-model="item.drilling"
          @change="updatePhase(item, 'drilling')"        
        >
        </v-checkbox>
      </template>

    </v-data-table>
    <!-- <v-dialog max-width="350" v-model="completeOrder">
      <v-card>
        <v-card-title class="secondary--text d-flex justify-center text-h5">
          Is Order #{{ tempOrder.id }} Completed?
        </v-card-title>
        <v-card-actions>
          <v-btn text color="primary" @click="completeOrder=false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="moveToComplete(tempOrder); completeOrder=false">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
   <v-dialog fullscreen
      v-model="showWorkOrder"
   >
      <v-toolbar
        class="d-print-none"
        flat
        color="secondary"
      >
        <v-btn @click="showWorkOrder=false">Close</v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn icon color="white" @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>            -->
      </v-toolbar>
      <SalesOrder :orderId="currentOrderId"></SalesOrder>
    </v-dialog>

  </v-card>
</template>

<script>
import { getDealerOrders, getProductionOrders, updatePhase, updateOrderStatus } from "@/services"
import SalesOrder from "./SalesOrder.vue";
import moment from 'moment';

export default {
  name: "ProductionStatusEmployeeDrilling",

  data:() => ({
    loading: false,
    search: "",
    filter: "",
    moment: require('moment'),
    completeOrder: false,
    currentDate: moment().format("YYYY-MM-DD"),
    currentOrderId: null,
    showWorkOrder: false,
    completedOrder: false,
    orders: [],
    completedOrders: [],
    finalOrder: {},
    tempOrder: {},
    headers: [
        { text: "Dealer",  value: "dealerName", groupable: false, sortable: false },
        { text: "Model", value: "modelName", groupable: false, sortable: false},
        { text: "Series", value: "seriesName", groupable: false, sortable: false},
        { text: "Serial No",  value: "serialNumber", groupable: false },
        { text: "Production Start Date", value: "productionStartDate", groupable: false },
        { text: "Sales Order",  value: "options", groupable: false, sortable: false },
        { text: "Drilling",  value: "drilling", groupable: false, sortable: false },    
    ],
  }),

  mounted() {
    
    this.loading = true;   
    getProductionOrders().then((result) => {
      result.data.forEach(a => {
        a.completedPhases = this.getCompletedPhases(a);
        if (a.modelGroup !== 'S.W.I.M.' && !a.drilling)
          this.orders.push(a);
        else if (a.modelGroup !== 'S.W.I.M.' && a.drilling)
          this.completedOrders.push(a)
      })
      this.loading = false;
    });

  },

  methods: {
    updatePhase(order, phase) {
      let index = this.orders.findIndex(o => o.id === order.id);
      this.finalOrder = { orderId: order.id, phase: phase, complete: order[phase] };
      updatePhase(this.finalOrder).then((result) => {
        order[phase] = result.data;
        order.completedPhases = this.getCompletedPhases(order);
        if (order.completedPhases > 0 && order.status === 'Scheduled') {
          this.moveToProduction(order);
          console.log("Move to In Production!");
        }
        if (order.completedPhases >= 9) {
          this.tempOrder = order;
          this.completeOrder = true;
          console.log("Completed!");
        }
        if (order.completedPhases <= 0 && order.status === 'In Production') {
          this.moveToScheduled(order)
        }
        //this.orders.splice(index,1,order);
      })
    },

    updateStatus(order, status) {
      updateOrderStatus({ orderId: order.id, orderStatus: status }).then((result) => {
        order.status = status;
      })
    },

    moveToScheduled(order) {
      this.updateStatus(order, "Scheduled")
    },

    moveToProduction(order) {
      this.updateStatus(order, 'In Production');
    },

    moveToComplete(order) {
      this.updateStatus(order, 'Completed');
    },

    getCompletedPhases(order) {
      var value = 0;
      if(order.vacuum_form) {
        value ++;
      }
      if(order.fiberglass) {
        value ++;
      }
      if(order.drilling) {
        value ++;
      }
      if(order.frame) {
        value ++;
      }
      if(order.door) {
        value ++;
      }
      if(order.plumbing) {
        value ++;
      }
      if(order.test) {
        value ++;
      }
      if(order.packaging) {
        value ++;
      }
      if(order.closing) {
        value ++;
      }
      return value;
    },
    print() {
      window.print()
    }
  },

  computed: {
    userTypeId() { return this.$store.getters.userTypeId },
    
    filteredOrders: function() {
        if (this.filter && this.filter !== "All" && !this.completedOrder)
        return this.orders.filter(order => order.dealerName === this.filter)
      else if ((!this.filter || this.filter === "All") && this.completedOrder)
        return this.completedOrders
      else if ((this.filter && this.filter !== "All") && this.completedOrder)
        return this.completedOrders.filter(order => order.dealerName === this.filter)
      else
        return this.orders
    },
    dealers() {
      var arrayToCount = this.completedOrder ? this.completedOrders : this.orders;
      var dlrs = [];
      arrayToCount.forEach(o => {
        let df = dlrs.find(d => d.name === o.dealerName);
         if (df) {
           df.count ++;
         } else {
           dlrs.push({ name: o.dealerName, count: 1 });
         }
      })

      dlrs.sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
      })

      dlrs.unshift({ name: "All", count: arrayToCount.length });

      return dlrs;
    }

  },

  components: {
    SalesOrder,
  }
}
</script>

<style scoped>
 
</style>

<template>
  <v-row
      justify="center"
  >
    <v-col
      cols="12"
      xs="12"
      sm="5"
      md="4"
      lg="3"
    >
      <v-container class="pt-16 d-flex justify-center">
        <v-img
          src="../assets/leaf.jpg"
          contain
        >
        <v-form @submit.prevent="login">
          <v-container class="d-flex justify-center my-4">
            <v-card elevation="0" color="rgb(255, 255, 255, 0.9)">
              <v-card-title class="text-center secondary--text font-weight-bold text-h3 pb-2"><v-container>Sign In</v-container></v-card-title>
              <v-text-field
                filled
                class="mx-6 mt-6"
                v-model="creds.email"
                :rules="[rules.required]"
                name="input-10-1"
                label="Email"
              ></v-text-field>
              <v-text-field
                filled
                class="mx-6"
                v-model="creds.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                @click:append="show1 = !show1"
              ></v-text-field>
              <v-alert
                v-if="loginFail"
                dense
                type="error"
              >
                Email or Password is Incorrect!
              </v-alert>
              <v-card-actions
                class="mx-4"
              >
                <v-btn

                  block
                  elevation="0"
                  class="secondary mb-4"
                  type="submit"
                  :loading="logginIn"
                >
                Sign In
                </v-btn>
              </v-card-actions>
              <v-card-subtitle class="text-right pt-0 pr-6"><a href="#" @click.prevent="$router.push('/forgot-password')">Forgot Password?</a></v-card-subtitle>
            </v-card>
          </v-container>
        </v-form>
        </v-img>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {} from "@/services";

export default {
  name: "Login",

  data: () => ({
    show1: false,
    loginFail: false,
    logginIn: false,
    creds: {
        email: null,
        password: null,
    },
    
    rules: {
        required: value => !!value || 'Required.',
    }
  }),

  methods: {
    login() {
      this.logginIn = true;
      this.$store.dispatch('login', this.creds)
      .then(() => {
        this.logginIn=false;
        this.$router.push('/');
      })
      .catch(err => { if(err) {
        this.logginIn=false;
        this.loginFail=true;
        }
      }) 
  }
  }

};
</script>
<template>
  <v-card>
     <v-banner
    elevation="5"
    class="text-center font-weight-bold">
    <span class="text-h4 font weight-bold"> VS Options Breakdown </span> <br/>
    Breakdown of Main Upgrades on VS Series Tubs 
    </v-banner>
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-2">
            
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">VS Acrylic Color Breakdown</v-card-title>    
              <GChart 
              type="BarChart"
                :data="vsAcrylicOptionData"
                :options="vsOptionsOptionsBar"    
              >
              </Gchart>
            </v-card>

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">VS Top Side Controls </v-card-title>    
              <GChart 
              type="PieChart"
                :data="vsTSCOptionData"
                :options="vsOptionsOptionsB"    
              >
              </Gchart>
            </v-card>
            
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">VS in.Touch 2 Upgrade</v-card-title>    
              <GChart     
                type="PieChart"
                :data="vsIntOptionData"
                :options="vsOptionsOptionsB"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row> 
    <v-row>
            <v-col>
              <v-card elevation="0" class="d-flex justify-center pt-0 mt-n4" >
           <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">VS LED</v-card-title>    
              <GChart 
              type="PieChart"
                :data="vsLEDOptionData"
                :options="vsOptionsOptions"
              >
              </Gchart>
            </v-card>

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">VS Cabinet Lighting</v-card-title>    
              <GChart 
              type="PieChart"
                :data="vsCabLightOptionData"
                :options="vsOptionsOptions" 
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">VS Stereo</v-card-title>    
              <GChart     
                type="PieChart"
                :data="vsStereoOptionData"
                :options="vsOptionsOptions"
              >
              </Gchart>
            </v-card>
          </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-0 mt-n4">
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">VS Insulation Upgrade</v-card-title>    
              <GChart     
                 type="PieChart"
                :data="vsInsOptionData"
                :options="vsOptionsOptions"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">VS Sanitation Upgrade</v-card-title>    
              <GChart     
                type="PieChart"
                :data="vsSanOptionData"
                :options="vsOptionsOptions"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">VS Circ Pump</v-card-title>    
              <GChart     
                 type="PieChart"
                :data="vsCircOptionData"
                :options="vsOptionsOptionsB"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row>

    <v-bottom-navigation
  color="primary"
  >
  <v-btn to="analyticsoptions">
      <span>Open Options</span>
      <v-icon>mdi-table</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>  
    
  <v-btn to="/analyticsoo">
      <span>Open Hot Tubs</span>
      <v-icon>mdi-chart-pie</v-icon>
    </v-btn>
 
    <v-btn to="analyticsswimoo">
      <span>Open SWIM Spas</span>
      <v-icon>mdi-chart-pie-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analyticses">
      <span>Extreme</span>
      <v-icon>mdi-alpha-e-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsgs">
      <span>Great</span>
      <v-icon>mdi-alpha-g-circle-outline</v-icon>
    </v-btn>

    <v-btn to="analyticsvs">
      <span>Value</span>
      <v-icon>mdi-alpha-v-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsholiday">
      <span>Holiday</span>
      <v-icon>mdi-alpha-h-circle-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analytics">
      <span> Hot Tubs</span>
      <v-icon>mdi-chart-box</v-icon>
    </v-btn>

    <v-btn to="analyticsswim">
      <span>SWIM Spas</span>
      <v-icon>mdi-chart-box-outline</v-icon>
    </v-btn>

  </v-bottom-navigation>

  </v-card>
</template>

<script>
import {  getVSOptionCount } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "AnalyticsVS",

  data: () => ({
    vsCabLightOptionData: [
      ['Option', 'Count']
    ],
    vsTSCOptionData: [
      ['Option', 'Count']
    ],
    vsAcrylicOptionData: [
      ['Option', 'Count']
    ],
    vsCircOptionData: [
      ['Option', 'Count']
    ],
    vsIntOptionData: [
      ['Option', 'Count']
    ],
    vsLEDOptionData: [
      ['Option', 'Count']
    ],
    vsInsOptionData: [
      ['Option', 'Count']
    ],
    vsStereoOptionData: [
      ['Option', 'Count']
    ],
    vsSanOptionData: [
      ['Option', 'Count']
    ],
    vsOptionsOptions: {
     
      width: 500,
      height: 300,
      legend: { position: 'right', alignment: 'center'},
      titlePosition: 'none',
      sliceVisibilityThreshold: .00
    },
    vsOptionsOptionsB: {
     
     width: 500,
     height: 300,
     legend: { position: 'top', alignment: 'center'},
     titlePosition: 'none',
     sliceVisibilityThreshold: .00
   },
   vsOptionsOptionsBar: {
     
     width: 500,
     height: 300,
     legend: { position: 'none'},
     titlePosition: 'none',
     
   },
  }),

  mounted() {
    getVSOptionCount().then((result) => {
      result.data.forEach(o => {
        if (o.optionTypeName === "Cabinet Lighting") {
          this.vsCabLightOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Insulation") {
          this.vsInsOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Top Side Controls") {
          this.vsTSCOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Acrylic Color") {
          this.vsAcrylicOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "24hr. Circulation Pump") {
          this.vsCircOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "in.touch 2 App") {
          this.vsIntOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "LED Lighting") {
          this.vsLEDOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Stereo") {
          this.vsStereoOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Sanitation") {
          this.vsSanOptionData.push([o.optionName, o.optionCount])
        }
    
      })
    })
  },
  computed: {
    
  },

  components: {
    GChart
  },

}
</script>

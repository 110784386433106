<template>
  <v-card>
     <v-banner
    elevation="5"
    class="text-center font-weight-bold">
    <span class="text-h4 font weight-bold"> Aspel Historical Data </span> <br/>
    Breakdown of Model, Series, Acrylic Color, and Cabinent Color for All Aspel UK Hot Tubs Excluding SWIM Spas
    </v-banner>
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-4">

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Model Popularity</v-card-title>    
              <GChart     
                type="BarChart"
                :data="modelsData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Series Popularity</v-card-title>    
              <GChart     
                type="PieChart"
                :data="seriesData"
                :options="chartOptionsPie"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row> <v-row>
            <v-col>
              <v-card elevation="0" class="d-flex justify-center pt-4" >
           <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Arcylic Color</v-card-title>    
              <GChart     
                type="BarChart"
                :data="acrylicData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Cabinet Color</v-card-title>    
              <GChart     
                type="BarChart"
                :data="cabinetData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
          </v-card>
        </v-col>
    </v-row>

    <v-bottom-navigation
  color="primary"
  >
  <v-btn to="analyticsoptions">
      <span>Open Options</span>
      <v-icon>mdi-table</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>  
    
  <v-btn to="/analyticsoo">
      <span>Open Hot Tub</span>
      <v-icon>mdi-chart-pie</v-icon>
    </v-btn>
 
    <v-btn to="analyticsswimoo">
      <span>Open SWIM Spas</span>
      <v-icon>mdi-chart-pie-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analyticses">
      <span>Extreme</span>
      <v-icon>mdi-alpha-e-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsgs">
      <span>Great</span>
      <v-icon>mdi-alpha-g-circle-outline</v-icon>
    </v-btn>

    <v-btn to="analyticsvs">
      <span>Value</span>
      <v-icon>mdi-alpha-v-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsholiday">
      <span>Holiday</span>
      <v-icon>mdi-alpha-h-circle-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analytics">
      <span> Hot Tubs</span>
      <v-icon>mdi-chart-box</v-icon>
    </v-btn>

    <v-btn to="analyticsswim">
      <span>SWIM Spas</span>
      <v-icon>mdi-chart-box-outline</v-icon>
    </v-btn>

  </v-bottom-navigation>

  </v-card>
</template>

<script>
import { getAspelOrdersIntervals, getAspelModelCount, getAspelSeriesCount, getAspelAcrylicOptionCount, getAspelCabOptionCount } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "AnalyticsAspel",

  data: () => ({
    modelsData: [
      ['Model', 'Sales', {role: 'annotation'}],
    ],
    seriesData: [
      ['Series', 'Sales'],
    ],
    acrylicData: [
      ['Option', 'Count']
    ],
    cabinetData: [
      ['Option', 'Count']
    ],
    orderCount: [],
    chartOptionsBar: {
      width: 700,
      height: 300,
      legend: { position: 'none'},
      titlePosition: 'none',
    },
    chartOptionsPie: {
      width: 700,
      height: 300,
      legend: { position: 'top', alignment: 'center'},
      titlePosition: 'none',
    },
  }),

  mounted() {
    getAspelModelCount().then((result) => {
      result.data.forEach((m) => this.modelsData.push([ m.modelName, m.modelCount, m.modelGroup ]));
    })
    getAspelSeriesCount().then((result) => {
      result.data.forEach((s) => this.seriesData.push([ s.seriesName, s.seriesCount ]));
    })
    getAspelAcrylicOptionCount().then((result) => {
      result.data.forEach((o) => this.acrylicData.push([ o.optionName, o.optionCount])); 
    })
    getAspelCabOptionCount().then((result) => {
      result.data.forEach((o) => this.cabinetData.push([ o.optionName, o.optionCount])); 
    })
     getAspelOrdersIntervals().then((result) => {
      this.orderCount = result.data;
    })
  },
  computed: {
    
  },

  components: {
    GChart
  },

}
</script>

<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-img
            src="../assets/aspenLogo.png"
            contain
            max-height="200"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        
          <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 17">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-foreman">
            Jefe Production Tracker
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 6">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-vacuum">
            Vacuum Former Production Tracker
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 7">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-fiberglass">
            Fiberglass Production Tracker
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 8">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-drilling">
            Drilling Production Tracker
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 9">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-frames">
            Frames Production Tracker
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 10">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-door">
            Cabinets Production Tracker
          </v-btn>
        </v-col>
         <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 16">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-preplumbed">
            Pre-Plumbing Production Tracker
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 11">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-plumbed">
            Plumbing Production Tracker
          </v-btn>
        </v-col>
        
        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 12">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-test">
            Testing Production Tracker
          </v-btn>
        </v-col>
          <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 13">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-packaged">
            Packaging Production Tracker
          </v-btn>
        </v-col>
          <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 14">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-swim1">
            Swim Spa Phase 1 Production Tracker
          </v-btn>
        </v-col>
         <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 15">
          <v-btn color="primary" outlined rounded block to="/production-status-employee-swim2">
            Swim Spa Phase 2 Production Tracker
          </v-btn>
        </v-col>

       
      </v-row>
    </v-container>
  </div>

</template>

<script>
import { getOrders } from "@/services";

export default {
  name: "EmpHome",

  data: () => ({
    approvedOrders: []
  }),

  mounted() {
    getOrders().then((result) => {
      result.data.forEach(a => { if(a.status === "Pending") {
        this.approvedOrders.push(a);
      }
      })
    })
  },

  computed : {
    isLoggedIn() { return this.$store.getters.isLoggedIn },
    currentUser() { return this.$store.state.user },
    userTypeId() { return this.$store.getters.userTypeId }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      })
    }
  },
  components: {},
};
</script>

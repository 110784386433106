<template>
  <div>
    <v-container v-if="catalog">
      <v-row class="mb-1">
        <v-col>
          <h2>{{ catalog.name }}</h2>
        </v-col>
      </v-row>
      <v-expansion-panels>
        <v-expansion-panel class="my-1" v-for="model in catalog.models" :key="'model-'+model.modelId">
          <v-expansion-panel-header class="text-h6" color="blue-grey lighten-1">
            {{ model.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>

            <v-expansion-panels  v-if="model.series">
               <v-expansion-panel class="my-1" v-for="series in model.series" :key="'series-'+model.modelId+'-'+series.seriesId">
                  <v-expansion-panel-header class="text-subtitle-2" color="blue-grey lighten-3">
                     {{series.name}}<v-spacer></v-spacer>${{ series.price }}
                  </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <v-expansion-panels  v-if="series.optionTypes">
               <v-expansion-panel class="my-1" v-for="optionType in series.optionTypes" :key="'catoptyp-'+model.modelId+'-'+series.seriesId+'-'+optionType.catalogOptionTypeId">
                  <v-expansion-panel-header class="text-subtitle-2" color="blue-grey lighten-5">
                    {{ optionType.name }}
                  </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <v-expansion-panels  v-if="optionType.options">
               <v-expansion-panel class="my-1" v-for="option in optionType.options" :key="'catop-'+model.modelId+'-'+series.seriesId+'-'+option.catalogOptionTypeId+'-'+option.optionId">
                  <v-expansion-panel-header class="text-subtitle-2" expand-icon="mdi-none" disabled>
                    {{ option.name }}<span class="font-weight-black pl-2" v-if="option.standard===1">(Standard)</span><v-spacer></v-spacer>${{ option.price }}
                  </v-expansion-panel-header>
                </v-expansion-panel>
                    <v-btn class="mt-3" @click="optionToAdd={};optionToAdd.catalogOptionTypeId=optionType.catalogOptionTypeId;selectedOptionTypeId=optionType.optionTypeId;optionDialog=true">Add Option to {{ model.name }}/{{ series.name }}/{{ optionType.name }}</v-btn>      
              </v-expansion-panels>

                </v-expansion-panel-content>
                </v-expansion-panel>
                <v-btn class="mt-3" @click="optionTypeToAdd.modelId=model.modelId;optionTypeToAdd.seriesId=series.seriesId;optionTypeToAdd.optionTypeId=null;optionTypeDialog=true">Add Option Type to {{ model.name }}/{{ series.name }}</v-btn>
              </v-expansion-panels>
            
                </v-expansion-panel-content>
                </v-expansion-panel>
                <v-btn class="mt-3" @click="modelToAdd=model.modelId;seriesToAdd={};seriesDialog=true">Add Series to {{ model.name }}</v-btn>
              </v-expansion-panels>

          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-btn class="mt-3" @click="modelToAdd=null;modelDialog=true">Add Model to Catalog</v-btn>
      </v-expansion-panels>
     
    </v-container>
    <v-dialog v-model="modelDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>Model</v-card-title>
        <v-card-text>
          <v-select label="Select Model" v-model="modelToAdd" :items="models" item-text="name" item-value="id">
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="modelDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addModelToCatalog();modelDialog = false"
          >
            Add
          </v-btn>
        </v-card-actions>      
      </v-card>
    </v-dialog>
    <!-- Series Dialog -->
    <v-dialog v-model="seriesDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>Series</v-card-title>
        <v-card-text>
          <v-select label="Select Series" v-model="seriesToAdd.seriesId" :items="series" item-text="name" item-value="id">
          </v-select>
          <v-text-field v-model="seriesToAdd.price" label="Price"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="seriesDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addSeriesToModel();seriesDialog = false"
          >
            Add
          </v-btn>
        </v-card-actions>      
      </v-card>
    </v-dialog>
    <!-- Option Type Dialog -->
    <v-dialog v-model="optionTypeDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>Option Type</v-card-title>
        <v-card-text>
          <v-select label="Select Option Type" v-model="optionTypeToAdd.optionTypeId" :items="optionTypes" item-text="name" item-value="id">
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="optionTypeDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addOptionTypeToSeries();optionTypeDialog = false"
          >
            Add
          </v-btn>
        </v-card-actions>      
      </v-card>
    </v-dialog>

    <!-- Option Dialog -->
    <v-dialog v-model="optionDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>Option</v-card-title>
        <v-card-text>
          <v-select label="Select Option" v-model="optionToAdd.optionId" :items="options.filter(o => o.optionTypeId===selectedOptionTypeId)" item-text="name" item-value="id">
          </v-select>
          <v-text-field v-model="optionToAdd.price" label="Price"></v-text-field>
          <v-checkbox v-model="optionToAdd.standard" label="Standard"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="optionDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addOptionToOptionType();optionDialog = false"
          >
            Add
          </v-btn>
        </v-card-actions>      
      </v-card>
    </v-dialog>
    <v-overlay :value="loadingCatalog">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { getCatalog, getModels, getSeries, getOptionTypes, getOptions, createCatalogModel, createCatalogSeries, createCatalogOptionType, createCatalogOption } from "@/services";

export default {
  name: "CatalogEditor",

  data: () => ({
    loadingCatalog: false,
    catalog: {},
    selectedModelIndex: null,
    selectedSeriesIndex: null,
    selectedOptionTypeId: null,
    selectedOptionIndex: null,
    models: [],
    series: [],
    optionTypes: [],
    options: [],
    modelDialog: false,
    seriesDialog: false,
    optionTypeDialog: false,
    optionDialog: false,
    modelToAdd: null,
    seriesToAdd: { seriesId: null, price: null },
    optionTypeToAdd: {},
    optionToAdd: {},
  }),

  props: ['catalogId'],

  mounted() {
    this.$set(this.catalog, 'models', []);

    this.refreshCatalog();

    getModels().then(result => {
      this.models = result.data;
    });

    getSeries().then(result => {
      this.series = result.data;
    });

    getOptionTypes().then(result => {
      this.optionTypes = result.data;
    });

    getOptions().then(result => {
      this.options = result.data;
    });


  },

  computed: {
  },

  methods: {
    refreshCatalog() {
      this.loadingCatalog = true;
      getCatalog(this.catalogId).then((result) => {
        this.catalog = result.data;
        this.loadingCatalog = false;
      });

    },
    addModelToCatalog() {
      createCatalogModel({ catalogId: this.catalogId, modelId: this.modelToAdd}).then((result) => {
        this.refreshCatalog();
      });
    },
    addSeriesToModel() {
      if (this.seriesToAdd.seriesId) {
        createCatalogSeries({ catalogId: this.catalogId, modelId: this.modelToAdd, seriesId: this.seriesToAdd.seriesId, price: this.seriesToAdd.price}).then((result) => {
          this.refreshCatalog();
        });
      }
    },
    addOptionTypeToSeries() {
      createCatalogOptionType({ catalogId: this.catalogId, modelId: this.optionTypeToAdd.modelId, seriesId: this.optionTypeToAdd.seriesId, optionTypeId: this.optionTypeToAdd.optionTypeId}).then((result) => {
        this.refreshCatalog();
      });
    },
    addOptionToOptionType() {
      createCatalogOption({ catalogId: this.catalogId, catalogOptionTypeId: this.optionToAdd.catalogOptionTypeId, optionId: this.optionToAdd.optionId, price: this.optionToAdd.price, standard: this.optionToAdd.standard }).then((result) => {
        this.refreshCatalog();
      });
    }
  }
};
</script>

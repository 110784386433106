<template>
  <v-container class="mt-16">
    <v-row justify="center">
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="5"
        xl="3"
      >
        <v-card v-if="user.id" class="mt-16 px-6 rounded-lg" elevation="4">
          <v-row>
            <v-img
              src="../assets/aspenLogo.png"
              contain
            ></v-img>
          </v-row>
          <v-row>
            <v-container class="d-flex justify-center">
              <v-card elevation="0">
                <v-card-title class="text-center primary--text font-weight-bold text-h5">
                  <v-container>
                    Reset Password
                  </v-container>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2 pt-3"
                    :rules="[rules.required, rules.validPass]"
                    v-model="user.password"
                    label="New Password"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1=!show1"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="rePassword"
                    :rules="[rules.required, rules.passMatch, passwordConfirmationRule]"
                    label="Confirm Password"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show2=!show2"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn block elevation="0" class="secondary mb-6 pt-0" @click="changePassword">Confirm</v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-row>
        </v-card>
        <v-container v-else class="pt-16 d-flex justify-center">
          <v-row>
            <v-col>
              <v-img
                src="../assets/aspenLogo.png"
                contain
                max-height="300"
              ></v-img>
            </v-col>
            <v-col>
              <v-alert class="d-flex justify-center"
                type="error"
                text
                max-height="300"
              >
                Invalid Address For Password Reset
              </v-alert>
              <v-btn color="primary" :to="'/login'" block text>Return To Login</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getUser, editPassword } from "@/services";

export default {
  name: "PasswordReset",

  data: () => ({
    show1: false,
    show2: false,
    password: 'Password',    

    rePassword: '',

    user: {
      password: '',
      id: null,
    },

    rules: {
      required: (v) => !!v || "Required",
      validPass: (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
    },

  }),

  props: ['resetToken'],

  mounted() {
    getUser(this.resetToken).then((result) => {
      this.user.id = result.data[0].id;
    });
  },

  computed: {
    passwordConfirmationRule() {
      return (this.rePassword === this.user.password || 'Passwords Must Match')
    },
  },

  methods: {
    changePassword() {
      editPassword(this.user).then(this.$router.push('/login'));
    },
  }
}

</script>
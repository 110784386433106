<template>
  <v-card flat>
    <v-toolbar flat class="my-2">
      <v-toolbar-title class="text-h4 primary--text font-weight-bold">Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch
      class="mt-8"
      @click="updateCards(), swimSpaState()"
      v-model="swimProp"
      inset
      label="SWIM Spas"
      >
      </v-switch>
    </v-toolbar>
    
    <v-container class="mb-4">
      <v-row>
        <v-col>
          <v-card outlined hover @click="toApproveOrders" class="rounded-lg">
            <v-card-title class="d-flex justify-center primary--text">Approve Orders</v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-icon x-large>mdi-check</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined hover @click="toScheduling" class="rounded-lg">
            <v-card-title class="d-flex justify-center primary--text">Scheduling Orders</v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-icon x-large>mdi-calendar-end</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined hover @click="toAssignProdDate" class="rounded-lg">
            <v-card-title class="d-flex justify-center primary--text">Assign Production Start Date</v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-icon x-large>mdi-calendar</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined hover @click="toProductionSchedule" class="rounded-lg">
            <v-card-title class="d-flex justify-center primary--text">Production Schedule</v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-icon x-large>mdi-calendar-week</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined hover @click="toStatusList" class="rounded-lg">
            <v-card-title class="d-flex justify-center primary--text">Production Tracker & Reports</v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-icon x-large>mdi-progress-check</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        
        <v-col>
           <v-card outlined hover @click="toClaimedOrderList" class="rounded-lg">
            <v-card-title class="d-flex justify-center primary--text"> Ship Claimed Tubs</v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-icon x-large>mdi-send</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
           <v-card outlined hover @click="toOrderList" class="rounded-lg">
            <v-card-title class="d-flex justify-center primary--text"> View / Edit All Orders</v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-icon x-large>mdi-sticker-text-outline</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
           <v-card outlined hover to="/analytics-home" class="rounded-lg">
            <v-card-title class="d-flex justify-center primary--text"> View Analytics</v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-icon x-large>mdi-poll</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-card class="mx-16" flat>
            <v-card-title class="text-h5 font-weight-bold justify-center primary--text">Order Status</v-card-title>
            <v-row>
              <v-col v-for="item in status" :key="item.orderStatus">
                <v-card class="ma-6" :color="getColor(item)">
                  <v-card-title class="text-h5 font-weight-bold justify-center secondary--text">{{ item.orderStatus }}</v-card-title>
                  <v-card-text class="text-h3 d-flex justify-center font-weight-black secondary--text">
                    {{ item.statusCount }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
           
          </v-card>
  </v-card>      
</template>

<script>
import { getStatusCountNoSwimSpas, getStatusCountSwimSpas, getDateCountNoSwimSpas, getDateCountSwimSpas } from '@/services';
import { GChart } from 'vue-google-charts';
export default {
  name: "Dashboard",
    
  data: () => ({
    moment: require('moment'),
    swimProp: false,
    status: [
      { orderStatus: "Pending", statusCount: 0 },
      { orderStatus: "Approved", statusCount: 0 },
      { orderStatus: "Scheduled", statusCount: 0},
      { orderStatus: "In Production", statusCount: 0 },
      { orderStatus: "Claimed", statusCount: 0 },
      { orderStatus: "Completed", statusCount: 0 },
    ],  
    ordersSwimData: [
      ['Date', 'GS', 'ES'],
    ],
     ordersData: [
      ['Date', 'Pinn', 'Arc', 'Alp', 'Mon', 'Eld', 'Pio', 'Quat', 'Nor', 'Col'],
    ],
  
    chartOptions: { 
        hAxis: {
          showTextEvery: 3,
          title: 'yyyy-mm'
        },
        isStacked: true,
        
    },
  
  }),

  mounted() {
    this.swimProp = this.$store.state.swimSpa
    if (!this.swimProp) {
      getStatusCountNoSwimSpas().then((result) => {
        result.data.forEach(status => {
          if(status.orderStatus === "Pending") {
            this.status[0].statusCount = status.statusCount
          }
          if(status.orderStatus === "Approved") {
            this.status[1].statusCount = status.statusCount
          }
          if(status.orderStatus === "Scheduled") {
            this.status[2].statusCount = status.statusCount
          }
          if(status.orderStatus === "In Production") {
            this.status[3].statusCount = status.statusCount
          }
            if(status.orderStatus === "Claimed") {
            this.status[4].statusCount = status.statusCount
          }
          if(status.orderStatus === "Completed") {
            this.status[5].statusCount = status.statusCount
          }
        })
      })
      getDateCountNoSwimSpas().then((result) => {
        result.data.forEach((o) => this.ordersData.push([o.dates, o.pinnOrders, o.arcOrders, o.alpOrders, o.monOrders, o.eldOrders, o.pioOrders, o.quatOrders, o.norOrders, o.colOrders ]))
      })
      getDateCountSwimSpas().then((result) => {
        result.data.forEach((o) => this.ordersSwimData.push([o.dates, o.gsOrders, o.esOrders]))
    })
    }
    else {
      getStatusCountSwimSpas().then((result) => {
        result.data.forEach(status => {
          if(status.orderStatus === "Pending") {
            this.status[0].statusCount = status.statusCount
          }
          if(status.orderStatus === "Approved") {
            this.status[1].statusCount = status.statusCount
          }
          if(status.orderStatus === "Scheduled") {
            this.status[2].statusCount = status.statusCount
          }
          if(status.orderStatus === "In Production") {
            this.status[3].statusCount = status.statusCount
          }
          if(status.orderStatus === "Claimed") {
            this.status[4].statusCount = status.statusCount
          }
          if(status.orderStatus === "Completed") {
            this.status[5].statusCount = status.statusCount
          }
        })
      })
     getDateCountNoSwimSpas().then((result) => {
        result.data.forEach((o) => this.ordersData.push([o.dates, o.pinnOrders, o.arcOrders, o.alpOrders, o.monOrders, o.eldOrders, o.pioOrders, o.quatOrders, o.norOrders, o.colOrders ]))
      })
      getDateCountSwimSpas().then((result) => {
        result.data.forEach((o) => this.ordersSwimData.push([o.dates, o.gsOrders, o.esOrders]))
    })
    }
  },

  methods: {
    toApproveOrders() {
      this.$router.push("/approve-orders/"+this.swimProp);
    },
    toScheduling() {
      this.$router.push("/scheduling/"+this.swimProp);
    },
    toAssignProdDate() {
      this.$router.push("/assign-prod-date/"+this.swimProp);
    },
      toClaimedOrderList() {
      this.$router.push("/order-list-claimed/"+this.swimProp);
    },
    toStatusList() {
      this.$router.push("/production-status/"+this.swimProp);
    },
     toStatusReportList() {
      this.$router.push("/production-status-report/"+this.swimProp);
    },
     toOrderList() {
      this.$router.push("/order-list-aspen-emp/");
    },       
    toProductionSchedule() {
      this.$router.push("/production-schedule");
    },
    getColor(item) {
      if(item.orderStatus === "Approved") {
        return "orange lighten-4"
      }
      if(item.orderStatus === "Scheduled") {
        return "blue lighten-4"
      }
      if(item.orderStatus === "In Production") {
        return "yellow lighten-4"
      }
      if(item.orderStatus === "Completed") {
        return "green lighten-4"
      } else {
        return "red lighten-4"
      }
    },
    updateCards() {
      this.status = [
        { orderStatus: "Pending", statusCount: 0 },
        { orderStatus: "Approved", statusCount: 0 },
        { orderStatus: "Scheduled", statusCount: 0},
        { orderStatus: "In Production", statusCount: 0 },
        { orderStatus: "Claimed", statusCount: 0 },
        { orderStatus: "Completed", statusCount: 0 },
      ];
      if (this.swimProp) {
        getStatusCountSwimSpas().then((result) => {
          result.data.forEach(status => {
            if(status.orderStatus === "Pending") {
              this.status[0].statusCount = status.statusCount
            }
            if(status.orderStatus === "Approved") {
              this.status[1].statusCount = status.statusCount
            }
            if(status.orderStatus === "Scheduled") {
              this.status[2].statusCount = status.statusCount
            }
            if(status.orderStatus === "In Production") {
              this.status[3].statusCount = status.statusCount
            }
            if(status.orderStatus === "Claimed") {
              this.status[4].statusCount = status.statusCount
          }
            if(status.orderStatus === "Completed") {
              this.status[5].statusCount = status.statusCount
          }
          })
        })
      } else {
        getStatusCountNoSwimSpas().then((result) => {
          result.data.forEach(status => {
            if(status.orderStatus === "Pending") {
              this.status[0].statusCount = status.statusCount
            }
            if(status.orderStatus === "Approved") {
              this.status[1].statusCount = status.statusCount
            }
            if(status.orderStatus === "Scheduled") {
              this.status[2].statusCount = status.statusCount
            }
            if(status.orderStatus === "In Production") {
              this.status[3].statusCount = status.statusCount
            }
            if(status.orderStatus === "Claimed") {
              this.status[4].statusCount = status.statusCount
            }
            if(status.orderStatus === "Completed") {
              this.status[5].statusCount = status.statusCount
          }
          })
        })
      }
    },
    swimSpaState() {
      this.$store.state.swimSpa = !this.$store.state.swimSpa
      this.swimProp = this.$store.state.swimSpa
    }
  },

  computed: {
   
  },
   components: {
    // GChart
  },
};

</script>

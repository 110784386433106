<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-img
            src="../assets/aspenLogo.png"
            contain
            max-height="200"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if=" (updMsg.length > 1 && (userTypeId === 1 || userTypeId === 2 || userTypeId === 3 || userTypeId === 4) )">
          <v-card
          elevation="5"
          outlined>
          <v-card-title class="justify-center">
            Update for Dealers
            </v-card-title>
             <v-card-text  class="text-center font-weight-bold">
            {{updMsg}}
             </v-card-text>
           
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 2 || userTypeId === 3 || userTypeId === 4">
          <v-btn color="primary" outlined rounded block to="/order">
            Place New Order
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1">
          <v-btn color="primary" outlined rounded block to="/order-list-aspen-emp">
            View/Edit All Current Orders
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 2 || userTypeId === 3">
          <v-btn color="primary" outlined rounded block to="/order-list">
            View/Edit My Current Orders
          </v-btn>
        </v-col>

         <v-col cols="12" v-if="userTypeId === 4">
          <v-btn color="primary" outlined rounded block to="/rep-order-list">
            View My Dealers Current Orders
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 2 || userTypeId === 3">
          <v-btn color="primary" outlined rounded block to="/order-list-available">
            Claim Currently Available Spas
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1 || ((userTypeId === 2) && (this.retailCatalogEditorType === 'Standard' || this.retailCatalogEditorType === 'Full'))">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs}">
              <v-btn v-bind="attrs" v-on="on" color="primary" outlined rounded block to="/retailhome">
            Retail Orders Home
          </v-btn>
        </template>
            <span>Save Retail Draft Orders, View/Edit/Submit Retail Draft Orders, Edit Retail Catalog</span>
          </v-tooltip> 
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1 || userTypeId === 2 || userTypeId === 3 || userTypeId === 4">
          <v-btn color="primary" outlined rounded block to="/announcement-list-dealer">
            View Announcements
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1">
          <v-btn color="primary" outlined rounded block to="/analytics-home">
            View Analytics
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="userTypeId === 1">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs}">
              <v-btn v-bind="attrs" v-on="on" color="primary" outlined rounded block to="/editor-navigation">
            Manage Portal / System Configuration
          </v-btn>
            </template>
            <span>Mangae Announcements, Dealer, Users || Edit Catalogs, Models, Series, Options, Parts <br>
            New Dealer On Boarding Sales Tool || Employee Production Trackers</span>
          </v-tooltip> 
        </v-col>

         <v-col cols="12" v-if="userTypeId === 6 || userTypeId === 7 || userTypeId === 8 || userTypeId === 9 || userTypeId === 10 || userTypeId === 11 || userTypeId === 12 || userTypeId === 13 || userTypeId === 14 || userTypeId === 15 || userTypeId === 16 || userTypeId === 17">
          <v-btn color="primary" outlined rounded block to="/emphome">
            Employee Production Trackers
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="(userTypeId === 4 && this.currentUser.dealers.includes(167))">
          <v-btn color="primary" outlined rounded block to="/rep-onboard-tool">
            New Dealer On Boarding Sales Tool
          </v-btn>
        </v-col>

        <!-- <v-col cols="12" v-if="userTypeId === 1">
          <v-btn color="primary" outlined rounded block to="/part-order">
            Place New Part Order
          </v-btn>
        </v-col> -->
        
        <!-- <v-col cols="12" v-if="userTypeId === 1">
          <v-btn color="primary" outlined rounded block to="/part-order-list">
            Part Order List
          </v-btn>
        </v-col> -->

      </v-row>
    </v-container>
  </div>

</template>

<script>
import { getDealerx } from "@/services";

export default {
  name: "Home",

  data: () => ({
    //enter Update Mesage for Dealers by updating String asscoiated with updMsg variable, Leave Blank for Update Card to no be show on Home Page.
    updMsg: "",
    dealerId: null,
    retailCatalogComplete: null,
    retailCatalogEditorType: null,
  }),

  mounted() {
    if (this.userTypeId === 2 || this.userTypeId === 3) {
    this.dealerId = this.currentUser.dealers[0];
    getDealerx(this.dealerId).then((result) => {
      this.retailCatalogEditorType = result.data.retailCatalogEditorType;
      this.retailCatalogComplete = result.data.retailCatalogComplete;
    })
    
   }
    
  },

  computed : {
    isLoggedIn() { return this.$store.getters.isLoggedIn },
    currentUser() { return this.$store.state.user },
    userTypeId() { return this.$store.getters.userTypeId }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      })
    },
    reloadPage() {
      window.location.reload();
    }
  },
  components: {},
};
</script>

import Vue from "vue";
import Vuex from "vuex";
import { validateLogin } from "@/services";

Vue.use(Vuex);

const user = JSON.parse(localStorage.getItem('user'));

export default new Vuex.Store({
  state: {
    user: user || null,
    token: localStorage.getItem('token') || '',
    swimSpa: false
  },
  mutations: {
    loginSuccess(state, {token, user}) {
      state.token = token;
      state.user = user;
    },
    loginFailure(state) {
      state.user = null;
    },
    logout(state) {
      state.user = null;
      state.token = '';
    },    
  },
  actions: {
    login({ commit }, creds) {
      return validateLogin(creds).then(
        resp => {
          const token = resp.data.token;
          const user = resp.data.user;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user))
          commit('loginSuccess', {token, user});
          return Promise.resolve(resp);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      commit('logout');
    },  
  },
  getters : {
    isLoggedIn: state => !!state.token,
    userTypeId: state => state.user.userTypeId
  }
});

<template>
<div>
  <v-data-table
  height='500'
  fixed-header
    :headers="headers"
    :items="dealers"
    :search="search"
    :loading="loading"
    loading-text="Loading Dealers..."
    sort-by="name"
  >
    <template v-slot:top>
      <v-toolbar flat class="my-2">
        <v-toolbar-title class="text-h4 primary--text font-weight-bold">Dealers</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>          
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" width="75%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on">
                New Dealer
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              <span class="text-h5"> {{ formTitle }} </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-card-subtitle class="primary--text">Catalog</v-card-subtitle>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-select
                      v-model="editedDealer.catalogId"
                      label="Designated Catalog"
                      :items="catalogs"
                      item-value="catalogId"
                      item-text="name"
                    ></v-select>
                    
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-select
                      v-model="editedDealer.retailCatalogEditorType"
                      label="Retail Catalog Editor Type"
                      :items="retailCatalogEditorTypes"
                    ></v-select>
                    
                  </v-col>
                </v-row>
                <v-row>
                  <v-card-subtitle class="primary--text">Dealer Info</v-card-subtitle>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.name"
                      label="Dealer"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    v-model="editedDealer.num_stores"
                      label="# of Stores"
                      
                    ></v-text-field>
                  </v-col>
                   <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.website"
                      label="Website"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.showroom_aspenspas"
                      label="Aspen Spas on Showroom Floor"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.est_units_per_year"
                      label="Est Aspen Units Per Year"
                      
                    ></v-text-field>
                  </v-col>
                   <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.other_brands_carried"
                      label="Other Brands Carried"
                    ></v-text-field>
                  </v-col>
                </v-row>
           
           
                <v-row>
                  <v-card-subtitle class="primary--text">Address</v-card-subtitle>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.address1"
                      label="Address"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.address2"
                      label="Apt, Unit, Suite, etc. (optional)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.city"
                      label="City"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.stateProvince"
                      label="State/Province"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.postalCode"
                      label="Postal Code"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.country"
                      label="Country"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.phone"
                      label="Office Phone #"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedDealer.email"
                      label="Office Email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row><v-card-subtitle class="primary--text">Service</v-card-subtitle></v-row>
                
                     <v-row>
                    <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-select
                    v-model="editedDealer.deliver_setup"
                    label="Delivery/Set Up"
                    :items="delivers">

                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-select
                    v-model="editedDealer.service_provided"
                    label="Service Provided"
                    :items="services">

                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    v-model="editedDealer.phone_service_dept"
                    label="Service Dept Phone #">

                    </v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancel</v-btn>
              <v-btn color="primary" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-divider></v-divider>
    </template>
    <template v-slot:item.address1="{ item }">
      {{ item.address1 }}
      <v-menu
        min-width="300"
        offset-y
        open-on-hover
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon small color="secondary">mdi-more</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="primary--text">Full Address</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="secondary--text"><strong>Address:</strong> {{ item.address1 }}</v-card-text>
          <v-card-text class="secondary--text"><strong>Apt, Unit, Suite, etc. (optional):</strong> {{ item.address2 }}</v-card-text>
          <v-card-text class="secondary--text"><strong>City:</strong> {{ item.city }}</v-card-text>
          <v-card-text class="secondary--text"><strong>State/Province:</strong> {{ item.stateProvince }}</v-card-text>
          <v-card-text class="secondary--text"><strong>Postal Code:</strong> {{ item.postalCode }}</v-card-text>
          <v-card-text class="secondary--text"><strong>Country:</strong> {{ item.country }}</v-card-text>
        </v-card>
      </v-menu>
    </template>
    <template v-slot:item.actions="{ item, index }">  
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="editDealer(item, index)">  
            <v-icon class="mr-2" color="secondary"> mdi-pencil-outline </v-icon>
          </v-btn>
        </template>
        <span>Edit Dealer</span>
      </v-tooltip>
    </template>
     <template v-slot:item.profile="{ item }">
        <v-btn icon @click="currentDealerId=item.id; showDealerProfile=true">
          <v-icon color="secondary">mdi-arrow-top-right-thick</v-icon>
        </v-btn>
      </template>
  </v-data-table>
  <v-dialog fullscreen v-model="showDealerProfile">
    <v-toolbar
      class="d-print-none"
      flat
      color="secondary"
    >
      <v-btn @click="showDealerProfile=false">Close</v-btn>
    </v-toolbar>
    <AnalyticsDealer :dealerId="currentDealerId"></AnalyticsDealer>
  </v-dialog>
  </div>
</template>

<script>
import { getDealers, getCatalogs, editDealer, createDealer } from "@/services";
import AnalyticsDealer from "./AnalyticsDealer.vue";

export default {
  name: "DealerList",

  data: () => ({
    dialog: false,
    catalogs: [],
    dealers: [],
    search: '',
    loading: false,
    currentDealerId: null,
    showDealerProfile: false,

    editedIndex: -1,

    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Designated Catalog", value: "catalogName" },
      { text: "Retail Catalog Editor Type", value: "retailCatalogEditorType"},
      { text: "Address", value: "address1" },
      { text: "Office Email", value: "email" },
      { text: "Office Phone #", value: "phone" },
      { text: "URL", value: "website" },
      { text: "Analytics", value: "profile"},
      { text: "Actions", value: "actions", sortable: false },
    ],

    retailCatalogEditorTypes: [
      {text: "Unavailable (No Access)", value: "Unavailable"},
      {text: "Standard (No Hide)", value: "Standard"},
      {text: "Full (Hide Avail)", value: "Full"}],

    delivers: [
      {text: "Delivery and Full Set Up", value: "Delivery and Full Set Up"},
      {text: "Delivery and Set Up (Except Electric)", value: "Delivery and Set Up (Except Electric)"},
      {text: "Delivery but Do Not Set Up", value: "Delivery but Do Not Set Up"},
      {text: "Do Not Deliver or Set Up", value: "Do Not Deliver or Set Up"}

    ],
    services: [
      {text: "Yes", value: "Yes"},
      {text: "No", value: "No"},
      {text: "Via 3rd Party", value: "Via 3rd Party"}

    ],

    editedDealer: {
      id: null,
      name: "",
      website: "",
      catalogId: null,
      catalogName: "",
      address1: "",
      address2: "",
      city: "",
      stateProvince: "",
      postalCode: "",
      country: "",
      Phone: "",
      email: "",
      retailCatalogEditorType: "",
    },

    defaultDealer: {
      id: null,
      name: "",
      website: "",
      catalogId: null,
      catalogName: "",
      address1: "",
      address2: "",
      city: "",
      stateProvince: "",
      postalCode: "",
      country: "",
      Phone: "",
      email: "",
      retailCatalogEditorType: "",
    },    
  }),

  mounted() {
    this.loading = true;
    getDealers().then((result) => {
      this.dealers = result.data;
      this.loading = false;
    });
    getCatalogs().then((result) => {
      this.catalogs = result.data;
    })
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Dealer" : "Edit Dealer"
    },
  },

  methods: {
    editDealer(dealer, index) {
      this.editedIndex = index;
      this.editedDealer = Object.assign({}, dealer);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedDealer = Object.assign({}, this.defaultDealer);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        editDealer(this.editedDealer).then((result) => {
          this.dealers.splice(this.editedIndex,1,result.data);
          this.close();
        });
      } else {
        createDealer(this.editedDealer).then((result) => {
          console.log(result);
          this.dealers.push(result.data);
          this.close();
        });
      }
    }
  },
  components: {
    AnalyticsDealer,
  }
};

</script>
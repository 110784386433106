<template>
  <v-card>
     <v-banner
    elevation="5"
    class="text-center font-weight-bold">
    <span class="text-h4 font weight-bold"> Historical SWIM Spa Breakdown </span> <br/>
    Breakdown of SWIM Spa Series, and Cabinet Color for All Domestic and Aspel UK 
    </v-banner>

     <v-row class="mt-4" justify="center">
           <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
            <v-card-title>
             <span> Swims Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 3 Months </span></span>  
            </v-card-title>
            <span class="text-h5 font-weight-bold">{{this.orderCount.threeMonthOrders}}</span>
          </v-card>
              
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                   <span> Swims Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 6 Months </span></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.sixMonthOrders}}</span>
              </v-card>
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                 <span> Swims Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 1 Year </span></span>
                </v-card-title>
                 <span class="text-h5 font-weight-bold">{{this.orderCount.oneYearOrders}}</span>
              </v-card>
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                  <span> Swims Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 2 Years </span></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.twoYearOrders}}</span>
              </v-card>
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                  <span> All Swims Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.allOrders}}</span>
              </v-card>
        </v-row>
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-2">

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Domestic Swim Series Popularity</v-card-title>    
              <GChart     
                type="BarChart"
                :data="modelData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Domestic Swim Cabinet Popularity</v-card-title>    
              <GChart     
                type="BarChart"
                :data="cabinetOptions"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
            
          </v-card>
           </v-col> 
    </v-row>
     <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-0 mt-n2">
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Domestic Swim Series Popularity</v-card-title>    
              <GChart     
                type="PieChart"
                :data="seriesData"
                :options="chartOptionsPie"
              >
              </Gchart>
            </v-card>

           
          </v-card>
           </v-col> 
    </v-row> 

    <v-bottom-navigation
  color="primary"
  >
  <v-btn to="analyticsoptions">
      <span>Open Options</span>
      <v-icon>mdi-table</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>  
    
  <v-btn to="/analyticsoo">
      <span>Open Hot Tubs</span>
      <v-icon>mdi-chart-pie</v-icon>
    </v-btn>
 
    <v-btn to="analyticsswimoo">
      <span>Open SWIM Spas</span>
      <v-icon>mdi-chart-pie-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analyticses">
      <span>Extreme</span>
      <v-icon>mdi-alpha-e-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsgs">
      <span>Great</span>
      <v-icon>mdi-alpha-g-circle-outline</v-icon>
    </v-btn>

    <v-btn to="analyticsvs">
      <span>Value</span>
      <v-icon>mdi-alpha-v-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsholiday">
      <span>Holiday</span>
      <v-icon>mdi-alpha-h-circle-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analytics">
      <span> Hot Tubs</span>
      <v-icon>mdi-chart-box</v-icon>
    </v-btn>

    <v-btn to="analyticsswim">
      <span>SWIM Spas</span>
      <v-icon>mdi-chart-box-outline</v-icon>
    </v-btn>

  </v-bottom-navigation>

  </v-card>
</template>

<script>
import { getSwimOrdersIntervals, getModelSwimCount, getAspelModelSwimCount, getAspelSwimSeriesCount, getSwimSeriesCount, getSwimCabOptionCount, getSwimAspelCabOptionCount } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "AnalyticsSwim",

  data: () => ({
    aspModelData: [
      ['Model', 'Sales'],
    ],
    modelData: [
      ['Model', 'Sales'],
    ],
    aspSeriesData: [
      ['Series', 'Sales'],
    ],
    seriesData: [
      ['Series', 'Sales'],
    ],
    cabinetOptions: [
      ['Option', 'Count']
    ],
     aspCabinetOptions: [
      ['Option', 'Count']
    ],
    orderCount: [],
      chartOptionsBar: {
      width: 700,
      height: 350,
      legend: { position: 'none'},
      titlePosition: 'none',
    },
      chartOptionsPie: {
      width: 1000,
      height: 350,
      legend: { position: 'top', alignment: 'center'},
      titlePosition: 'none',
    },
  }),

  mounted() {
    getAspelModelSwimCount().then((result) => {
      result.data.forEach((m) => this.aspModelData.push([ m.modelName, m.modelCount ]));
    })
    getModelSwimCount().then((result) => {
      result.data.forEach((m) => this.modelData.push([ m.modelName, m.modelCount ]));
    })
    getAspelSwimSeriesCount().then((result) => {
      result.data.forEach((s) => this.aspSeriesData.push([ s.seriesName, s.seriesCount ]));
    })
    getSwimSeriesCount().then((result) => {
      result.data.forEach((s) => this.seriesData.push([ s.seriesName, s.seriesCount ]));
    })
    getSwimCabOptionCount().then((result) => {
      result.data.forEach((o) => this.cabinetOptions.push([ o.optionName, o.optionCount])); 
    })
    getSwimAspelCabOptionCount().then((result) => {
      result.data.forEach((o) => this.aspCabinetOptions.push([ o.optionName, o.optionCount])); 
    })
    getSwimOrdersIntervals().then((result) => {
      this.orderCount = result.data;
    })
  },
  computed: {
    
  },

  components: {
    GChart
  },

}
</script>

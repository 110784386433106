<template>
<div>
  <v-card max-width="600" class="mx-auto mt-2" elevation="4">
    <v-toolbar
    color="secondary"
    dark>
      <v-toolbar-tilte>My Announcements</v-toolbar-tilte>
      <v-spacer></v-spacer>
      <v-text-field 
      v-model="search"
      prepend-icon="mdi-magnify"
      label="Search Announcement Title"
      single-line
      class="pt-5">
      </v-text-field>
    </v-toolbar>
    <v-list-item
        v-for="announcement in shownAnnouncements"
        :key="announcement.id"
      >
        <v-list-item-content>
          <v-list-item-title v-text="announcement.title"></v-list-item-title>

          <v-list-item-subtitle>{{moment.utc(announcement.createdAt).local().format('MM/DD/YYYY')}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="openAttachment(announcement)">  
            <v-icon class="mr-2" color="secondary"> mdi-receipt-text </v-icon>
          </v-btn>
        </template>
        <span>View Announcement</span>
      </v-tooltip>
        </v-list-item-action>
      </v-list-item>
      <v-pagination
      v-model="page"
      :length="Math.ceil(filteredAnnouncements.length/perPage)"></v-pagination>
    </v-card>
  </div>
</template>

<script>
import { getAnnouncements } from "@/services";


export default {
  name: "AnnouncementListDealer",

  data: () => ({
    search: '',
    announcements: [],
    page: 1,
    perPage: 5,
    moment: require('moment'),  
  }),

  mounted() {
    getAnnouncements().then((result) => {
      this.announcements = result.data;
    });
  },

  computed: {
    currentUser() { return this.$store.state.user },
    userTypeId() { return this.$store.getters.userTypeId },
    filteredAnnouncements() {
    return this.announcements.filter((item) =>{
         return item.title.toLowerCase().match(this.search.toLowerCase())
    })
  },
  shownAnnouncements(){
    return this.filteredAnnouncements.slice((this.page - 1)* this.perPage, this.page* this.perPage)
  }
  },

  methods: {
    openAttachment(item){
      window.open(item.announcement, '_blank');
    },
  },
  components: {
  }
};

</script>
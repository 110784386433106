<template>
  <v-app>
    <v-app-bar v-if="isLoggedIn" app color="primary" dark>
      <v-btn icon to="/"><v-icon>mdi-home</v-icon></v-btn>
      <v-toolbar-title>Aspen Dealer Portal</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="userTypeId === 1"
        class="px-2"
        icon
        large
        to="/dashboard"
      >
        <v-icon> mdi-view-dashboard </v-icon>
      </v-btn>
      <v-menu
        bottom
        min-width="200px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            large
            v-on="on"
          >
            <v-avatar color="secondary">
              <span class="white--text text-h6"> {{ getInitials }} </span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
            <v-avatar color="secondary">
              <span class="white--text text-h6"> {{ getInitials }} </span>
            </v-avatar>
              <h3 class="mt-2"> {{ currentUser.firstName }} {{ currentUser.lastName }} </h3>
              <p class="text-caption mt-1">
                {{ currentUser.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                to="/account"
              >
                Edit Account
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text                    
                @click="alert = !alert"
              >
                Log Out
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-dialog
        v-model="alert"
        max-width="300"
      >
        <v-card class="px-4 rounded-lg">
          <v-img
            src="./assets/aspenLogo.png"
            contain
            max-height="250"
          ></v-img>
          <v-card-title class="d-flex justify-center text-center secondary--text">
            Are You Sure You Want To Log Out?
          </v-card-title>
          <v-card-actions>
            <v-btn
              color="secondary"
              text
              @click="alert = false"
            >
              No
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="logout"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    alert: false,
  }),

  computed: {
    currentUser() { return this.$store.state.user },
    userTypeId() { return this.$store.getters.userTypeId },
    isLoggedIn() { return this.$store.getters.isLoggedIn },
    getInitials() {
      if (this.currentUser && this.currentUser.firstName) {
        return this.currentUser.firstName.charAt(0) + this.currentUser.lastName.charAt(0);
      }
      return null;
    },
  },

  methods: {
    logout() {
      this.alert=false;
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      })
    }
  }
};
</script>

<template>
<div>
  <v-data-table
  height='500'
  fixed-header
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :headers="headers"
    :items="shownOrders"
    :search="search"
    :loading="loading"
    loading-text="Loading Orders..."
    class="elevation-2 rounded-lg d-print-none"
  >
    <template v-slot:top>
      <v-toolbar flat class="my-2">
        <v-toolbar-title class="text-h4 primary--text font-weight-bold">Orders</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>          
        <v-spacer></v-spacer>

          <v-select
            v-model="filter"
            dense
            flat
            outlined
            hide-details
            :items="dealers"
            :item-text="item => item.name"
            :item-value="item => item.name"
            placeholder="Dealer"
          >
          </v-select>
          <v-spacer></v-spacer>
       
        <v-switch
          v-model="showClosedOrders"
          color="primary"
          label="Show Completed"
        ></v-switch>
   
      </v-toolbar>
    </template>

    <template v-slot:item.createdAt="{ item }">
      {{ moment.utc(item.createdAt).local().format('MM/DD/YYYY') }}
    </template>
      <template v-slot:item.orderDetails="{ item }">
        <v-btn icon @click="currentOrderId=item.id; showWorkOrder=true">
          <v-icon color="secondary">mdi-receipt-text</v-icon>
        </v-btn>
      </template>

    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon :to="'/order/'+item.id" v-if="item.status==='Pending' || item.status==='Approved'" v-on="on">
            <v-icon color="secondary">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Order</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="orderToDelete=item; confirmDelete=true;" v-if="item.status==='Pending' || item.status==='Approved'" v-on="on">
            <v-icon color="primary">mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete Order</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon :to="'/order/'+item.id+'/true'" v-on="on">
            <v-icon color="secondary"> mdi-content-copy </v-icon>
          </v-btn>
        </template>
        <span>Duplicate Order</span>
      </v-tooltip>      
    </template>

  </v-data-table>
  <v-dialog max-width="350" v-model="confirmDelete">
    <v-card>
      <v-card-title class="secondary--text d-flex justify-center text-h5">
        Are you sure you want to delete Order #{{ orderToDelete.serialNumber }}?
      </v-card-title>
      <v-card-actions>
        <v-btn text color="primary" @click="confirmDelete=false; orderToDelete={};">No</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="deleteOrder(orderToDelete); confirmDelete=false">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog fullscreen v-model="showWorkOrder">
    <v-toolbar
      class="d-print-none"
      flat
      color="secondary"
    >
      <v-btn @click="showWorkOrder=false">Close</v-btn>
       <v-spacer></v-spacer>
        <v-btn icon color="white" @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
    </v-toolbar>
    <SalesOrderCust :orderId="currentOrderId"></SalesOrderCust>
  </v-dialog>
  <v-snackbar
    v-model="snackbarAlert"
    text
    color="primary"
  >
    <v-container class="text-center text-h6">
      {{ alertMsg }}
    </v-container>
  </v-snackbar>
</div>
</template>

<script>
import { getOrders, deleteOrder } from "@/services";
import SalesOrderCust from "./SalesOrderCust.vue";

export default {
  name: "RepOrderList",

  data() {
    return {
      sortBy: "createdAt",
      sortDesc: true,
      search: "",
      filter:"",
      loading: false,
      moment: require('moment'),
      showClosedOrders: false,
      headers: [
        {
          text: "Serial No",
          align: "start",
          value: "serialNumber",
        },
        { text: "Dealer", value: "dealerName"},
        { text: "Order Date", value: "createdAt" },
        { text: "PO Number", value: "poNumber" },
        { text: "Customer Name", value: "customer"},

        { text: "Model", value: "modelName" },
        { text: "Series", value: "seriesName" },
        { text: "Sales Order", value: "orderDetails"},
        { text: "Order Total", value: "totalCost" },
        { text: "Status", value: "status" },
        { text: "User", value: "usersFullName"},
        // { text: "Actions", sortable: false, value: "actions" },
      ],
      orders: [],
      confirmDelete: false,
      orderToDelete: {},
      currentOrderId: null,
      showWorkOrder: false,
      snackbarAlert: false,
      alertMsg: ""
    };
  },

  mounted() {
    this.loading = true;
    getOrders().then((result) => {
      this.orders = result.data;
      this.loading = false;
    });
  },

  methods: {
    editOrder(order) {
      console.log("Edit order" + order);
    },
    deleteOrder(order) {
      //confirm
      deleteOrder(order.id).then((result) => {
          //splice it out of list
          let i = this.orders.findIndex(o => o.id === order.id);
          this.orders.splice(i, 1);
          this.alertMsg = "Successfully deleted order " + order.serialNumber;
          this.snackbarAlert = true;
          console.log("Deleted order:" + order.id);
        }).catch((err) => {
          this.alertMsg = "Failed to delete order " + order.serialNumber;
          this.snackbarAlert = true;
          console.log("Failed to Delete order:" + order.id);
        })
    },
     print() {
      window.print()
    },
  },
  computed: {
    shownOrders: function () {
       if(this.filter && this.filter !== "All" && this.showClosedOrders === false)
        {return this.orders.filter((order) => order.status !== "Completed" && order.dealerName === this.filter);} 
        else if (this.filter && this.filter !== "All" & this.showClosedOrders === true) 
        {return this.orders.filter((order) => order.status === "Completed" && order.dealerName === this.filter);}
        else if ((!this.filter || this.filter === "All") && this.showClosedOrders === false)
        {return this.orders.filter((order) => order.status !== "Completed");}
        else 
        return this.orders.filter((order) => order.status === "Completed")
    },
     dealers() {
      var arrayToCount = this.swimSpa ? this.swimSpas : this.orders;
      var dlrs = [];
      arrayToCount.forEach(o => {
        let df = dlrs.find(d => d.name === o.dealerName);
         if (df) {
           df.count ++;
         } else {
           dlrs.push({ name: o.dealerName, count: 1 });
         }
      })

      dlrs.sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
      })

      dlrs.unshift({ name: "All", count: arrayToCount.length });

      return dlrs;
    }
  },
  components: {
    SalesOrderCust,
  }

};
</script>

<style></style>

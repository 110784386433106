<template>
  <v-container class="mt-8">
    <v-row justify="center">
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="5"
        xl="3"
        align-self="center"
      >
        <v-card class="mt-16 px-6 rounded-lg" elevation="4">
          <v-row>
            <v-img
              src="../assets/aspenLogo.png"
              contain
            ></v-img>
          </v-row>
            <v-row>          
            <v-container class="justify-center px-14">
              <v-card elevation="0">
                <v-card-title class="text-center primary--text font-weight-bold text-h5 pt-0">
                  <v-container>
                    Edit Account
                  </v-container>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-list class="pb-0">
                    <v-list-item>
                      <v-text-field
                        v-model="currentUser.firstName"
                        label="First Name"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item>
                      <v-text-field
                        v-model="currentUser.lastName"
                        label="Last Name"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item>
                      <v-text-field
                      v-model="currentUser.email"
                      label="Email"
                      :rules="[rules.required, rules.email]"
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item class="pb-4 d-flex justify-center">
                      <v-btn class="font-weight-bold" text @click="setPassword=true">Change Password</v-btn>
                    </v-list-item>
                    <v-alert
                      class="d-flex justify-center"
                      v-if="passChanged && !changeSaved"
                      dense
                      text
                      type="success"
                    >
                      Password Successfully Changed
                    </v-alert>
                    <v-alert
                      class="d-flex justify-center"
                      v-if="changeSaved"
                      dense
                      text
                      type="success"
                    >
                      Changes Saved
                    </v-alert>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-container>
          </v-row>
          <v-card-actions class="pr-2">
            <v-spacer></v-spacer>
            <v-btn text @click="save" color="primary">Save Changes</v-btn>
          </v-card-actions>
        </v-card>
        <v-dialog
          v-model="setPassword"
          max-width="300"
        >
          <v-card>
            <v-card-title class="primary--text font-weight-bold">Change Password</v-card-title>              
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                class="mt-2"
                :rules="[rules.validPass, rules.required]"
                v-model="user.password"
                label="New Password"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1=!show1"
              ></v-text-field>
              <v-text-field
                v-model="rePassword"
                :rules="[rules.required, rules.passMatch, passwordConfirmationRule]"
                label="Confirm Password"
                :type="show2 ? 'text' : 'password'"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show2=!show2"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="setPassword=false">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="changePassword(); setPassword=false; passChanged=true; changeSaved=false">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import { editUser, editPassword } from "@/services";

export default {
  name:"Account",

  data: () => ({
    show1: false,
    show2: false,
    password: 'Password',
    passChanged: false,
    changeSaved: false,

    userInitials: '',

    setPassword: false,
    rePassword: '',

    user: {
      id: null,
      password: '',
    },

    rules: {
      required: (v) => !!v || "Required",
      validPass: (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },    
    }
  }),

  mounted () {
    this.user.id = this.$store.state.user.id
  },

  computed: {
    currentUser() { return this.$store.state.user },
    passwordConfirmationRule() {
      return () => (this.rePassword === this.user.password || 'Passwords Must Match')
    },
  },

  methods: {
    save() {
      editUser(this.currentUser).then((result) => {
        console.log(this.currentUser)
        console.log(result)
        changeSaved=true;
      });
    },

    changePassword() {
      editPassword(this.user)
    }
  }

}
</script>

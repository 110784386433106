<template>
  <v-container class="d-flex justify-space-around">
    <v-container>
      <v-card>
        <v-card-title class="primary--text subheading font-weight-bold">
          Option Types
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="editOptionType(defaultItem)" v-on="on">
                <v-icon color="secondary"> mdi-plus-circle </v-icon> <!--mdi-add-circle-->
              </v-btn>
            </template>
            <span>Create</span>
          </v-tooltip>
        </v-card-title>

        <v-divider></v-divider>

        <v-list>
          <v-list-item-group v-model="selected" active-class="accent">
            <template v-for="(item, index) in optionTypes">
              <v-divider v-if="index > 0" :key="'d' + index"></v-divider>
              <v-list-item :key="item.id" :value="item.id">
                <v-list-item-content>
                  <v-list-item-title class="secondary--text" v-text="item.name"></v-list-item-title>

                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="item.price"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="editOptionType(item)">
                        <v-icon
                          color="secondary"
                        >
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </v-list-item-action
                ><br />
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title class="secondary--text subheading font-weight-bold">
          Options
          <v-spacer></v-spacer>
          <span v-if="selected">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="editOption(defaultOption)" v-on="on">
                  <v-icon color="secondary"> mdi-plus-circle </v-icon> <!--mdi-add-circle-->
                </v-btn>
              </template>
              <span>Create</span>
            </v-tooltip>
          </span>
        </v-card-title>

        <v-divider></v-divider>
        <div v-if="selected">
          <v-list>
            <v-list-item-group v-model="selectedOption" active-class="accent">
              <template v-for="(option, index) in currentOptions">
                <v-divider v-if="index > 0" :key="'d' + index"></v-divider>
                <v-list-item :key="option.id" :value="option.id">
                  <v-list-item-content>
                    <v-list-item-title class="secondary--text" v-text="option.name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="editOption(option)">
                          <v-icon
                            color="secondary"
                          >
                            mdi-pencil-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </div>
      </v-card>
    </v-container>
    <v-dialog v-model="optionTypeDialog" max-width="350">
      <v-card>
        <v-card-title>
          <span v-if="editedItem.id" class="text-h5 primary--text font-weight-bold">Edit OptionType</span>
          <span v-else class="text-h5 primary--text font-weight-bold">New OptionType</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-container class="d-flex justify-center">
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-container>
            </v-row>
            <v-row>
              <v-container class="d-flex justify-center">
                <v-col cols="12">
                  <v-select
                    :items="optionGroups"
                    label="Option Group"
                    v-model="editedItem.group"
                  ></v-select>
                </v-col>
              </v-container>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="closeOptionType">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveOptionType">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="optionDialog" max-width="350">
      <v-card>
        <v-card-title>
          <span v-if="editedOption.id" class="text-h5 primary--text font-weight-bold">Edit Option</span>
          <span v-else class="text-h5 primary--text font-weight-bold">New Option</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-container class="d-flex justify-center">
                <v-col cols="12">
                  <v-text-field
                    v-model="editedOption.name"
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-container>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="closeOption">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveOption"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getOptionTypes,
  getOptions,
  createOptionType,
  editOptionType,
  createOption,
  editOption,
} from "@/services";

export default {
  name: "OptionTypeEditor",

  data: () => ({
    selected: null,
    selectedOption: null,

    optionTypes: [],
    options: {},

    optionTypeDialog: false,
    optionDialog: false,

    editedIndex: -1,

    editedItem: {
      id: null,
      group: "",
      name: "",
    },
    defaultItem: {
      id: null,
      group: "",
      name: "",
    },

    editedOption: {
      id: null,
      option_type_id: null,
      name: "",
    },
    defaultOption: {
      id: null,
      option_type_id: null,
      name: "",
    },

    optionGroups: [
      {
        text: "Color",
        value: "color",
      },
      {
        text: "Other",
        value: "other",
      },
    ],
  }),

  mounted() {
    getOptionTypes().then((result) => {
      this.optionTypes = result.data;
    });
    getOptions().then((result) => {
      this.options = result.data;
    });
  },

  computed: {
    currentOptions: function () {
      if (this.selected) {
        return this.options.filter((o) => this.selected === o.option_type_id);
      }
      return null;
    },
  },

  methods: {
    editOptionType(item) {
      this.editedIndex = this.optionTypes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.optionTypeDialog = true;
    },

    closeOptionType() {
      this.optionTypeDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = null;
      });
    },

    saveOptionType() {
      if (this.editedItem.id) {
        editOptionType(this.editedItem).then((result) => {
          console.log(result);
          this.optionTypes[this.editedIndex] = result.data;
        });
      } else {
        createOptionType(this.editedItem).then((result) => {
          console.log(result);
          this.optionTypes.push(result.data);
        });
      }
      this.closeOptionType();
    },

    editOption(item) {
      item.option_type_id = this.selected;
      this.editedIndex = this.options.indexOf(item);
      this.editedOption = Object.assign({}, item);
      this.optionDialog = true;
    },

    closeOption() {
      this.optionDialog = false;
      this.$nextTick(() => {
        this.editedOption = Object.assign({}, this.defaultOption);
        this.editedIndex = null;
      });
    },

    saveOption() {
      if (this.editedOption.id) {
        editOption(this.editedOption).then((result) => {
          this.options[this.editedIndex] = result.data;
        });
      } else {
        createOption(this.editedOption).then((result) => {
          console.log(result);
          this.options.push(result.data);
        });
      }
      this.closeOption();
    },
  },
};
</script>

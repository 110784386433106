<template>
  <v-card>
    <v-banner
    sticky
    color="white"
    elevation="7"
    class="text-center">
    <span class="text-h4"> Dealer Profile and 2 Year Breakdown for <span class="font-weight-bold primary--text">{{ this.dealerx.name }}</span></span>
     <v-row justify="center">
       <v-card elevation="0" class="ma-3 pa-2 rounded-lg text-center">
        <span class="text-h6">
          Primary Address:
        </span> <br/>
    
        <span>
        {{this.dealerx.address1}} 
        {{this.dealerx.address2}} <br/>
        {{this.dealerx.city}},
        {{this.dealerx.stateProvince}} 
        {{this.dealerx.postalCode}} 
        {{this.dealerx.country}}
        </span>
      </v-card>

      <v-card elevation="0" class="ma-3 pa-2  text-center">
       <span class="text-h6">
          Contact Info:
        </span> <br/>
        
        <span>
           Office Phone #: {{this.dealerx.phone}} <br/>
           Email: {{this.dealerx.email}} <br/>
            URL: {{this.dealerx.website}} 
        </span>
      </v-card>

      <v-card elevation="0" class="ma-3 pa-2 rounded-lg text-center">
       <span class="text-h6">
        Store Info:
       </span> <br/>
        
        <span>
        {{this.dealerx.catalogName}} <br/>
        # of Stores: {{this.dealerx.num_stores}} <br/>
        # of Aspens on Showroom Floor: {{this.dealerx.showroom_aspenspas}}
        </span>
      </v-card>

      <v-card elevation="0" class="ma-3 pa-2 rounded-lg text-center">
        <span class="text-h6">
          Service Info:
        </span><br/>
          
        <span>
        Service Dept Phone #: {{this.dealerx.phone_service_dept}} <br/>
        Service: {{this.dealerx.service_provided}}<br/>
        {{this.dealerx.deliver_setup}} 
        
        </span>
      </v-card>

       <v-card elevation="0" class="ma-3 pa-2 rounded-lg">
        <span class="text-h6">
         Other Brands Carried:
        </span> <br/>
         <span>
          {{this.dealerx.other_brands_carried}}
         </span>

      </v-card>

       

      

    </v-row>
    </v-banner>
    <br/>


    


      <v-row justify="center">
           <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
            <v-card-title>
             <span> Spas Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 3 Months </span></span>  
            </v-card-title>
            <span class="text-h5 font-weight-bold">{{this.orderCount.threeMonthOrders}}</span>
          </v-card>
              
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                   <span> Spas Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 6 Months </span></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.sixMonthOrders}}</span>
              </v-card>
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                 <span> Spas Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 1 Year </span></span>
                </v-card-title>
                 <span class="text-h5 font-weight-bold">{{this.orderCount.oneYearOrders}}</span>
              </v-card>
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                  <span> Spas Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 2 Years </span></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.twoYearOrders}}</span>
              </v-card>
        </v-row>
        <v-row justify="center">
           <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
            <v-card-title>
              <span> Spas Shipped <v-icon color="green">mdi-forklift</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 3 Months </span></span>
            </v-card-title>
            <span class="text-h5 font-weight-bold">{{this.orderCount.threeMonthOrdersShiped}}</span>
          </v-card>
              
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                  <span> Spas Shipped <v-icon color="green">mdi-forklift</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 6 Months </span></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.sixMonthOrdersShiped}}</span>
              </v-card>
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                  <span> Spas Shipped <v-icon color="green">mdi-forklift</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 1 Year </span></span>
                </v-card-title>
                 <span class="text-h5 font-weight-bold">{{this.orderCount.oneYearOrdersShiped}}</span>
              </v-card>
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                  <span> Spas Shipped <v-icon color="green">mdi-forklift</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 2 Years </span></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.twoYearOrdersShiped}}</span>
              </v-card>
        </v-row>

        <v-row justify="center">
           <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
          <v-card-title class="justify-center"> Hot Tubs Ordered By Month Over Last 2 Years</v-card-title>
              <GChart
              type="LineChart"
                :data="orderData"
                :options="chartOptionsBar"
              >
              </GChart>
          </v-card>
            
          <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
          <v-card-title class="justify-center"> Hot Tubs Shiped by Month Over Last 2 Years</v-card-title>
              <GChart
              type="LineChart"
                :data="shipData"
                :options="chartOptionsBar"
              >
              </GChart>
          </v-card>
        </v-row>

        <v-row justify="center">
             <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Model Popularity Last 2 Years</v-card-title>    
              <GChart     
                type="BarChart"
                :data="modelsData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
              
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Series Popularity Last 2 Years</v-card-title>    
              <GChart     
                type="PieChart"
                :data="seriesData"
                :options="chartOptionsPie"
              >
              </Gchart>
            </v-card>
              
           
        </v-row>

        <v-row justify="center">
          <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Arcylic Color Popularity Last 2 Years</v-card-title>    
              <GChart     
                type="BarChart"
                :data="acrylicData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
            
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Cabinet Color Popularity Last 2 Years</v-card-title>    
              <GChart     
                type="BarChart"
                :data="cabinetData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>

        </v-row>


        <v-row justify="space-around">
          <v-card min-width="75%" elevation="7" class="ma-3 pa-2 rounded-lg text-center" >
            <span class="text-h6">Users connected to {{this.dealerx.name}}</span>
            <v-data-table
  :headers="headers"
  :items="userx"
  :items-per-page="-1"
  hide-default-footer
  sort-by="userTypeId">
  <template v-slot:item.userTypeId="{ item }">
        {{ userTypes[item.userTypeId-1].text }}
      </template>

  </v-data-table>

          </v-card>
  

 
         

        </v-row>

    
 
  </v-card>
</template>

<script>
import { getDealerx, getUserx, getDateCountDealer, getDateCountShipedDealer, getCountDealerOrders,getDealerModelCount, getDealerSeriesCount, getDealerAcrylicOptionCount, getDealerCabOptionCount } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "AnalyticsDealer",

  data: () => ({
    dealerx:{},
    userx:[],
    headers: [
      {text: "Name", value: "name", sortable: false},
      {text: "Email", value: "email", sortable: false},
      {text: "User Type", value: "userTypeId"}],

      userTypes: [
      { value: 1, text: "Aspen Administrator"},
      { value: 2, text: "Aspen Sales Employee"},
      { value: 3, text: "Dealership Sales Employee"},
      { value: 4, text: "Dealership Aspen Rep"},
      { value: 5, text: "Aspen Admin Assistant"},
      { value: 6, text: "Vacuum Former Employee"},
      { value: 7, text: "Fiberglass Employee"},
      { value: 8, text: "Tub Driller Employee"},
      { value: 9, text: "Frame Builder Employee" },
      { value: 10, text: "Cabinet Builder Employee" },
      { value: 11, text: "Plumbing Employee" },
      { value: 12, text: "Testing Employee" },
      { value: 13, text: "Packaging Employee"},
      { value: 14, text: "Swim Phase 1 Employee"},
      { value: 15, text: "Swim Phase 2 Employee"},
      { value: 16, text: "Pre-Plumbing Employee"},
      { value: 17, text: "Foreman Employee"},
      { value: 18, text: "Dealership Service Employee"},
      { value: 19, text: "Dealership Owner"}
      
    ],
      
    modelsData: [
      ['Model', 'Sales', {role: 'annotation'}],
    ],
    seriesData: [
      ['Series', 'Sales']
    ],
     acrylicData: [
       ['Option', 'Count']
     ],
    cabinetData: [
      ['Option', 'Count']
    ],
    orderData: [
      ['Dates', 'Count']
    ],
    shipData: [
      ['Dates', 'Count']
    ],
    orderCount: [],
    chartOptionsBar: {
      width: 700,
      height: 350,
      legend: { position: 'none'},
      titlePosition: 'none',
    },
      chartOptionsPie: {
      width: 700,
      height: 350,
      legend: { position: 'top', alignment: 'center'},
      titlePosition: 'none',
    },
  }),

  props: ['dealerId'],

  mounted() {
    getDealerModelCount(this.dealerId).then((result) => {
      result.data.forEach((m) => this.modelsData.push([ m.modelName, m.modelCount, m.modelGroup ]));
    })
    getDealerSeriesCount(this.dealerId).then((result) => {
      result.data.forEach((s) => this.seriesData.push([ s.seriesName, s.seriesCount ]));
    })
    getDealerAcrylicOptionCount(this.dealerId).then((result) => {
      result.data.forEach((o) => this.acrylicData.push([ o.optionName, o.optionCount])); 
    })
    getDealerCabOptionCount(this.dealerId).then((result) => {
      result.data.forEach((o) => this.cabinetData.push([ o.optionName, o.optionCount])); 
    })
    getDateCountDealer(this.dealerId).then((result) => {
      result.data.forEach((o) => this.orderData.push([ o.dates, o.dealerOrders]));
    })
    getDateCountShipedDealer(this.dealerId).then((result) => {
      result.data.forEach((o) => this.shipData.push([ o.datesShiped, o.dealerOrders]));
    })
    getDealerx(this.dealerId).then((result) => {
      this.dealerx = result.data;
    })
    getCountDealerOrders(this.dealerId).then((result) => {
      this.orderCount = result.data;
    })
    getUserx(this.dealerId).then((result) => {
      this.userx = result.data;
    })
  },


  computed: {
    
  },


  watch: {
    dealerId: function () {
      this.modelsData=[['Model', 'Sales', {role: 'annotation'}]];
      this.seriesData=[['Series', 'Sales']];
      this.acrylicData=[['Option', 'Count']];
      this.cabinetData=[['Option', 'Count']];
      this.orderData=[['Dates', 'Count']];
      this.shipData=[['Dates', 'Count']];
      this.dealerx={};
      this.orderCount=[];
      this.userx=[];
      getDealerModelCount(this.dealerId).then((result) => {
      result.data.forEach((m) => this.modelsData.push([ m.modelName, m.modelCount, m.modelGroup ]));
    })
     getDealerSeriesCount(this.dealerId).then((result) => {
      result.data.forEach((s) => this.seriesData.push([ s.seriesName, s.seriesCount ]));
    })
     getDealerAcrylicOptionCount(this.dealerId).then((result) => {
      result.data.forEach((o) => this.acrylicData.push([ o.optionName, o.optionCount])); 
    })
    getDealerCabOptionCount(this.dealerId).then((result) => {
      result.data.forEach((o) => this.cabinetData.push([ o.optionName, o.optionCount])); 
    })
    getDateCountDealer(this.dealerId).then((result) => {
      result.data.forEach((o) => this.orderData.push([ o.dates, o.dealerOrders]));
    })
      getDateCountShipedDealer(this.dealerId).then((result) => {
      result.data.forEach((o) => this.shipData.push([ o.datesShiped, o.dealerOrders]));
    })
    getDealerx(this.dealerId).then((result) => {
     this.dealerx = result.data;
    })
    getCountDealerOrders(this.dealerId).then((result) => {
      this.orderCount = result.data;
    })
     getUserx(this.dealerId).then((result) => {
      this.userx = result.data;
    })
  }
  },

  components: {
    GChart
  },

}
</script>

<template>
  <v-card>
    <v-data-table
      height='600'
      fixed-header
      class="pl-4 d-print-none"
      :headers="headers"
      :items="filteredOrders"
      :search="search"
      :loading="loading"
      loading-text="Loading Orders..."
      multi-sort
      sort-by="createdAt"
    >
      <template v-slot:top>
        <v-toolbar flat class="my-2">
          <v-toolbar-title class="text-h4 primary--text font-weight-bold">Orders</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>          
          <v-spacer></v-spacer>
          <v-select
            v-model="filter"
            dense
            flat
            outlined
            hide-details
            :items="dealers"
            :item-text="item => `${item.name} (${item.count})`"
            :item-value="item => item.name"
            placeholder="Dealer"
          >
          </v-select>
          <v-spacer></v-spacer>
          <v-switch
            class="mt-8"
            v-model="swimSpa"
            inset
            label="SWIM Spas"
          >
          </v-switch>
        </v-toolbar>
      </template>
      <template v-slot:item.back="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">    
            <v-btn icon @click="warning=true; currentOrderId=item.id" v-on="on">
              <v-icon color="red"> mdi-restore </v-icon>
            </v-btn>
          </template>
          <span>Move Back to Pending</span>
        </v-tooltip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ moment.utc(item.createdAt).local().format('MM/DD/YYYY') }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="currentOrderId=item.id; showWorkOrder=true">
          <v-icon color="secondary">mdi-receipt-text</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status === 'Approved'"
          color="yellow"
        >
          {{ item.status }}
        </v-chip>
        <v-chip v-else
          color="blue"
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item, index }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">    
            <v-btn v-if="item.status==='Approved'" @click="scheduleOne(item, index)" icon v-on="on" >
              <v-icon color="blue"> mdi-calendar-end </v-icon>
            </v-btn>
          </template>
          <span>Schedule Order</span>
        </v-tooltip>


        
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="tempOrder=item; tempIndex=index; notesPage=true" v-on="on">
              <v-icon color="primary">mdi-comment-outline</v-icon>
            </v-btn>
          </template>
          <span>Add Notes</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">    
            <v-btn icon :to="'/order/'+item.id" v-on="on">
              <v-icon color="secondary"> mdi-pencil-outline </v-icon>
            </v-btn>
          </template>
          <span>Edit Order</span>
        </v-tooltip>
         
        <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="orderToDelete=item; confirmDelete=true;" v-on="on">
            <v-icon color="primary">mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete Order</span>
      </v-tooltip>

      </template>
      
    </v-data-table>
    <v-dialog v-model="notesPage" max-width="500">
      <v-card>
        <v-card-title class="primary--text">Order Notes</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea
            v-model="tempOrder.notes"
            auto-grow
            rows="1"
            label="Add Notes For Order"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="updateNotes(tempOrder, tempIndex); notesPage=false">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="approveAlert"
      text
      color="primary"
    >
      <v-container class="text-center text-h6">
        {{ alertMsg }}
      </v-container>
    </v-snackbar>
    <v-dialog max-width="50%" v-model="warning">
      <v-card>
        <v-card-title class="secondary--text d-flex justify-center text-h5 text-center">
          Are You Sure You Want to Move Order #{{ currentOrderId }} Back to Pending?
        </v-card-title>
        <v-card-actions>
          <v-btn text color="primary" @click="warning=false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="backStatus(currentOrderId); warning=false">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog fullscreen
      v-model="showWorkOrder"
    >
      <v-toolbar
        class="d-print-none"
        flat
        color="secondary"
      >
        <v-btn @click="showWorkOrder=false">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn icon color="white" @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-toolbar>
      <SalesOrder :orderId="currentOrderId"></SalesOrder>
    </v-dialog>
    <v-dialog max-width="350" v-model="confirmDelete">
      <v-card>
        <v-card-title class="secondary--text d-flex justify-center text-h5">
          Are you sure you want to delete Order #{{ orderToDelete.serialNumber }}?
        </v-card-title>
        <v-card-actions>
          <v-btn text color="primary" @click="confirmDelete=false; orderToDelete={};">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="deleteOrder(orderToDelete); confirmDelete=false">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getDealerOrders, getOrdersWithStatus, updateOrderNotes, deleteOrder, updateOrderStatus} from "@/services"
import SalesOrder from "./SalesOrder.vue";
import moment from 'moment';

export default {
  name: "Scheduling",

  data: () => ({
    orders: [],
    swimSpas: [],
    color: "red",
    loading: false,
    search: "",
    notesPage: false,
    editedShipDate: null,
    tempOrder: [],
    tempIndex: null,
    currentOrderId: null,
    showWorkOrder: false,
    approveAlert: false,
    alertMsg: '',
    confirmDelete: false,
    orderToDelete: {},
    moment: require('moment'),
    filter: "",
    swimSpa: false,
    warning: false,

    headers: [
      {
        text: "Back",
        align: "start",
        sortable: false,
        value: "back",
        groupable: false
      },
      {
        text: "Serial No",
        align: "start",
        value: "serialNumber",
      },
      { text: "Dealer", value: "dealerName" },
      { text: "Customer Name", value: "customer" },
      { text: "Order Date", value: "createdAt" },
      { text: "Model", value: "modelName" },
      { text: "Series", value: "seriesName" },
      { text: "Sales Order", value: "options" },
      { text: "Order Total", value: "totalCost" },
      { text: "Status", value: "status" },
      { text: "Actions", sortable: false, value: "actions" },
    ],    


  }),

  mounted() {
    this.loading = true;
    if (this.swimProp === "true")
      this.swimSpa = true;
    getOrdersWithStatus("Approved").then((result) => {
      result.data.forEach((t) => {
        if(t.productionStartDate) {
          t.productionStartDate = moment(t.productionStartDate).format('YYYY-MM-DD')
        }
        if(t.modelGroup !== "S.W.I.M.")
          this.orders.push(t)
        else
          this.swimSpas.push(t)
      });
      this.loading = false;
    })
  },

  props: ['swimProp'],

  methods: {
    deleteOrder(order) {
      //confirm
      deleteOrder(order.id).then((result) => {
          //splice it out of list
          let i = this.orders.findIndex(o => o.id === order.id);
          this.orders.splice(i, 1);
          this.alertMsg = "Successfully deleted order " + order.serialNumber;
          this.approveAlert = true;
          console.log("Deleted order:" + order.id);
        }).catch((err) => {
          this.alertMsg = "Failed to delete order " + order.serialNumber;
          this.approveAlert = true;
          console.log("Failed to Delete order:" + order.id);
        })
    },
    updateNotes(item, index) {
      updateOrderNotes(item).then((result) => {
        if (result.data.productionStartDate !== null) {
          result.data.productionStartDate = moment(result.data.productionStartDate).format('YYYY-MM-DD');
        }
        if (result.data.modelGroup !== 'S.W.I.M.')
          this.orders.splice(this.orders.findIndex((order) => order.id === item.id), 1, result.data)
        else
          this.swimSpas.splice(this.swimSpas.findIndex((order) => order.id === item.id), 1, result.data)
        this.alertMsg = `Notes for Order #${item.id} Have Been Updated!`;
        this.approveAlert=true;

      }) 
    },
    scheduleOne(item, index) {
      updateOrderStatus({ orderId: item.id, orderStatus: 'Scheduled' }).then(response => {
        if (response.data.modelGroup !== 'S.W.I.M.')
          this.orders.splice(this.orders.findIndex((order) => order.id === item.id), 1, response.data)
        else
          this.swimSpas.splice(this.swimSpas.findIndex((order) => order.id === item.id), 1, response.data)
        this.alertMsg = `Order #${item.id} Has Been Moved to Scheduling!`;
        this.approveAlert=true;
      }) 
      .catch(error => {
        this.alertMsg = "Failed to Move Order to Scheduling."
        this.approveAlert=true;
      })
    },
    backStatus(itemId, index) {
      updateOrderStatus({ orderId: itemId, orderStatus: 'Pending' }).then(response => {
        console.log(response.data)
        if (response.data.modelGroup !== 'S.W.I.M.')
          this.orders.splice(this.orders.findIndex((order) => order.id === itemId), 1)
        else
          this.swimSpas.splice(this.swimSpas.findIndex((order) => order.id === itemId), 1)
        this.alertMsg = `Order #${itemId} Has Been Moved to Approve Orders!`;
        this.approveAlert=true;
      }) 
      .catch(error => {
        console.log(error)
        this.alertMsg = "Failed to Move Order to Approve Orders."
        this.approveAlert=true;
      })
    },
    print() {
      window.print()
    },

  },

  computed: {
    filteredOrders() {
      if (this.filter && this.filter !== "All" && !this.swimSpa)
        return this.orders.filter(order => order.dealerName === this.filter)
      else if ((!this.filter || this.filter === "All") && this.swimSpa)
        return this.swimSpas
      else if ((this.filter && this.filter !== "All") && this.swimSpa)
        return this.swimSpas.filter(order => order.dealerName === this.filter)
      else
        return this.orders
    },
    dealers() {
       var arrayToCount = this.swimSpa ? this.swimSpas : this.orders;
       var dlrs = [];
      arrayToCount.forEach(o => {
        if (o.dealerName != null) {
        let df = dlrs.find(d => d.name === o.dealerName);
         if (df) {
           df.count ++;
         } else {
           dlrs.push({ name: o.dealerName, count: 1 });
         }
        }
      })

      dlrs.sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
      })

      dlrs.unshift({ name: "All", count: arrayToCount.length });

      return dlrs;
    }
  },

  components: {
    SalesOrder,
  }

};
</script>

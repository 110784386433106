<template>
  <v-card>
     <v-banner
    elevation="5"
    class="text-center font-weight-bold">
    <span class="text-h4 font weight-bold"> Open Orders Options Breakdown </span> <br/>
    Breakdown of Parts / Options / Upgrades Currently On Order
    </v-banner>
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-2">
           
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Top Side Control On Order Breakdoown</v-card-title>    
              <GChart     
                type="Table"
                :data="gsTSCOptionData"
                :options="gsOptionsOptions"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">LEDs On Order Breakdown</v-card-title>    
              <GChart     
                type="Table"
                :data="gsLEDOptionData"
                :options="gsOptionsOptions"
              >
              </Gchart>
            </v-card>
             <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Cabinet Lighting On Order Breakdown</v-card-title>    
              <GChart     
                type="Table"
                :data="gsCabLightOptionData"
                :options="gsOptionsOptions"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row> 
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-0 mt-n2">
             <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Volcano Jets On Order Breakdown</v-card-title>    
              <GChart     
                type="Table"
                :data="gsVolOptionData"
                :options="gsOptionsOptions"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Circulation Pump Upgrades On Order</v-card-title>    
              <GChart     
                type="Table"
                :data="gsCircOptionData"
                :options="gsOptionsOptions"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">In. Touch2 Add-Ons On Order</v-card-title>    
              <GChart     
                type="Table"
                :data="gsIntOptionData"
                :options="gsOptionsOptions"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row> 
    <v-row>
            <v-col>
              <v-card elevation="0" class="d-flex justify-center pt-0 mt-n2" >
           <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Stereos On Order Breakdown</v-card-title>    
              <GChart     
                type="Table"
                :data="gsStereoOptionData"
                :options="gsOptionsOptions"
              >
              </Gchart>
            </v-card>

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Insulation Packages On Order</v-card-title>    
              <GChart     
                type="Table"
                :data="gsInsOptionData"
                :options="gsOptionsOptions"
              >
              </Gchart>
            </v-card>
             <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Alternate Sanitation Systems On Order</v-card-title>    
              <GChart     
                type="Table"
                :data="gsSaniOptionData"
                :options="gsOptionsOptions"
              >
              </Gchart>
            </v-card>
          </v-card>
        </v-col>
    </v-row>

    <v-bottom-navigation
  color="primary"
  >
  <v-btn to="analyticsoptions">
      <span>Open Options</span>
      <v-icon>mdi-table</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>  
    
  <v-btn to="/analyticsoo">
      <span>Open Hot Tubs</span>
      <v-icon>mdi-chart-pie</v-icon>
    </v-btn>
 
    <v-btn to="analyticsswimoo">
      <span>Open SWIM Spas</span>
      <v-icon>mdi-chart-pie-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analyticses">
      <span>Extreme</span>
      <v-icon>mdi-alpha-e-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsgs">
      <span>Great</span>
      <v-icon>mdi-alpha-g-circle-outline</v-icon>
    </v-btn>

    <v-btn to="analyticsvs">
      <span>Value</span>
      <v-icon>mdi-alpha-v-circle</v-icon>
    </v-btn>

    <v-btn to="analyticsholiday">
      <span>Holiday</span>
      <v-icon>mdi-alpha-h-circle-outline</v-icon>
    </v-btn>

    <v-divider vertical></v-divider>

    <v-btn to="analytics">
      <span> Hot Tubs</span>
      <v-icon>mdi-chart-box</v-icon>
    </v-btn>

    <v-btn to="analyticsswim">
      <span>SWIM Spas</span>
      <v-icon>mdi-chart-box-outline</v-icon>
    </v-btn>

  </v-bottom-navigation>

  </v-card>
</template>

<script>
import { getOptionCount } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "AnalyticsOptions",

  data: () => ({
    gsCabLightOptionData: [
      ['Option', 'Count']
    ],
    gsTSCOptionData: [
      ['Option', 'Count']
    ],
    gsVolOptionData: [
      ['Option', 'Count']
    ],
    gsCircOptionData: [
      ['Option', 'Count']
    ],
    gsIntOptionData: [
      ['Option', 'Count']
    ],
    gsLEDOptionData: [
      ['Option', 'Count']
    ],
    gsInsOptionData: [
      ['Option', 'Count']
    ],
    gsStereoOptionData: [
      ['Option', 'Count']
    ],
    gsSaniOptionData: [
      ['Option', 'Count']
    ], 
    gsOptionsOptions: {
     
      width: 425,
      height: 300,
      titlePosition: 'none',
    },
  }),

  mounted() {
    getOptionCount().then((result) => {
      result.data.forEach(o => {
        if (o.optionTypeName === "Cabinet Lighting") {
          this.gsCabLightOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Insulation") {
          this.gsInsOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Top Side Controls") {
          this.gsTSCOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Volcano Jets") {
          this.gsVolOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "24hr. Circulation Pump") {
          this.gsCircOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "in.touch 2 App") {
          this.gsIntOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "LED Lighting") {
          this.gsLEDOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Stereo") {
          this.gsStereoOptionData.push([o.optionName, o.optionCount])
        }
        if (o.optionTypeName === "Sanitation") {
          this.gsSaniOptionData.push([o.optionName, o.optionCount])
        }  
      })
    })
  },
  computed: {
    
  },

  components: {
    GChart
  },

}
</script>

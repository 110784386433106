<template>
  <v-data-table
    :headers="headers"
    :items="series"
    sort-by="name"
    class="elevation-2 rounded-lg"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="text-h4 primary--text font-weight-bold">Series Editor</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>          
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="35%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on">
              New Series
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5"> {{ formTitle }} </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-container class="d-flex justify-center">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Series Name"
                      ></v-text-field>
                    </v-col>
                  </v-container>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea cols="12"
                      auto-grow
                      rows="1"
                      v-model="editedItem.description"
                      label="Description"
                      placeholder="Enter Description..."
                      persistent-placeholder
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="secondary" text @click="close"> Cancel </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="editItem(item)">
            <v-icon color="secondary" class="mr-2"> mdi-pencil-outline </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { getSeries, createSeries, editSeries } from "@/services";

export default {
  name: "SeriesEditor",

  data: () => ({
    dialog: false,
    search: "",
    headers: [
      {
        text: "Series",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Description", value: "description" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    editedIndex: -1,

    editedItem: {
      id: null,
      name: "",
      description: "",
    },
    defaultItem: {
      id: null,
      name: "",
      description: "",
    },

    series: [],
  }),

  mounted() {
    getSeries().then((result) => {
      this.series = result.data;
    });
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.series.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        editSeries(this.editedItem).then((result) => {
          console.log(result);
          this.series[this.editedIndex] = result.data;
        });
      } else {
        createSeries(this.editedItem).then((result) => {
          this.series.push(result.data);
        });
      }
      this.close();
    },
  },
};
</script>

<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :loading="loading"
      loading-text="Loading Users..."
      fixed-header
      height="500"
    >
      <template v-slot:top>
        <v-toolbar flat class="my-2">
          <v-toolbar-title class="text-h4 primary--text font-weight-bold">Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>          
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" width="50%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" v-bind="attrs" v-on="on">
                New User
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                <span class="text-h5"> {{ formTitle }} </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedUser.firstName"
                        label="First Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedUser.lastName"
                        label="Last Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedUser.email"
                        label="Email"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-select
                        v-model="editedUser.userTypeId"
                        label="User Type"
                        :items="userTypes"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-select
                        v-model="editedUser.dealers"
                        label="Dealer"
                        :items="dealers"
                        multiple
                        chips
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-switch
                      color="success"
                      v-model="editedUser.active"
                      :label="editedUser.active ? 'Active' : 'Inactive'"
                    ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="close"> Cancel </v-btn>
                <v-btn color="primary" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.userType="{ item }">
        {{ userTypes[item.userTypeId-1].text }}
      </template>
      <template v-slot:item.dealerNames="{ item }">
        {{ getDealerNames(item.dealers) }}
      </template>
      <template v-slot:item.active="{ item, index }">
        <v-switch
          color="success"
          v-model="item.active"
          :label="item.active ? 'Active' : 'Inactive'"
          @click="saveSwitch(item, index)"
        ></v-switch>
      </template>
      <template v-slot:item.actions="{ item, index }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">    
            <v-btn icon class="mr-2" @click="openEditUser(item, index)" v-on="on">
              <v-icon color="secondary"> mdi-pencil-outline </v-icon>
            </v-btn>
          </template>
          <span>Edit User</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon class="mr-2" @click="tempUser=item; resetDialog=true" v-on="on">
              <v-icon color="primary"> mdi-lock-reset </v-icon>
            </v-btn>
          </template>
          <span>Reset Password</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      width="350"
      v-model="resetDialog"
    >
      <v-card>
        <v-img
          src="../assets/aspenLogo.png"
          contain
          max-height="100"
        ></v-img>

        <v-card-title class="d-flex justify-center primary--text font-weight-bold">Send Reset Password Link?</v-card-title>
        <v-card-actions>
          <v-btn color="secondary" @click="resetDialog=false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="sendReset(tempUser); resetDialog=false">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="resetSnackbar"
      outlined
      color="primary"
    >
      <v-container class="d-flex justify-center">
        Password Reset Email Sent
      </v-container>
    </v-snackbar>
  </v-card>
</template>


<script>
import { getUsers, editUser, createUser, getDealers, resetPasswordEmail } from "@/services";

export default {
  name: "CreateUser",

  data: () => ({
    dialog: false,
    editedIndex: -1,
    search: "",
    loading: false,
    dealers: [],
    resetDialog: false,
    tempUser: [],
    resetSnackbar: false,

    userTypes: [
      { value: 1, text: "Aspen Administrator"},
      { value: 2, text: "Aspen Sales Employee"},
      { value: 3, text: "Dealership Sales Employee"},
      { value: 4, text: "Dealership Aspen Rep"},
      { value: 5, text: "Aspen Admin Assistant"},
      { value: 6, text: "Vacuum Former Employee"},
      { value: 7, text: "Fiberglass Employee"},
      { value: 8, text: "Tub Driller Employee"},
      { value: 9, text: "Frame Builder Employee" },
      { value: 10, text: "Cabinet Builder Employee" },
      { value: 11, text: "Plumbing Employee" },
      { value: 12, text: "Testing Employee" },
      { value: 13, text: "Packaging Employee"},
      { value: 14, text: "Swim Phase 1 Employee"},
      { value: 15, text: "Swim Phase 2 Employee"},
      { value: 16, text: "Pre-Plumbing Employee"},
      { value: 17, text: "Foreman Employee"},
      { value: 18, text: "Dealership Service Employee"},
      { value: 19, text: "Dealership Owner"}
      
    ],
    users: [],

    headers: [
      {    
        text: "First Name",
        align: "start",
        value: "firstName",
      },
      { text: "Last Name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Dealer", value: "dealerNames"},
      { text: "User Type", value: "userType" },
      { text: "Active/Inactive", value: "active" },
      { text: "Edit User", value: "actions", sortable: false },
    ],

    editedUser: {
      id: null,
      dealerId: null,
      email: "",
      firstName:"",
      lastName: "",
      userTypeId: null,
      active: null,
      dealerName: '',
    },
    defaultUser: {
      id: null,
      dealerId: null,
      email: "",
      firstName:"",
      lastName: "",
      userTypeId: null,
      active: null,
      dealerName: '',
    },

  }),

  mounted() {
    this.loading = true;
    getUsers().then((result) => {
      this.users = result.data;
      this.loading = false;
    });
    getDealers().then((result) => {
      result.data.forEach((d) => this.dealers.push({ value: d.id, text: d.name }))
    })
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User"
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    openEditUser(user, index) {
      this.editedIndex = index;
      this.editedUser = Object.assign({}, user);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      });
    },

    save() {
      if(this.editedIndex > -1) {
        editUser(this.editedUser).then((result) => {
          console.log(result);
          console.log(this.editedIndex);
          this.users.splice(this.editedIndex,1,result.data);
          this.close();
        });
      } else {
        createUser(this.editedUser).then((result) => {
          console.log(result.data)
          this.users.push(result.data);
          this.close();
        })
      }
    },

    saveSwitch(item, index) {
      if(item.active) {
        item.active = 1;
      } else {
        item.active = 0;
      }
      editUser(item).then((result) => {
          this.users.splice(index,1,result.data);
      });
    },

    sendReset(user) {
      resetPasswordEmail(user.email).then((result) => {
        this.resetSnackbar=true
      })
    },

    getDealerNames(dealerIds) {
      return this.dealers.filter(el => dealerIds.includes(el.value)).map(d => d.text).join();
    }
  },
};
</script>
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

//Login and Account Routes
import Login from "../views/Login.vue";
import Account from "../views/Account.vue";
import PasswordReset from "../views/PasswordReset.vue";
import ForgotPassword from "../views/ForgotPassword.vue";

//Home and Dashboard Routes
import Home from "../views/Home.vue";
import EmpHome from "../views/EmpHome.vue";
import Dashboard from "../views/Dashboard.vue";

//Order Route
import Order from "../views/Order.vue";

//Order List Routes
import OrderList from "../views/OrderList.vue";
import OrderListAvailable from "../views/OrderListAvailable.vue";
import OrderListClaimed from "../views/OrderListClaimed.vue";
import RepOrderList from "../views/RepOrderList.vue";
import OrderListAspenEmp from "../views/OrderListAspenEmp.vue";

//Retail Order Route
import RetailHome from "../views/RetailHome.vue";
import RetailOrder from "../views/RetailOrder.vue";

//Retail Order List Routes
import RetailOrderList from "../views/RetailOrderList.vue";
import RetailOrderListAspenEmp from "../views/RetailOrderListAspenEmp.vue";

//Catalog Editing Routes
import EditorNavigation from "../views/EditorNavigation.vue";
import ModelEditor from "../views/ModelEditor.vue";
import SeriesEditor from "../views/SeriesEditor.vue";
import OptionTypeEditor from "../views/OptionTypeEditor.vue";
import CatalogEditor from "../views/CatalogEditor.vue";
import PartTypeEditor from "../views/PartTypeEditor.vue";
import RetailCatalogEditor from "../views/RetailCatalogEditor.vue";
import RetailCatalogEditorNoHide from "../views/RetailCatalogEditorNoHide.vue";

//Part Order Routes
import PartOrderList from "../views/PartOrderList.vue";
import PartOrderListAspenEmp from "../views/PartOrderListAspenEmp.vue";
import PartSalesOrderCust from "../views/PartSalesOrderCust.vue";
import PartOrder from "../views/PartOrder.vue";

//User and Dealer Routes
import CreateUser from "../views/CreateUser.vue";
import DealerList from "../views/DealerList.vue";

//Sales Order Routes
import SalesOrder from "../views/SalesOrder.vue";
import SalesOrderCust from "../views/SalesOrderCust.vue";
import SalesOrderEndCust from "../views/SalesOrderEndCust.vue";

//Swim Production Report Routes
import ProductionStatusReportSWIMDoors from "../views/ProductionStatusReportSWIMDoors.vue";
import ProductionStatusReportSWIMDrilling from "../views/ProductionStatusReportSWIMDrilling.vue";
import ProductionStatusReportSWIMFiberglass from "../views/ProductionStatusReportSWIMFiberglass.vue";
import ProductionStatusReportSWIMFrames from "../views/ProductionStatusReportSWIMFrames.vue";
import ProductionStatusReportSWIMPackaged from "../views/ProductionStatusReportSWIMPackaged.vue";
import ProductionStatusReportSWIMPlumbing from "../views/ProductionStatusReportSWIMPlumbing.vue";
import ProductionStatusReportSWIMTest from "../views/ProductionStatusReportSWIMTest.vue";
import ProductionStatusReportSWIMVacuum from "../views/ProductionStatusReportSWIMVacuum.vue";

//Production Repot Routes
import ProductionStatusReportDoors from "../views/ProductionStatusReportDoors.vue";
import ProductionStatusReportDrilling from "../views/ProductionStatusReportDrilling.vue";
import ProductionStatusReportFiberglass from "../views/ProductionStatusReportFiberglass.vue";
import ProductionStatusReportFrames from "../views/ProductionStatusReportFrames.vue";
import ProductionStatusReportPackaged from "../views/ProductionStatusReportPackaged.vue";
import ProductionStatusReportPlumbed from "../views/ProductionStatusReportPlumbed.vue";
import ProductionStatusReportTest from "../views/ProductionStatusReportTest.vue";
import ProductionStatusReportVacuum from "../views/ProductionStatusReportVacuum.vue";

//Production Status Routes
import ProductionStatus from "../views/ProductionStatus.vue";
import ProductionStatusEmployeeDoors from "../views/ProductionStatusEmployeeDoors.vue";
import ProductionStatusEmployeeDrilling from "../views/ProductionStatusEmployeeDrilling.vue";
import ProductionStatusEmployeeFiberglass from "../views/ProductionStatusEmployeeFiberglass.vue";
import ProductionStatusEmployeeFrames from "../views/ProductionStatusEmployeeFrames.vue";
import ProductionStatusEmployeePackaged from "../views/ProductionStatusEmployeePackaged.vue";
import ProductionStatusEmployeePlumbed from "../views/ProductionStatusEmployeePlumbed.vue";
import ProductionStatusEmployeePrePlumbed from "../views/ProductionStatusEmployeePrePlumbed.vue";
import ProductionStatusEmployeeTest from "../views/ProductionStatusEmployeeTest.vue";
import ProductionStatusEmployeeVacuum from "../views/ProductionStatusEmployeeVacuum.vue";
import ProductionStatusEmployeeSWIM1 from "../views/ProductionStatusEmployeeSWIM1.vue";
import ProductionStatusEmployeeSWIM2 from "../views/ProductionStatusEmployeeSWIM2.vue";
import ProductionStatusEmployeeForeman from "../views/ProductionStatusEmployeeForeman.vue";

//Analytics Routes
import AnalyticsHome from "../views/AnalyticsHome.vue";
import AnalyticsDealer from "../views/AnalyticsDealer.vue";
import Analytics from "../views/Analytics.vue";
import AnalyticsSwim from "../views/AnalyticsSwim.vue";
import AnalyticsSwimOO from "../views/AnalyticsSwimOO.vue";
import AnalyticsAspel from "../views/AnalyticsAspel.vue";
import AnalyticsOO from "../views/AnalyticsOO.vue";
import AnalyticsOptions from "../views/AnalyticsOptions.vue";
import AnalyticsAspelOO from "../views/AnalyticsAspelOO.vue";
import AnalyticsES from "../views/AnalyticsES.vue";
import AnalyticsGS from "../views/AnalyticsGS.vue";
import AnalyticsVS from "../views/AnalyticsVS.vue";
import AnalyticsHoliday from "../views/AnalyticsHoliday.vue";

//Admin Dashboard Routes
import ApproveOrders from "../views/ApproveOrders.vue";
import Scheduling from "../views/Scheduling.vue";
import AssignProdDate from "../views/AssignProdDate.vue";
import ProductionSchedule from "../views/ProductionSchedule.vue";

//Rep On Board and Example Order Routes
import RepOnBoardTool from "../views/RepOnBoardTool.vue";
import ExampleOrder from "../views/ExampleOrder.vue";
import ExampleOrderList from "../views/ExampleOrderList.vue";

//Announcement Routes
import AnnouncementListAspen from "../views/AnnouncementListAspen.vue";
import AnnouncementListDealer from "../views/AnnouncementListDealer.vue"

//In Production
import DealerAnalytics from "../views/DealerAnalytics.vue";

Vue.use(VueRouter);

const routes = [
  //Login and Account Paths
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/password-reset/:resetToken",
    name: "PasswordReset",
    props: true,
    component: PasswordReset,
  },  
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  

  //Home and Dashboard Paths
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/emphome",
    name: "EmpHome",
    component: EmpHome,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },  
  

  //Order Paths
  {
    path: "/order",
    name: "NewOrder",
    component: Order,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/order/new",
    name: "NewOrder",
    component: Order,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/order/new/:key",
    name: "NewOrder",
    component: Order,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/order/:orderId",
    name: "EditOrder",
    props: true,
    component: Order,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/order/:orderId/:copy",
    name: "CopyOrder",
    props: true,
    component: Order,
    meta: {
      requiresAuth: true
    }
  },

  //Retail Order Paths
  {
    path: "/retail-order",
    name: "NewRetailOrder",
    component: RetailOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/retail-order/new",
    name: "NewRetailOrder",
    component: RetailOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/retail-order/new/:key",
    name: "NewRetailOrder",
    component: RetailOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/retail-order/:orderId",
    name: "EditRetailOrder",
    props: true,
    component: RetailOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/retail-order/:orderId/:copy",
    name: "CopyRetailOrder",
    props: true,
    component: RetailOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/retailhome",
    name: "RetailHome",
    component: RetailHome,
    meta: {
      requiresAuth: true
    }
  },
  

  //Order List Paths
  {
    path: "/order-list",
    name: "OrderList",
    component: OrderList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/order-list-available",
    name: "OrderListAvailable",
    component: OrderListAvailable,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/order-list-claimed/:swimProp",
    name: "OrderListClaimed",
    props: true,
    component: OrderListClaimed,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/rep-order-list",
    name: "RepOrderList",
    component: RepOrderList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/order-list-aspen-emp",
    name: "OrderListAspenEmp",
    component: OrderListAspenEmp,
    meta: {
      requiresAuth: true
    }
  },

  //Retail Order List Paths
  {
    path: "/retail-order-list",
    name: "RetailOrderList",
    component: RetailOrderList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/retail-order-list-aspen-emp",
    name: "RetailOrderListAspenEmp",
    component: RetailOrderListAspenEmp,
    meta: {
      requiresAuth: true
    }
  },


  //Catalog Editing Paths
  {
    path: "/editor-navigation",
    name: "EditorNavigation",
    component: EditorNavigation,
    meta: {
      requiresAuth: true
    },
    
  },
  {
    path: "/model-editor",
    name: "ModelEditor",
    component: ModelEditor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/series-editor",
    name: "SeriesEditor",
    component: SeriesEditor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/optiontype-editor",
    name: "OptionTypeEditor",
    component: OptionTypeEditor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/catalog-editor/:catalogId",
    name: "CatalogEditor",
    props: true,
    component: CatalogEditor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/parttype-editor",
    name: "PartTypeEditor",
    component: PartTypeEditor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/retail-catalog-editor",
    name: "RetailCatalogEditor",
    component: RetailCatalogEditor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/retail-catalog-editor-no-hide",
    name: "RetailCatalogEditorNoHide",
    component: RetailCatalogEditorNoHide,
    meta: {
      requiresAuth: true
    }
  },

  //Part Order Paths
  {
    path: "/part-order-list",
    name: "PartOrderList",
    component: PartOrderList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/part-order-list-aspen-emp",
    name: "PartOrderListAspenEmp",
    component: PartOrderListAspenEmp,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/part-sales-order-cust/:partOrderId",
    name: "PartSalesOrderCust",
    props: true,
    component: PartSalesOrderCust,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/part-order",
    name: "NewPartOrder",
    component: PartOrder,
    meta: {
      requiresAuth: true
    }
  },  
  
  //User and Deler Paths
  {
    path: "/create-user",
    name: "CreateUser",
    component: CreateUser,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dealer-list",
    name: "DealerList",
    component: DealerList,
    meta: {
      requiresAuth: true
    }
  },


  //Sales Order Paths
  {
    path: "/sales-order-cust/:orderId",
    name: "SalesOrderCust",
    props: true,
    component: SalesOrderCust,
    meta: {
      requiresAuth: true
    },
  },  
  {
    path: "/sales-order/:orderId",
    name: "SalesOrder",
    props: true,
    component: SalesOrder,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/sales-order-end-cust/:orderId",
    name: "SalesOrderEndCust",
    props: true,
    component: SalesOrderEndCust,
    meta: {
      requiresAuth: true
    },
  },  
  

  //Swim Production Report Paths
  {
    path: "/production-status-report-swim-vacuum/:swimProp",
    name: "ProductionStatusReportSWIMVacuum",
    props: true,
    component: ProductionStatusReportSWIMVacuum,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-swim-doors/:swimProp",
    name: "ProductionStatusReportSWIMDoors",
    props: true,
    component: ProductionStatusReportSWIMDoors,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-swim-drilling/:swimProp",
    name: "ProductionStatusReportSWIMDrilling",
    props: true,
    component: ProductionStatusReportSWIMDrilling,
    meta: {
      requiresAuth: true
    }, 
  },
  {
    path: "/production-status-report-swim-fiberglass/:swimProp",
    name: "ProductionStatusReportSWIMFiberglass",
    props: true,
    component: ProductionStatusReportSWIMFiberglass,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-swim-plumbing/:swimProp",
    name: "ProductionStatusReportSWIMPlumbing",
    props: true,
    component: ProductionStatusReportSWIMPlumbing,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-swim-frames/:swimProp",
    name: "ProductionStatusReportSWIMFrames",
    props: true,
    component: ProductionStatusReportSWIMFrames,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-swim-packaged/:swimProp",
    name: "ProductionStatusReportSWIMPackaged",
    props: true,
    component: ProductionStatusReportSWIMPackaged,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-swim-test/:swimProp",
    name: "ProductionStatusReportSWIMTest",
    props: true,
    component: ProductionStatusReportSWIMTest,
    meta: {
      requiresAuth: true
    },
  },
  

  //Production Repot Paths
  {
    path: "/production-status-report-doors/:swimProp",
    name: "ProductionStatusReportDoors",
    props: true,
    component: ProductionStatusReportDoors,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-drilling/:swimProp",
    name: "ProductionStatusReportDrilling",
    props: true,
    component: ProductionStatusReportDrilling,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-fiberglass/:swimProp",
    name: "ProductionStatusReportFiberglass",
    props: true,
    component: ProductionStatusReportFiberglass,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-frames/:swimProp",
    name: "ProductionStatusReportFrames",
    props: true,
    component: ProductionStatusReportFrames,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-packaged/:swimProp",
    name: "ProductionStatusReportPackaged",
    props: true,
    component: ProductionStatusReportPackaged,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-plumbed/:swimProp",
    name: "ProductionStatusReportPlumbed",
    props: true,
    component: ProductionStatusReportPlumbed,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-test/:swimProp",
    name: "ProductionStatusReportTest",
    props: true,
    component: ProductionStatusReportTest,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-report-vacuum/:swimProp",
    name: "ProductionStatusReportVacuum",
    props: true,
    component: ProductionStatusReportVacuum,
    meta: {
      requiresAuth: true
    },
  },
  

  //Production Status Paths
  {
    path: "/production-status-employee-door/",
    name: "ProductionStatusEmployeeDoor",
    component: ProductionStatusEmployeeDoors,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-drilling/",
    name: "ProductionStatusEmployeeDrilling",
    component: ProductionStatusEmployeeDrilling,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-fiberglass/",
    name: "ProductionStatusEmployeeFiberglass",
    component: ProductionStatusEmployeeFiberglass,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-frames/",
    name: "ProductionStatusEmployeeFrames",
    component: ProductionStatusEmployeeFrames,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-packaged/",
    name: "ProductionStatusEmployeePackaged",
    component: ProductionStatusEmployeePackaged,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-plumbed/",
    name: "ProductionStatusEmployeePlumbed",
    component: ProductionStatusEmployeePlumbed,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-preplumbed/",
    name: "ProductionStatusEmployeePrePlumbed",
    component: ProductionStatusEmployeePrePlumbed,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-test/",
    name: "ProductionStatusEmployeeTest",
    component: ProductionStatusEmployeeTest,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-vacuum/",
    name: "ProductionStatusEmployeeVacuum",
    component: ProductionStatusEmployeeVacuum,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-swim1/",
    name: "ProductionStatusEmployeeSWIM1",
    component: ProductionStatusEmployeeSWIM1,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-swim2/",
    name: "ProductionStatusEmployeeSWIM2",
    component: ProductionStatusEmployeeSWIM2,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status-employee-foreman/",
    name: "ProductionStatusEmployeeForeman",
    component: ProductionStatusEmployeeForeman,
    meta: {
      requiresAuth: true
    },
  },
  

  //Analytics Paths
  {
    path: "/analytics-home",
    name: "AnalyticsHome",
    component: AnalyticsHome,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analytics-dealer",
    name: "AnalyticsDealer",
    component: AnalyticsDealer,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticsswim",
    name: "AnalyticsSwim",
    component: AnalyticsSwim,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticsswimoo",
    name: "AnalyticsSwimOO",
    component: AnalyticsSwimOO,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticsaspel",
    name: "AnalyticsAspel",
    component: AnalyticsAspel,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticsoptions",
    name: "AnalyticsOptions",
    component: AnalyticsOptions,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticsoo",
    name: "AnalyticsOO",
    component: AnalyticsOO,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticsaspeloo",
    name: "AnalyticsAspelOO",
    component: AnalyticsAspelOO,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticsvs",
    name: "AnalyticsVS",
    component: AnalyticsVS,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticsgs",
    name: "AnalyticsGS",
    component: AnalyticsGS,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticses",
    name: "AnalyticsES",
    component: AnalyticsES,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/analyticsholiday",
    name: "Analyticsholiday",
    component: AnalyticsHoliday,
    meta: {
      requiresAuth: true
    },
  },


  //Admin Dashboard Paths
  {
    path: "/approve-orders/:swimProp",
    name: "ApproveOrders",
    props: true,
    component: ApproveOrders,
    meta: {
      requiresAuth: true
    },
  },  
  {
    path: "/scheduling/:swimProp",
    name: "Scheduling",
    props: true,
    component: Scheduling,
    meta: {
      requiresAuth: true
    },
  },  
  {
    path: "/assign-prod-date/:swimProp",
    name: "AssignProdDate",
    props: true,
    component: AssignProdDate,
    meta: {
      requiresAuth: true
    },
  },    
  {
    path: "/production-schedule",
    name: "ProductionSchedule",
    component: ProductionSchedule,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/production-status/:swimProp",
    name: "ProductionStatus",
    props: true,
    component: ProductionStatus,
    meta: {
      requiresAuth: true
    },
  },

  
  //Rep On Board and Example Order Paths
  {
    path: "/rep-onboard-tool",
    name: "RepOnBoardTool",
    component: RepOnBoardTool,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/exorder",
    name: "ExampleNewOrder",
    component: ExampleOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/exorder/new",
    name: "ExampleNewOrder",
    component: ExampleOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/exorder/new/:key",
    name: "ExampleNewOrder",
    component: ExampleOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/exorder/:orderId",
    name: "ExampleEditOrder",
    props: true,
    component: ExampleOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/exorder/:orderId/:copy",
    name: "ExampleCopyOrder",
    props: true,
    component: ExampleOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/exorder-list",
    name: "ExampleOrderList",
    component: ExampleOrderList,
    meta: {
      requiresAuth: true
    }
  },
  
  
  //Announcement Paths
  {
    path: "/announcement-list-aspen",
    name: "AnnouncementListAspen",
    component: AnnouncementListAspen,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/announcement-list-dealer",
    name: "AnnouncementListDealer",
    component: AnnouncementListDealer,
    meta: {
      requiresAuth: true
    }
  },


  //In Production
  {
    path: "/dealeranalytics",
    name: "DealerAnalytics",
    component: DealerAnalytics,
    meta: {
      requiresAuth: true
    }
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router;

<template>
  <div>
    <v-row>
                <v-col>
                      <v-alert
                      :value="success"
                      outlined
                      type="success"
                      text
                      transition="scale-transition">
                      <v-row>
                        <v-col class="grow">
                          Thank you!  You have successfully submitted this Part order.
                        </v-col>
                      </v-row> 
                      
                    </v-alert>
                </v-col>
              </v-row>

        <div class="pa-2 d-flex align-center">
          <v-chip
            class="ma-2"
            color="primary"
            text-color="white"
          >
          ${{ this.totalCurrentPrice }}
          </v-chip>
          <v-btn @click="cartDialog = true">View Cart</v-btn>
        </div>
       
          
           
              <v-container v-for="part in this.parts" :key="part.id">
                  <v-row>
                  <v-col>
                    
                        <v-card  class="d-flex align-center rounded-lg" elevation="0">
                          <v-card-title class="d-flex justify-center primary--text pa-0">
                        <v-container class="text-center pa-0">
                        {{ part.name }}
                        </v-container>
                      </v-card-title>
                      <v-card-subtitle class="d-flex justify-center secondary--text">
                        <v-container class="text-center">
                        {{ part.unitPrice }}
                        </v-container>
                      </v-card-subtitle>
                          <v-img
                            :src="part.img"
                            contain
                            height="250"
                            :aspect-ratio="1/1"
                          >
                          </v-img>
                          <v-col
                          cols="1">
                            <v-select
                          label="QTY"
                          v-model="part.qty"
                          :items="[0,1,2,3,4,5,6,7,8,9]"
                          
                          max-width="2"
                          >

                          </v-select>
                          </v-col>
                        
                        <v-card-actions>
                          <v-btn v-if="part.qty>0" @click=addToCart(part)>Add To Cart</v-btn>
                        </v-card-actions>

                        </v-card>
                     
                     
                  </v-col>
                  
                </v-row>
            
              </v-container>

              <v-dialog
          width="700"
          v-model="cartDialog"
          persistent
        >
        <v-card>
          <v-btn
                  color="red"
                  elevation="1"
                  icon
                  outlined
                  x-small 
                  @click="cartDialog = false"
                >X</v-btn>
                <br/>
          
          <v-container v-for="item in this.order.options" :key="item.partId">
           <v-card>
            <v-img :src="item.img"></v-img>
            <v-row>
            <v-col
            cols="2">
                            <v-select
                          v-model="item.qty"
                          :items="[1,2,3,4,5,6,7,8,9]"
                          >

                          </v-select>
                          </v-col>
          <div>{{ item.name }} @ ${{ item.unitPrice }}/piece  = ${{ item.qty * item.unitPrice }}</div> 
         <v-col cols="2"><v-btn @click="removeFromCart(item)">Remove From Cart</v-btn></v-col> </v-row> 
          </v-card>
         
         </v-container>
         <div>Total Cost ${{ this.totalCurrentPrice }}</div>
        </v-card>
        
         <v-btn @click="savePartOrder()">Place Order</v-btn>
        </v-dialog>

        <v-overlay :value="saving">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
           
          
  </div>
</template>

<script>
import {  createPartOrder, editOrder, getParts, getDealersForUser } from "@/services";

export default {
  name: "PartOrder",

  data: () => ({
    
    order: { id: null, userId: null, dealerId: null, options: []},
    parts: {},
    saving: false,
    success: false,
    currentGroup: null,
    dialog: false,
    timeout: -1,
    dealers: [],
    cartDialog: false,
  
    rules: { required: value => !!value || 'Required' }
  }),

  mounted() {
      getParts().then((result) => {
        this.parts = result.data;
      })
    },

   

  props: ['orderId', 'copy'], 

  computed: {
    currentUser() { return this.$store.state.user },
   
   
    totalCurrentPrice: function () {
      var total = 0;
      if (this.order.options.length >= 0) {
        this.order.options.forEach(a => 
        total +=
        Number(a.unitPrice) * Number(a.qty));
      }
      return total;
    },
  },

  methods: {

    addToCart: function(part) {
      this.order.options.push({partId: part.id, img: part.img, qty: part.qty, unitPrice: part.unitPrice, name: part.name })
      
    },

    removeFromCart: function(part) {
      let targetElementIndex = this.order.options.indexOf(part);
      this.order.options.splice(targetElementIndex, 1)
    },

    savePartOrder() {
      this.saving = true;
      this.order.totalCost = this.totalCurrentPrice;
      this.order.userId = this.currentUser.id;
      this.order.dealerId = this.currentUser.dealers[0]
      
      createPartOrder(this.order).then(result => {
        this.order.id = result.data.id;
        this.saving = false;
        this.cartDialog = false;
        this.success = true;
        
        });
      }
    },
};
</script>

<style></style>

<template>
  <v-card>
     <v-banner
    elevation="5"
    class="text-center font-weight-bold">
    <span class="text-h4 font weight-bold"> Aspel Open Orders Breakdown </span> <br/>
    Breakdown of Model, Series, Acrylic Color, and Cabinent Color for Aspel UK Hot Tubs On Order Excluding SWIM Spas
    </v-banner>
     <v-row justify="center">
           <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
            <v-card-title>
             <span> Spas Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 3 Months </span></span>  
            </v-card-title>
            <span class="text-h5 font-weight-bold">{{this.orderCount.threeMonthOrders}}</span>
          </v-card>
              
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                   <span> Spas Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 6 Months </span></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.sixMonthOrders}}</span>
              </v-card>
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                 <span> Spas Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 1 Year </span></span>
                </v-card-title>
                 <span class="text-h5 font-weight-bold">{{this.orderCount.oneYearOrders}}</span>
              </v-card>
              
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                  <span> Spas Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon> Last <span class="font-weight-bold text-decoration-underline"> 2 Years </span></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.twoYearOrders}}</span>
              </v-card>
              <v-card elevation="7" class="ma-3 pa-2 rounded-lg text-center">
                <v-card-title>
                  <span> All Spas Ordered <v-icon color="primary">mdi-cart-arrow-down</v-icon></span>
                </v-card-title>
                <span class="text-h5 font-weight-bold">{{this.orderCount.allOrders}}</span>
              </v-card>
        </v-row>
    <v-row>
        <v-col>
          <v-card elevation="0" class="d-flex justify-center pt-4">

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Model Popularity</v-card-title>    
              <GChart     
                type="BarChart"
                :data="modelsData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Series Popularity</v-card-title>    
              <GChart     
                type="PieChart"
                :data="seriesData"
                :options="chartOptionsPie"
              >
              </Gchart>
            </v-card>
          </v-card>
           </v-col> 
    </v-row> <v-row>
            <v-col>
              <v-card elevation="0" class="d-flex justify-center pt-4" >
           <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Arcylic Color</v-card-title>    
              <GChart     
                type="BarChart"
                :data="acrylicData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>

            <v-card elevation="7" class="ma-3 pa-2 rounded-lg">
              <v-card-title class="justify-center">Cabinet Color</v-card-title>    
              <GChart     
                type="BarChart"
                :data="cabinetData"
                :options="chartOptionsBar"
              >
              </Gchart>
            </v-card>
          </v-card>
        </v-col>
    </v-row>

   <v-bottom-navigation
  color="primary"
  >
    <v-btn to="/analyticsoo">
      <span>Domestic OO</span>
      <v-icon>mdi-chart-donut</v-icon>
    </v-btn>

    <v-btn to="/analyticsaspeloo">
      <span>Aspel UK OO</span>
      <v-icon>mdi-chart-donut</v-icon>
    </v-btn>

     <v-btn to="analyticsswimoo">
      <span>SWIM Spas OO</span>
      <v-icon>mdi-chart-donut</v-icon>
    </v-btn>

    <v-btn to="analytics">
      <span> Domestic Data</span>
      <v-icon>mdi-chart-donut</v-icon>
    </v-btn>

    <v-btn to="analyticsaspel">
      <span>Aspel UK Data</span>
      <v-icon>mdi-chart-donut</v-icon>
    </v-btn>

    <v-btn to="analyticsswim">
      <span>SWIM Spas Data</span>
      <v-icon>mdi-chart-donut</v-icon>
    </v-btn>

    <v-btn to="analyticsgs">
      <span>GS Options Data</span>
      <v-icon>mdi-chart-donut</v-icon>
    </v-btn>

    <v-btn to="analyticsvs">
      <span>VS Option Data</span>
      <v-icon>mdi-chart-donut</v-icon>
    </v-btn>

    <v-btn to="analyticsoptions">
      <span>OO Options Breakdown</span>
      <v-icon>mdi-chart-donut</v-icon>
    </v-btn>
  </v-bottom-navigation>

  </v-card>
</template>

<script>
import { getAspelOrdersIntervalsOpen, getActiveAspelModelCount, getActiveAspelSeriesCount, getActiveAspelAcrylicOptionCount, getActiveAspelCabOptionCount } from '@/services';
import { GChart } from 'vue-google-charts';

export default {
  name: "AnalyticsAspelOO",

  data: () => ({
    modelsData: [
      ['Model', 'Sales', {role: 'annotation'}],
    ],
    seriesData: [
      ['Series', 'Sales'],
    ],
    acrylicData: [
      ['Option', 'Count']
    ],
    cabinetData: [
      ['Option', 'Count']
    ],
    orderCount: [],
      chartOptionsBar: {
      width: 700,
      height: 300,
      legend: { position: 'none'},
      titlePosition: 'none',
    },
      chartOptionsPie: {
      width: 700,
      height: 300,
      legend: { position: 'top', alignment: 'center'},
      titlePosition: 'none',
    },
  }),

  mounted() {
    getActiveAspelModelCount().then((result) => {
      result.data.forEach((m) => this.modelsData.push([ m.modelName, m.modelCount, m.modelGroup ]));
    })
    getActiveAspelSeriesCount().then((result) => {
      result.data.forEach((s) => this.seriesData.push([ s.seriesName, s.seriesCount ]));
    })
    getActiveAspelAcrylicOptionCount().then((result) => {
      result.data.forEach((o) => this.acrylicData.push([ o.optionName, o.optionCount])); 
    })
    getActiveAspelCabOptionCount().then((result) => {
      result.data.forEach((o) => this.cabinetData.push([ o.optionName, o.optionCount])); 
    })
    getAspelOrdersIntervalsOpen().then((result) => {
      this.orderCount = result.data;
    })
  },
  computed: {
    
  },

  components: {
    GChart
  },

}
</script>
